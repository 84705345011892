import React, { useState } from "react";
import { Box, Button, Icon, Modal, Select } from "zmp-ui";
import { selectOptionModel } from "../../models";

const { Option } = Select;
interface SelectWithSearchProps {
  label: string;
  options: selectOptionModel[];
  input_name: string;
  onChangeCallback?: (
    e: React.ChangeEvent<HTMLInputElement>
  ) => void | undefined;
  placeholder?: string;
  default_value?: string;
}

const SelectWithSearch: React.FunctionComponent<SelectWithSearchProps> = ({
  label,
  options,
  onChangeCallback,
  placeholder,
  default_value,
  input_name,
}) => {
  const [popupVisible, setPopupVisible] = useState(false);
  const [inputSelectedState, setInputSelectedState] = useState(
    default_value ? options.find((x) => x.value == default_value) : undefined
  );
  const [inputSearchValueState, setInputSearchValueState] = useState("");
  const [filteredOptionsState, setFilteredOptionsState] = useState(options);

  const onClickOption = (value: string) => {
    setInputSelectedState(options.find((x) => x.value === value));

    if (onChangeCallback) {
      onChangeCallback({
        target: { name: input_name, value: value },
      } as React.ChangeEvent<HTMLInputElement>);
    }

    setPopupVisible(false);
  };

  const onClickInput = () => {
    setPopupVisible(true);
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = event.target.value;
    processFilterOptions(searchValue);
  };

  const processFilterOptions = (searchValue: string) => {
    setInputSearchValueState(searchValue);

    const filteredOptions = options.filter((option) =>
      option.title.toLowerCase().includes(searchValue.toLowerCase())
    );
    setFilteredOptionsState(filteredOptions);
  };

  const viewClearTextForSearchMerchant = inputSearchValueState ? (
    <div
      onClick={() => {
        processFilterOptions("");
      }}
      className="flex m-a p-r-10"
    >
      <Icon
        className="font-size-18"
        style={{ color: "#ddd" }}
        icon={"zi-close-circle-solid"}
      />
    </div>
  ) : (
    <></>
  );

  const viewSearchMerchant = (
    <div
      className="m-t-10 m-b-10 flex border-solid-1 border-12"
      style={{ flexFlow: "row" }}
    >
      <div className="flex p-5 m-a">
        <Icon icon={"zi-search"} />
      </div>
      <input
        style={{ border: "none" }}
        placeholder="Tìm kiếm..."
        className="form-control border-12 p-5 no-outline"
        onChange={handleSearch}
        value={inputSearchValueState}
      />
      {viewClearTextForSearchMerchant}
    </div>
  );

  const viewListOption =
    filteredOptionsState.length > 0 ? (
      filteredOptionsState.map((option) => {
        const isSelected = inputSelectedState?.value === option.value;
        return (
          <div
            key={option.value}
            onClick={() => {
              onClickOption(option.value);
            }}
            className={`zaui-select-option ${
              isSelected ? "zaui-select-option-selected" : ""
            }`}
            role="presentation"
          >
            <span>{option.title}</span>
            {isSelected ? (
              <i className="zaui-icon zaui-select-option-check-icon">
                zi_check
              </i>
            ) : (
              <></>
            )}
          </div>
        );
      })
    ) : (
      <></>
    );

  return (
    <>
      <div
        className="m-t-10 m-b-10 flex border-solid-1 border-025"
        style={{ flexFlow: "row" }}
      >
        <input
          readOnly
          style={{ border: "none" }}
          placeholder={placeholder}
          className="form-control border-12 p-5 no-outline"
          onClick={onClickInput}
          value={inputSelectedState?.title ?? ""}
        />
        <div className="flex p-5 m-a">
          <Icon icon={"zi-chevron-down"} />
        </div>
      </div>
      <Modal
        className="modal-select-with-search"
        visible={popupVisible}
        onClose={() => {
          setPopupVisible(false);
        }}
      >
        <div className="zaui-select-panel">
          <div className="container-header">
            <div className="zaui-select-header">
              <div className="zaui-select-title">{label}</div>
              <button
                className="zaui-btn zaui-btn-neutral zaui-btn-tertiary zaui-btn-icon-only zaui-select-close-icon"
                type="button"
              >
                <div className="zaui-btn-container">
                  <span
                    onClick={() => {
                      setPopupVisible(false);
                    }}
                    className="zaui-btn-icon"
                  >
                    <i className="zaui-icon">zi_close</i>
                  </span>
                </div>
              </button>
            </div>
            {viewSearchMerchant}
          </div>
          <div className="zaui-select-options">{viewListOption}</div>
        </div>
      </Modal>
    </>
  );
};

export default SelectWithSearch;
