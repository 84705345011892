import React from "react";
import { TLMerchantModel } from "../../../models";
import LazyImage from "../../share/lazy-image";

interface TichLuyListMerchantProps {
  merchants: TLMerchantModel[];
  funcShowModalMerchantDetail(merchantId: number): Promise<void>;
}

const TichLuyListMerchant: React.FunctionComponent<
  TichLuyListMerchantProps
> = ({ merchants, funcShowModalMerchantDetail }) => {
  return (
    <div className="row-2-colum">
      {merchants.map((merchant) => (
        <div
          onClick={() => funcShowModalMerchantDetail(merchant.id ?? 0)}
          key={merchant.id}
          className="home-block-merchant"
        >
          <div className="home-block-merchant-image">
            <LazyImage
              className="w-full border-top-10"
              src={merchant.image_url}
            />
          </div>
          <div className="merchant-cashback-text">
            <span>{merchant.cashback_text}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TichLuyListMerchant;
