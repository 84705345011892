import React from "react";
import { tlDonationCampaignModel } from "../../../models";
import { cache_tlDonationCampaigns } from "../../../cache-manager";
import DataLoading from "../../share/data-loading";
import { getTLDonationCampaignOnGoingForDetailService } from "../../../services/tl-donation-campaign-service";
import TichLuyDonationListItem from "./donation-list-item";

interface TichLuyDonationDetailOnGoingCampaignProps {
  campaignDetail: tlDonationCampaignModel;
}

const TichLuyDonationDetailOnGoingCampaign: React.FunctionComponent<
  TichLuyDonationDetailOnGoingCampaignProps
> = ({ campaignDetail }) => {
  const campaignsFromCache = cache_tlDonationCampaigns();

  if (!campaignsFromCache) return <DataLoading />;

  const listCampaigns = getTLDonationCampaignOnGoingForDetailService(
    campaignDetail,
    campaignsFromCache
  );

  if (!listCampaigns || listCampaigns.length === 0) return <></>;

  return (
    <div>
      <div className="text-bold font-size-18">Chương trình đang diễn ra</div>
      <div className="m-t-15">
        {listCampaigns.map((campaign) => (
          <TichLuyDonationListItem
            key={campaign.id}
            donationCampaign={campaign}
          />
        ))}
      </div>
    </div>
  );
};

export default TichLuyDonationDetailOnGoingCampaign;
