import React from "react";
import { Text } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { ACTION_TYPE_OF_NGHIEN_REVIEW } from "../../../constants";
import { tlActionTypeEnum } from "../../../enums";
import { TLHomeBlockModel } from "../../../models";
import { capitalizeFirstLetter } from "../../../utility";
import LazyImage from "../../share/lazy-image";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeBlockListImageWithTextProps {
  block: TLHomeBlockModel;
}

const TichLuyHomeBlockListImageWithText: React.FunctionComponent<
  TichLuyHomeBlockListImageWithTextProps
> = ({ block }) => {
  const userFromCache = cache_user();

  let viewImage: any = <></>;
  if (block.list_home_block_item?.length > 0 && userFromCache) {
    viewImage = block.list_home_block_item.map((item) => (
      <ProcessActionTypeTichLuy
        action_type={item.action_type}
        action_data={item.action_data}
         // nếu là loại deal TL thì ko cần auth
        requiredLogin={!ACTION_TYPE_OF_NGHIEN_REVIEW.includes(item.action_type)}
        key={item.id}
      >
      <div
        className="block-with-text-container m-r-12"
        style={{ width: `${item.image_width ?? 100}%` }}
      >
        <LazyImage className="w-full" src={item.image_url} />
        {/* nếu là loại xem tất cả thì ko hiển thị text */}
        {item.action_type ===
        tlActionTypeEnum.ScreenNghienReviewDealAll ? (
          <></>
        ) : (
          <>
            <div className="block-with-text-line-1">
              <span>{item.text_line_1}</span>
            </div>
            {item.text_line_2 ? (
              <div className="block-with-text-line-2">
                <span>{item.text_line_2}</span>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      </ProcessActionTypeTichLuy>
    ));
  }

  return (
    <div className="block-container">
      <Text.Title className="m-b-10" size="normal">
        {capitalizeFirstLetter(block.title ?? "")}
      </Text.Title>
      <div className="scrolling-wrapper">{viewImage}</div>
    </div>
  );
};

export default TichLuyHomeBlockListImageWithText;
