import {
  callJoinDeal,
  categoryLv1s,
  dealDetail,
  dealsBySearch,
  dealsForPageHome,
  dealsJoined,
} from "../connect/nghien-review-client";
import {
  categoryLv1,
  dealDetailModel,
  dealItem,
  dealJoined,
  joinDealModel,
} from "../models";

export const getDealsForPageHome = async (
  apiAuthToken: string | undefined,
  categoryLv1Name: string
): Promise<dealItem[]> => {
  return dealsForPageHome(apiAuthToken, categoryLv1Name);
};

export const getDealDetail = async (
  dealId: string,
  apiAuthToken: string | undefined
): Promise<dealDetailModel> => {
  return dealDetail(dealId, apiAuthToken);
};

export const getDealsJoined = async (
  slotStatusCode: string | null,
  apiAuthToken: string | undefined,
  pageIndex: number,
  pageSize: number = 10
): Promise<dealJoined[]> => {
  return dealsJoined(slotStatusCode, apiAuthToken, pageIndex, pageSize);
};

export const getDealsBySearchService = async (
  name: string,
  pageIndex: number,
  pageSize: number = 20
): Promise<dealItem[]> => {
  return dealsBySearch(name, pageIndex, pageSize);
};

export const joinDeal = async (
  dealId: string,
  apiAuthToken: string | undefined
): Promise<joinDealModel> => {
  return callJoinDeal(dealId, apiAuthToken);
};
