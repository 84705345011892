import React, { useState } from "react";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { Icon, BottomNavigation, useNavigate } from "zmp-ui";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED,
  ROUTE_PATH_NGHIEN_REVIEW_HOME,
} from "../../constants";
import { setPageHomeLastViewProductId } from "../../helper";
import BottomNavigationTichLuy from "../tich_luy/bottom-navigation-tich-luy";
import BottomNavigationNghienReview from "../nghien_review/bottom-navigation-nghien-review";

interface bottomNavigationCustomProps {
  children?: React.ReactNode;
}

const BottomNavigationCustom: React.FunctionComponent<
  bottomNavigationCustomProps
> = () => {
  const currentUrlPathName = useLocation().pathname;
  const isViewNghienReview = currentUrlPathName.includes(
    ROUTE_PATH_NGHIEN_REVIEW_HOME
  );
  return isViewNghienReview ? (
    <BottomNavigationNghienReview />
  ) : (
    <BottomNavigationTichLuy />
  );
};

export default BottomNavigationCustom;
