import React from "react";
import { Modal } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { tlActionTypeEnum } from "../../../enums";
import { buildUrl_TichLuy_External_Redirect_Merchant } from "../../../helper";
import { TLMerchantModel } from "../../../models";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface ModalMerchantDetailProps {
  merchant: TLMerchantModel;
  isVisible: boolean;
  funcSetModalVisible(value: boolean): void;
}

const TichLuyModalMerchantDetail: React.FunctionComponent<
  ModalMerchantDetailProps
> = ({ merchant, isVisible, funcSetModalVisible }) => {
  const userFromCache = cache_user();

  if (!userFromCache) return <></>;

  const viewListTier = merchant.group_tiers ? (
    merchant.group_tiers.map((groupTier) => (
      <li key={Math.random()} className="list-group-item">
        {groupTier.any_each_tier ? (
          <>
            <div className="flex m-b-5">
              <span className="flex-basis-100 list-group-item-title">
                {groupTier.name}
              </span>
            </div>
            {groupTier.tiers.map((tier) => (
              <div key={Math.random()} className="flex">
                <span className="flex-basis-70">{tier.name}</span>
                <span className="flex-basis-30 text-right text-danger">
                  {tier.value}
                </span>
              </div>
            ))}
          </>
        ) : (
          <div className="flex">
            <span className="flex-basis-70">{groupTier.name}</span>
            <span className="flex-basis-30 text-right text-danger">
              {groupTier.tiers[0].value}
            </span>
          </div>
        )}
      </li>
    ))
  ) : (
    <></>
  );

  const viewTerms = (
    <div
      className="html-inject-fix"
      dangerouslySetInnerHTML={{ __html: merchant.cashback_terms }}
    ></div>
  );

  // console.log(
  //   `rerender modal merchant detail: merchantid: ${merchant.name} - modalVisible: ${isVisible}`
  // );

  return (
    <Modal
      className="modal-merchant-detail"
      visible={isVisible}
      //title="ZaUI 2.0 Modal"
      coverSrc={merchant.image_url}
      onClose={() => {
        funcSetModalVisible(false);
      }}
      //zIndex={1200}
      // 2023-09-27 nam.nguyen đã chuyển xuống view ko dùng action của zalo nữa vì khó custom
      // actions={[
      //   {
      //     text: "Đóng",
      //     close: true,
      //   },
      //   {
      //     text: "Mua sắm ngay",
      //     highLight: true,
      //     className: "btn btn-solid btn-border btn-small",
      //     onClick: onClickRedirectMerchant,
      //   },
      // ]}
      //description=""
    >
      <div className="m-b-5rem">
        <span className="f-w-b">Hướng dẫn hoàn tiền</span>
        <ul className="list-style-decimal">
          <li>Nhấn "Mua sắm ngay" để chuyển đến trang đối tác</li>
          <li>Mua sắm như bình thường và thanh toán tại trang đối tác</li>
          <li>Nhận hoàn tiền về tài khoản Tích Lũy của bạn</li>
          <li>Rút tiền về TK ngân hàng</li>
        </ul>
        <span className="f-w-b">Tỉ lệ hoàn tiền</span>
        <ul className="list-group m-t-5">{viewListTier}</ul>
        <div>{viewTerms}</div>
      </div>
      <div className="zaui-modal-content-actions modal-merchant-detail-actions">
        <button
          onClick={() => {
            funcSetModalVisible(false);
          }}
          className="zaui-btn zaui-btn-neutral zaui-btn-tertiary zaui-modal-content-action"
          type="button"
        >
          <div className="zaui-btn-container">
            <span>Đóng</span>
          </div>
        </button>
        <ProcessActionTypeTichLuy
          action_type={tlActionTypeEnum.RedirectUrl}
          action_data={buildUrl_TichLuy_External_Redirect_Merchant(
            merchant.alias
          )}
          requiredLogin={true}
        >
          <button
            className="zaui-btn zaui-btn-highlight zaui-btn-tertiary btn btn-solid btn-border btn-small zaui-modal-content-action zaui-modal-content-action-highlight"
            type="button"
          >
            <div className="zaui-btn-container">
              <span>Mua sắm ngay</span>
            </div>
          </button>
        </ProcessActionTypeTichLuy>
      </div>
    </Modal>
  );
};

export default TichLuyModalMerchantDetail;
