import React from "react";
import { Icon, Page, useNavigate } from "zmp-ui";
import { tlDonationCampaignModel } from "../../../models";
import {
  calculateTimeDifference,
  formatCurrencyVND,
  getDateFromString,
} from "../../../utility";
import { buildUrl_TichLuy_Internal_Redirect_CampaignDetail } from "../../../helper";
import Badge from "../../share/badge";

interface TichLuyDonationListItemProps {
  donationCampaign: tlDonationCampaignModel;
}

const TichLuyDonationListItem: React.FunctionComponent<
  TichLuyDonationListItemProps
> = ({ donationCampaign }) => {
  const navigate = useNavigate();
  // thiết lập của user hiện tại với chiến dịch (trường hợp KH chọn quyên góp )
  // nếu chưa đăng nhập hoặc ko chọn chiến dịch này thì là null
  const customerDonationSetting = donationCampaign.customer_donation_setting;
  // chiến dịch đang được user chọn quyên góp
  const isCampaignDonating =
    customerDonationSetting !== null && customerDonationSetting !== undefined;

  const redirectToCampaignDetail = (campaignAlias: string) => {
    navigate(buildUrl_TichLuy_Internal_Redirect_CampaignDetail(campaignAlias), {
      animate: false,
    });
  };

  return (
    <div
      onClick={() => {
        redirectToCampaignDetail(donationCampaign.alias);
      }}
      className={`flex flex-col flex nowrap border border-solid-1 cursor-pointer ${
        isCampaignDonating && "border-active"
      }`}
    >
      <div className="position-relative h-11rem overflow-hidden">
        <img
          className="w-full position-absolute border-radius-top-20"
          src={donationCampaign.logo}
        />
        {isCampaignDonating && (
          <Badge
            classNameExtends="position-absolute bottom-0 left-0"
            icon={<Icon className="font-size-15" icon="zi-check-circle" />}
            type="success"
            text="Đang quyên góp"
          />
        )}
      </div>
      <div className="text-bold p-15 m-b-0 text-line-max-4">
        {donationCampaign.name}
      </div>
      <div className="p-15 p-t-0 m-t-15">
        <div className="flex">
          <div>
            <img
              className="w-30px h-30px border-radius-max"
              src={donationCampaign.donation_partner_primary_logo}
            />
          </div>
          <div className="flex-1 p-l-10 font-size-12 m-t-auto m-b-auto">
            {donationCampaign.donation_partner_primary_name}
          </div>
          <div className="font-size-12 m-t-auto m-b-auto">
            <span className="badge badge-warning">
              Còn{" "}
              {calculateTimeDifference(
                new Date(),
                getDateFromString(donationCampaign.end_date),
                "day"
              )}{" "}
              ngày
            </span>
          </div>
        </div>
        <div className="flex gap-5px">
          <div className="text-bold">
            {formatCurrencyVND(donationCampaign.raised_amount)}
          </div>{" "}
          /{" "}
          <div className="text-dark">
            {formatCurrencyVND(donationCampaign.goal_amount)}
          </div>
        </div>
        <div className="progress progress-small m-t-5 m-b-8">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${donationCampaign.raised_percent}%` }}
          ></div>
        </div>
        <div className="flex j-c-space-between">
          <div className="line-height-15">
            <div className="font-size-12 text-dark">Lượt quyên góp</div>
            <div className="text-bold font-size-12">
              {donationCampaign.donation_count}
            </div>
          </div>
          <div className="line-height-15">
            <div className="font-size-12 text-dark">Đạt được</div>
            <div className="text-bold font-size-12">
              {donationCampaign.raised_percent}%
            </div>
          </div>
          <div className="m-t-auto m-b-auto">
            <a className="btn btn-outline-solid btn-very-small btn-border">
              {isCampaignDonating ? "Chỉnh sửa" : "Quyên góp"}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TichLuyDonationListItem;
