import React from "react";
import { Text } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { TLHomeBlockModel } from "../../../models";
import { capitalizeFirstLetter } from "../../../utility";
import LazyImage from "../../share/lazy-image";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeBlockFullImageProps {
  block: TLHomeBlockModel;
}

const TichLuyHomeBlockFullImage: React.FunctionComponent<
  TichLuyHomeBlockFullImageProps
> = ({ block }) => {
  const userFromCache = cache_user();

  let viewImage = <></>;
  if (block.list_home_block_item?.length > 0 && userFromCache) {
    const blockItem = block.list_home_block_item[0];

    viewImage = (
      <ProcessActionTypeTichLuy
        action_type={blockItem.action_type}
        action_data={blockItem.action_data}
        requiredLogin={true}
      >
        <LazyImage
          className="w-full border-12 h-full"
          src={blockItem.image_url}
        />
      </ProcessActionTypeTichLuy>
    );
  }

  return (
    <div className="block-container">
      <Text.Title className="m-b-10" size="normal">
        {capitalizeFirstLetter(block.title ?? "")}
      </Text.Title>
      {viewImage}
    </div>
  );
};

export default TichLuyHomeBlockFullImage;
