import React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Icon, Modal, useNavigate } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { TLHomePopupModel, TLUserModel } from "../../../models";
import {
  tichLuyModalAccountVerifyState,
  tichLuyModalRedirectExternalState,
} from "../../../state";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeModalFullImageProps {
  visible: boolean;
  funcSetModalVisible: (val: boolean) => void;
  homePopup: TLHomePopupModel;
}

const TichLuyHomeModalFullImage: React.FunctionComponent<
  TichLuyHomeModalFullImageProps
> = ({ visible, homePopup, funcSetModalVisible }) => {
  const userFromCache = cache_user();

  if (visible === false || !homePopup || !userFromCache) return <></>;

  return (
    <Modal
      className="modal-tich-luy-home-popup-fullscreen"
      visible={visible}
      onClose={() => {
        funcSetModalVisible(false);
      }}
      zIndex={1200}
    >
      <ProcessActionTypeTichLuy
        action_type={homePopup.action_type}
        action_data={homePopup.action_data}
        requiredLogin={true}
      >
        <div className="zaui-modal-content-cover">
          <img src={homePopup.image_url} />
        </div>
      </ProcessActionTypeTichLuy>

      <span
        onClick={() => {
          funcSetModalVisible(false);
        }}
      >
        <Icon
          className="font-size-35 text-white"
          icon={"zi-close-circle"}
        ></Icon>
      </span>
    </Modal>
  );
};

export default TichLuyHomeModalFullImage;
