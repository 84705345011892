import React from "react";
import { Page, Swiper, Text } from "zmp-ui";
import { tlActionTypeEnum } from "../../../enums";
import { TLHomeBlockModel, TLMerchantModel } from "../../../models";
import { capitalizeFirstLetter } from "../../../utility";
import TichLuyListMerchant from "../merchant/list-merchant";

interface TichLuyHomeBlockListMerchantProps {
  block: TLHomeBlockModel;
  textButtonViewMore?: string;
  funcOnClickBtnViewMore?(): void;
  funcShowModalMerchantDetail(merchantId: number): Promise<void>;
}

const TichLuyHomeBlockListMerchant: React.FunctionComponent<
  TichLuyHomeBlockListMerchantProps
> = ({
  block,
  textButtonViewMore,
  funcOnClickBtnViewMore,
  funcShowModalMerchantDetail,
}) => {
  textButtonViewMore = textButtonViewMore ?? "Xem thêm";

  let viewImage: any = <></>;
  if (block.list_home_block_item?.length > 0) {
    const listMerchantViewModel: TLMerchantModel[] = block.list_home_block_item
      .filter(
        (x) =>
          x.action_type ===
          tlActionTypeEnum.ScreenTichLuyMerchantDetail
      )
      .map((item) => {
        const viewModel: TLMerchantModel = {
          id: parseInt(item.action_data),
          image_url: item.image_url,
          cashback_text: item.text_line_1 ?? "",
          name: "",
          cashback_terms: "",
          group_tiers: [],
          enable_cashback: true,
          category_ids: [],
          alias: "",
        };
        return viewModel;
      });

    viewImage = (
      <TichLuyListMerchant
        merchants={listMerchantViewModel}
        funcShowModalMerchantDetail={funcShowModalMerchantDetail}
      />
    );
  }

  return (
    <div className="block-container">
      <Text.Title className="m-b-10" size="normal">
        {capitalizeFirstLetter(block.title ?? "")}
      </Text.Title>
      {viewImage}
      <div className="m-t-15 text-center">
        <button
          onClick={funcOnClickBtnViewMore}
          className="btn btn-solid btn-border"
        >
          {textButtonViewMore}
        </button>
      </div>
    </div>
  );
};

export default TichLuyHomeBlockListMerchant;
