import React, { useState } from "react";
import { useLocation } from "react-router";
import { useSetRecoilState } from "recoil";
import { Icon, BottomNavigation, useNavigate } from "zmp-ui";
import { cache_user } from "../../cache-manager";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED,
  ROUTE_PATH_NGHIEN_REVIEW_HOME,
  ROUTE_PATH_NGHIEN_REVIEW_USER,
  ROUTE_PATH_TICH_LUY_HOME,
  ROUTE_PATH_TICH_LUY_USER,
} from "../../constants";
import { setPageHomeLastViewProductId } from "../../helper";

interface bottomNavigationCustomProps {
  children?: React.ReactNode;
}

const BottomNavigationNghienReview: React.FunctionComponent<
  bottomNavigationCustomProps
> = () => {
  const userFromCache = cache_user();
  const [activeTab, setActiveTabState] = useState("DealBanChay");
  const setActiveTab: any = (key: string) => {
    console.log(key);
    setActiveTabState(key);
  };
  const navigate = useNavigate();

  return (
    <BottomNavigation
      id="bottomNavigation"
      fixed
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key)}
    >
      <BottomNavigation.Item
        label="Trang chủ"
        key="TichLuyHome"
        icon={<Icon icon="zi-home" />}
        activeIcon={<Icon icon="zi-home" />}
        onClick={() => {
          navigate(ROUTE_PATH_TICH_LUY_HOME, {
            animate: false,
          });
        }}
      />
      <BottomNavigation.Item
        key="DealBanChay"
        label="Deal bán chạy"
        icon={<Icon icon="zi-star" />}
        activeIcon={<Icon icon="zi-star-solid" />}
        onClick={() => {
          setPageHomeLastViewProductId(undefined);
          navigate(ROUTE_PATH_NGHIEN_REVIEW_HOME, {
            animate: false,
            replace: true,
          });
        }}
      />
      <BottomNavigation.Item
        label="Đã mua"
        key="DaMua"
        icon={<Icon icon="zi-heart" />}
        activeIcon={<Icon icon="zi-heart-solid" />}
        onClick={() => {
          navigate(ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED, {
            animate: false,
            replace: true,
          });
        }}
      />
      <BottomNavigation.Item
        key="User"
        label="Tài khoản"
        icon={<Icon icon="zi-user" />}
        activeIcon={<Icon icon="zi-user-solid" />}
        onClick={() => {
          let routePath = "";
          // nếu đã đăng nhập tài khoản TL thì hiển thị tài khoản TL
          if (userFromCache?.tich_luy_user) {
            routePath = ROUTE_PATH_TICH_LUY_USER;
          }
          // hiển thị tài khoản nghiện review
          else {
            routePath = ROUTE_PATH_NGHIEN_REVIEW_USER;
          }
          navigate(routePath, {
            animate: false,
            replace: true,
          });
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNavigationNghienReview;
