import React from "react";
import { Page } from "zmp-ui";
import TichLuyDonationListItem from "../../components/tich_luy/donation/donation-list-item";
import { cache_tlDonationCampaigns } from "../../cache-manager";
import DataLoading from "../../components/share/data-loading";

const TichLuyDonationIndex: React.FunctionComponent = () => {
  const pageHideScrollnbar = true;
  const donationCampaigns = cache_tlDonationCampaigns();

  if (!donationCampaigns) {
    return <DataLoading />;
  }

  return (
    <Page className="page" hideScrollbar={pageHideScrollnbar}>
      <div className="m-t-15 text-center">
        <h2>Các hoàn cảnh quyên góp</h2>
        <label>
          Chung tay quyên góp giúp đỡ các hoàn cảnh khó khăn trên khắp cả nước.
        </label>
      </div>
      <div className="grid grid-cols-1 gap-1.5rem md:grid-cols-2 lg:grid-cols-3 m-t-15">
        {donationCampaigns.map((campaign) => (
          <TichLuyDonationListItem
            key={campaign.id}
            donationCampaign={campaign}
          />
        ))}
      </div>
    </Page>
  );
};

export default TichLuyDonationIndex;
