import React, { useState } from "react";
import { Icon } from "zmp-ui";

interface noteOpenLinkExternalParams {
  visible_content?: boolean;
}

const NoteOpenLinkExternal: React.FunctionComponent<
  noteOpenLinkExternalParams
> = ({ visible_content }) => {
  const [contentVisible, setcontentVisible] = useState(
    visible_content ?? false
  );

  const onClickDisplayContent = (event: React.MouseEvent<HTMLElement>) => {
    setcontentVisible(true);
  };

  return (
    <>
      {contentVisible ? (
        <div className="text-center m-t-10">
          <hr />
          <span className="content-note-open-external">
            Hiện tại Zalo Mini App đang trong giai đoạn phát triển và chưa hỗ
            trợ mở trực tiếp liên kết bằng trình duyệt điện thoại.
          </span>
        </div>
      ) : (
        <div className="text-center m-t-10" onClick={onClickDisplayContent}>
          <span className="font-size-12 text-success">
            Tại sao phải mở bằng trình duyệt ĐT?
            <Icon
              className="m-b-5"
              icon="zi-chevron-down"
              style={{ color: "#acef90" }}
            />
          </span>
        </div>
      )}
    </>
  );
};

export default NoteOpenLinkExternal;
