import React from "react";
import { Icon } from "zmp-ui";
import { dealSlotStatusEnum, stepBuyDealEnum } from "../../../enums";
import { dealSlot } from "../../../models";
import ImageWithViewDetail from "../../share/image-with-view-detail";
import StepUpdateOrder from "./process_step/step-update-order";
import StepUpdateReview from "./process_step/step-update-review";

interface componentParams {
  step: stepBuyDealEnum;
  dealSlot?: dealSlot;
  funcOnClickBtnJoinDeal?(event: React.MouseEvent<HTMLElement>): void;
  funcOnClickBtnUpdateInfo?(
    event: React.MouseEvent<HTMLElement>,
    actionText?: string
  ): void;
  funcFetchDataDealDetail(): Promise<void>;
}

const DealDetailBuyProcessStep: React.FunctionComponent<componentParams> = ({
  step,
  dealSlot,
  funcOnClickBtnJoinDeal,
  funcOnClickBtnUpdateInfo,
  funcFetchDataDealDetail,
}) => {
  const slotStatus: dealSlotStatusEnum | null | undefined =
    dealSlot?.status_code;

  switch (step) {
    case "B1": {
      switch (slotStatus) {
        case null:
        case undefined:
          return (
            <div>
              <a onClick={funcOnClickBtnJoinDeal} className="btn btn-solid">
                Đăng ký
              </a>
            </div>
          );
        case dealSlotStatusEnum.Expired:
          return (
            <div>
              <Icon
                className="m-b-5 font-size-18 text-danger"
                icon="zi-close-circle-solid"
              />
              <span> Lượt mua hết hạn</span> |{" "}
              <a onClick={funcOnClickBtnJoinDeal} className="color-primary">
                Đăng ký{" "}
                <Icon className="m-b-5 font-size-18" icon="zi-arrow-right" />
              </a>
            </div>
          );
        default:
          return (
            <div>
              <Icon
                className="m-b-5 font-size-18"
                icon="zi-check-circle-solid"
                style={{ color: "#00800087" }}
              />
              <span className="color-disabled"> Đã đăng ký</span> |{" "}
              <a onClick={funcOnClickBtnJoinDeal} className="color-primary">
                Mua deal{" "}
                <Icon className="m-b-5 font-size-18" icon="zi-arrow-right" />
              </a>
            </div>
          );
      }
    }
    case "B2": {
      switch (slotStatus) {
        case null:
        case undefined:
        case dealSlotStatusEnum.Expired:
          return (
            <div>
              <button className="btn btn-disabled">
                Cập nhật ảnh đơn hàng
              </button>
            </div>
          );
        default: {
          return (
            <StepUpdateOrder
              funcFetchDataDealDetail={funcFetchDataDealDetail}
              dealSlot={dealSlot as dealSlot}
            />
          );
        }
      }
    }
    case "B3": {
      switch (slotStatus) {
        case null:
        case undefined:
        case dealSlotStatusEnum.WaitingForBuyer:
        case dealSlotStatusEnum.Expired:
          return (
            <div>
              <button className="btn btn-disabled">
                Cập nhật ảnh đánh giá SP
              </button>
            </div>
          );
        default:
          return (
            <StepUpdateReview
              funcFetchDataDealDetail={funcFetchDataDealDetail}
              dealSlot={dealSlot as dealSlot}
            />
          );
      }
    }
    case "B4": {
      switch (slotStatus) {
        case null:
        case undefined:
        case dealSlotStatusEnum.WaitingForBuyer:
        case dealSlotStatusEnum.Expired:
        case dealSlotStatusEnum.WaitingForBuyerReview:
          return (
            <div>
              <button className="btn btn-disabled">Chờ hoàn tiền</button>
            </div>
          );
        case dealSlotStatusEnum.WaitingForSeller:
          return (
            <div>
              <span className="text-info">Đang chờ người bán xét duyệt</span>
            </div>
          );
        case dealSlotStatusEnum.Rejected:
          return (
            <div>
              <span className="text-danger">Người bán đã từ chối lượt mua</span>
              <br />
              {dealSlot?.reject_reason ? (
                <span>Lý do: {dealSlot.reject_reason}</span>
              ) : (
                ""
              )}
            </div>
          );
        case dealSlotStatusEnum.Approved:
          return (
            <div>
              <span className="text-success">Người bán đã duyệt lượt mua</span>
            </div>
          );
        case dealSlotStatusEnum.Rewarded: {
          let viewImagesReward = <></>;
          if (dealSlot?.reward_image_link) {
            viewImagesReward = (
              <div className="flex m-t-10 m-b-10 m-r-10">
                {dealSlot.reward_image_link.map((imageUrl, index) => (
                  <ImageWithViewDetail
                    key={index}
                    className="img-square-50px"
                    src={imageUrl}
                  />
                ))}
              </div>
            );
          }

          return (
            <div>
              <span className="text-success">
                Người bán đã trả thưởng lượt mua
              </span>
              {viewImagesReward}
            </div>
          );
        }
        default:
          return (
            <div>
              <span>{slotStatus}</span>
            </div>
          );
      }
    }
    default:
      return <></>;
  }
};

export default DealDetailBuyProcessStep;
