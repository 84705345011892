import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Avatar, Box, Page, Text, useNavigate, useSnackbar } from "zmp-ui";
import { URL_LOGO_APP } from "../../constants";
import { setTlWebAppInformartion } from "../../helper";
import { tl_etoken_cookie } from "../../cookie-helpers";

const TichLuyAppOpenPage: React.FunctionComponent = () => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const tl_eToken = searchParams.get("tl_etoken");
  const partnerAlias = searchParams.get("partner");
  const callbackUrl = searchParams.get("callback_url");

  useEffect(() => {
    // nếu ko có tlEtoken trên queryString
    if (!tl_eToken) {
      // nếu trong cookie có tlEtoken rồi thì ko làm gì
      // đợi fetch data từ api để chạy app thôi
      if (tl_etoken_cookie) {
        // do noting
      }
      // báo lỗi ko hợp lệ
      else {
        openSnackbar({
          text: "Liên kết không hợp lệ",
          type: "error",
        });
      }
    }
    // lưu thông tin vào cookie và xử lý redirect
    else {
      setTlWebAppInformartion(tl_eToken, partnerAlias);

      // nếu có callbackUrl thì đi theo callbackUrl
      if (callbackUrl) {
        navigate(callbackUrl, { replace: true });
      }
      // về trang chủ
      else {
        navigate("/", { replace: true, animate: false });
      }
      // sau khi set cookie xong refresh lại trang cho ăn cookie
      navigate(0);
    }

    return () => {
      //console.log("Cleanup functio deal detail");
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, []);

  return (
    <Page className="page" hideScrollbar={true}>
      <div className="text-center">
        <img className="w-100" src={URL_LOGO_APP} />
      </div>
      <div className="m-a text-center">
        <p>Đang tải dữ liệu...</p>
      </div>
    </Page>
  );
};

export default TichLuyAppOpenPage;
