export function addMinutes(date: Date, minutes: number): Date {
  return new Date(date.getTime() + minutes * 60000);
}

export function addSeconds(date: Date, seconds: number): Date {
  return new Date(date.getTime() + seconds * 1000);
}

export function formatCurrencyVND(number: number, isShorten: boolean = false) {
  if (isShorten) {
    return formatCurrencyWithShorten(number);
  }

  const formatter = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
    minimumFractionDigits: 0,
  });
  const formattedNumber = formatter.format(number);

  return formattedNumber;
}

export function formatCurrencyVNDToNumber(value: string) {
  const newVal = value.replace(/[^0-9]/g, "");

  if (!newVal) return undefined;

  return parseInt(newVal);
}

export function formatCurrencyWithShorten(num: number) {
  if (num >= 1000000) {
    const tr = (num / 1000000).toFixed(1);
    return tr.replace(/\.[0-9]/, "") + "tr";
  } else if (num >= 1000) {
    const k = (num / 1000).toFixed(1);
    return k.replace(/\.[0-9]/, "") + "k";
  } else {
    return num.toString();
  }
}

// Hàm helper để lấy các tham số query từ location
export function getQueryParams(location: any) {
  return new URLSearchParams(location.search);
}

interface QueryParams {
  [key: string]: string;
}

export function addQueryParams(url: string, params: QueryParams): string {
  const queryString = Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(params[key])}`)
    .join("&");
  if (!queryString) {
    return url;
  }
  const separator = url.includes("?") ? "&" : "?";
  return `${url}${separator}${queryString}`;
}

export const copyTextToClipboard = (text: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export function capitalizeFirstLetter(sentence: string) {
  sentence = sentence.toLowerCase();
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
}

export function updateQueryParams(
  url: string,
  params: Record<string, string>
): string {
  const urlObj = new URL(url);
  const searchParams = urlObj.searchParams;

  for (const key in params) {
    const encodedValue = encodeURIComponent(params[key]);

    const lowercaseKey = key.toLowerCase();
    let paramExists = false;

    // Kiểm tra xem có tồn tại param tương ứng không (không phân biệt hoa thường)
    searchParams.forEach((value, currentKey) => {
      if (currentKey.toLowerCase() === lowercaseKey) {
        paramExists = true;
        searchParams.set(currentKey, encodedValue); // Ghi đè giá trị mới (đã được mã hóa)
      }
    });

    if (!paramExists) {
      searchParams.append(key, encodedValue); // Tạo mới param (giá trị đã được mã hóa)
    }
  }

  return urlObj.toString();
}

export function getDateFromString(dateString: string): Date {
  return new Date(dateString);
}

export function calculateTimeDifference(
  startTime: Date,
  endTime: Date,
  type: "hour" | "minute" | "second" | "day" | "month" | "year"
): any {
  if (!startTime || !endTime || !type) {
    return "Missing required parameters";
  }

  const timeDiff = Math.abs(endTime.getTime() - startTime.getTime());

  switch (type) {
    case "hour":
      return { hours: Math.floor(timeDiff / 3600000) };
    case "minute":
      return { minutes: Math.floor(timeDiff / 60000) };
    case "second":
      return { seconds: Math.floor(timeDiff / 1000) };
    case "day":
      const timeDiffForDay = endTime.getTime() - startTime.getTime();
      return Math.floor(timeDiffForDay / (24 * 60 * 60 * 1000));
    case "month":
      return (
        endTime.getMonth() -
        startTime.getMonth() +
        12 * (endTime.getFullYear() - startTime.getFullYear())
      );
    case "year":
      return endTime.getFullYear() - startTime.getFullYear();
    default:
      return "Invalid time type";
  }
}

export function formatDate(
  dateString: string,
  format: "dd/MM/yyyy" | "dd/MM/yy" | "dd/MM/yyyy HH:mm"
): string {
  const date = new Date(dateString);
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  const formattedDay = day < 10 ? `0${day}` : day.toString();
  const formattedMonth = month < 10 ? `0${month}` : month.toString();
  const formattedYear = year.toString();
  const formattedHours = hours < 10 ? `0${hours}` : hours.toString();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes.toString();

  const formattedDate = format
    .replace("dd", formattedDay)
    .replace("MM", formattedMonth)
    .replace("yyyy", formattedYear)
    .replace("yy", formattedYear.slice(-2))
    .replace("HH", formattedHours)
    .replace("mm", formattedMinutes);

  return formattedDate;
}
