import { dealSlotTransferTypeEnum } from "./enums";

export const getTransferTypeName = (
  transferType: dealSlotTransferTypeEnum
): string => {
  switch (transferType) {
    case dealSlotTransferTypeEnum.Bank:
      return "Ngân hàng";
    case dealSlotTransferTypeEnum.Momo:
      return "Momo";
    case dealSlotTransferTypeEnum.ShopeePay:
      return "ShopeePay";
    case dealSlotTransferTypeEnum.ZaloPay:
      return "ZaloPay";
    default:
      return "";
  }
};
