import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { Icon, Modal, Spinner, useSnackbar } from "zmp-ui";
import { cache_user, set_cache_user_info } from "../../../cache-manager";
import { TLUserModel, userInfo } from "../../../models";
import {
  getAccountInfoTichLuyService,
  signinTichLuyService,
} from "../../../services/tl-user-service";
import {
  cache_UserState,
  tichLuyModalAccountVerifyState,
} from "../../../state";
import TichLuyFormMappingToken from "./form-mapping-token";
import { tl_etoken_cookie } from "../../../cookie-helpers";

const TichLuyModalAccountVerify: React.FunctionComponent = () => {
  const [cacheUserState, setCacheUserState] = useRecoilState(cache_UserState);
  const { openSnackbar } = useSnackbar();
  const [modalState, setModalState] = useRecoilState(
    tichLuyModalAccountVerifyState
  );
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [visibleFormMappingTokenState, setVisibleFormMappingTokenState] =
    useState(false);
  const userFromCache = cache_user();
  // chưa lấy đc user thì ko build html
  if (!userFromCache) {
    return <></>;
  }

  // check thêm nếu đã có tài khoản rồi thì ko cần render ra html modal để tối ưu
  if (userFromCache.tich_luy_user) {
    return <></>;
  }

  const setCacheTichLuyUser = (tlUser: TLUserModel) => {
    set_cache_user_info(cacheUserState, setCacheUserState, {
      ...userFromCache,
      tich_luy_user: tlUser,
    });
  };

  const processAfterAccountVerifySuccess = async (tlUser: TLUserModel) => {
    // lấy thêm thông tin user (số tiền, đơn hàng, ...) để lưu vào cache luôn
    const userInfoDetail = await getAccountInfoTichLuyService(tlUser.token);
    tlUser.info_detail = userInfoDetail;

    // lưu tlUser vào cache
    setCacheTichLuyUser(tlUser);
    // thực hiện callbackFunc
    modalState.callbackFunction(tlUser);
  };

  const signup = async () => {
    // đang load dữ liệu thì return
    if (isLoadingState) return;

    const tlEtoken = tl_etoken_cookie;
    if(!tlEtoken)
      return;

    try {
      setIsLoadingState(true);

      const tlUser = await signinTichLuyService(tlEtoken);

      // có lỗi xảy ra
      if (tlUser.error_from_api) {
        openSnackbar({
          text: tlUser.error_from_api.error_msg,
          type: "error",
        });
        return;
      }

      // set thông tin user Tích Lũy vào cache
      await processAfterAccountVerifySuccess(tlUser);
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Đã có lỗi xảy ra",
        type: "error",
      });
    } finally {
      setIsLoadingState(false);
    }
  };

  const onClickCloseModal = () => {
    setModalState({ ...modalState, isVisible: false });
  };

  const viewListBtnAction = (
    <>
      <div className="modal-title">
        Bạn đã có tài khoản trên ứng dụng Tích Lũy?
      </div>
      <div className="flex j-c-center m-t-25">
        <button
          onClick={signup}
          className="btn btn-warning btn-border btn-small w-115 m-r-12"
        >
          <Icon icon={"zi-stranger"} className="font-size-18 m-b-5" /> Chưa có
        </button>
        <button
          onClick={() => {
            setVisibleFormMappingTokenState(true);
          }}
          className="btn btn-solid btn-border btn-small w-115"
        >
          <Icon icon={"zi-user-check"} className="font-size-18 m-b-5" /> Đã có
        </button>
      </div>
      <div className="text-center m-t-15">
        <a
          //style={{ display: "none" }}
          onClick={onClickCloseModal}
          className=""
        >
          Đóng
        </a>
      </div>
    </>
  );

  const viewLoading = (
    <div className="row">
      <div className="m-a">
        <Spinner visible />
        <h4>Đang xử lý...</h4>
      </div>
    </div>
  );

  return (
    <Modal
      onClose={onClickCloseModal}
      className="modal-account-verify"
      visible={modalState.isVisible}
      verticalActions="true"
      zIndex={9999}
    >
      <>
        {isLoadingState ? (
          viewLoading
        ) : visibleFormMappingTokenState ? (
          <TichLuyFormMappingToken
            isLoadingState={isLoadingState}
            setIsLoadingState={setIsLoadingState}
            setVisibleFormMappingTokenState={setVisibleFormMappingTokenState}
            processAfterAccountVeirySuccess={processAfterAccountVerifySuccess}
            userFromCache={userFromCache}
          />
        ) : (
          viewListBtnAction
        )}
      </>
    </Modal>
  );
};

export default TichLuyModalAccountVerify;
