import React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { Avatar, Box, Icon, List, Page, Text, useNavigate } from "zmp-ui";
import { cache_user } from "../../cache-manager";
import DataLoading from "../../components/share/data-loading";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED,
  ROUTE_PATH_TICH_LUY_ACCOUNT_MAPPING,
  ROUTE_PATH_TICH_LUY_ORDERS,
  TL_WEB_ACCOUNT,
  TL_WEB_MAKE_MONEY,
  TL_WEB_ORDERS,
  TL_WEB_ORDER_CLAIM,
  TL_WEB_SUPPORT,
} from "../../constants";
import { tlActionTypeEnum } from "../../enums";
import { processActionTypeTichLuyHelper } from "../../helper";
import {
  tichLuyModalRedirectExternalState,
  tichLuyModalAccountVerifyState,
} from "../../state";

const { Item } = List;
const TichLuyUserPage: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const userFromCache = cache_user();
  const setModalRedirectExternalState = useSetRecoilState(
    tichLuyModalRedirectExternalState
  );

  if (!userFromCache?.tich_luy_user) {
    return <DataLoading />;
  }

  const onClickOpenRedirectExternal = (url: string) => {
    processActionTypeTichLuyHelper(
      userFromCache.tich_luy_user?.eToken as string,
      tlActionTypeEnum.RedirectUrl,
      url,
      navigate,
      setModalRedirectExternalState,
      userFromCache.app_settings
    );
  };

  return (
    <Page className="section-container m-t-35">
      <Box flex className="m-b-15">
        <Avatar
          story="default"
          online
          src={
            userFromCache.avatar.startsWith("http")
              ? userFromCache.avatar
              : undefined
          }
        >
          {userFromCache.avatar}
        </Avatar>
        <div className="flex m-l-15">
          <div className="m-a">
            <span className="f-w-b">{userFromCache.name}</span>
            <div>
              Tổng số tiền hoàn:{" "}
              {userFromCache.tich_luy_user.info_detail.lifetime_cashback_text}
            </div>
          </div>
        </div>
      </Box>
      <div className="row m-b-25">
        <div className="flex-1 text-center">
          Số dư khả dụng
          <br />
          <span className="text-success">
            {userFromCache.tich_luy_user.info_detail.balance_text}
          </span>
        </div>
        <div className="flex-1 text-center border-left-1">
          Chờ xác nhận
          <br />
          <span className="text-warning">
            {userFromCache.tich_luy_user.info_detail.balance_pending_text}
          </span>
        </div>
      </div>
      <List className="item-m-b-20">
        <Item
          onClick={() => {
            onClickOpenRedirectExternal(TL_WEB_ACCOUNT);
          }}
          title="Thông tin tài khoản"
          prefix={<Icon icon="zi-user-circle" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
        {/* <Item
          title="Cập nhật thông tin"
          prefix={<Icon icon="zi-user-settings" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
          onClick={() => {
            navigate(ROUTE_PATH_TICH_LUY_ACCOUNT_MAPPING, {
              animate: false,
            });
          }}
        /> */}
        <Item
          onClick={() => {
            navigate(ROUTE_PATH_TICH_LUY_ORDERS, {
              animate: true,
            });
          }}
          title="Đơn hàng đã mua"
          prefix={<Icon icon="zi-photo-search" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
        <Item
          onClick={() => {
            navigate(ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED, {
              animate: true,
            });
          }}
          title="Deal đã mua"
          prefix={<Icon icon="zi-photo-search" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
        <Item
          onClick={() => {
            onClickOpenRedirectExternal(TL_WEB_ORDER_CLAIM);
          }}
          title="Báo lỗi hoàn tiền"
          prefix={<Icon icon="zi-warning" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
        <Item
          onClick={() => {
            onClickOpenRedirectExternal(TL_WEB_MAKE_MONEY);
          }}
          title="Giới thiệu bạn bè"
          prefix={<Icon icon="zi-create-group" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
        <Item
          onClick={() => {
            onClickOpenRedirectExternal(TL_WEB_SUPPORT);
          }}
          title="Hỗ trợ"
          prefix={<Icon icon="zi-warning-circle" className="color-primary" />}
          suffix={<Icon icon="zi-chevron-right" />}
        />
      </List>
    </Page>
  );
};

export default TichLuyUserPage;
