import React, { useState } from "react";
import { Page, Text, useNavigate, useSnackbar } from "zmp-ui";
import {
  cache_tlAllMerchant,
  cache_tlListHomeBlock,
} from "../../../cache-manager";
import { ROUTE_PATH_TICH_ALL_MERCHANT } from "../../../constants";
import { tlHomeBlockTypeEnum } from "../../../enums";
import { TLMerchantModel } from "../../../models";
import { getTLMerchantDetailService } from "../../../services/tl-merchant-service";
import DataLoading from "../../share/data-loading";
import ModalDataLoading from "../../share/modal-data-loading";
import TichLuyHomeBlockFullImage from "../blocks/full-image";
import TichLuyHomeBlockListImage from "../blocks/list-image";
import TichLuyHomeBlockListImageWithText from "../blocks/list-image-with-text";
import TichLuyHomeBlockListImageWithTextVertical from "../blocks/list-image-with-text-vertical";
import TichLuyHomeBlockListMerchant from "../blocks/list-merchant";
import TichLuyHomeSlider from "../blocks/slider";
import TichLuyModalMerchantDetail from "../merchant/modal-merchant-detail";

const TichLuyHomeBlocks: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const listHomeBlocks = cache_tlListHomeBlock();
  const listMerchantFromCache = cache_tlAllMerchant();
  const [merchantDetailState, setMerchantDetailState] =
    useState<TLMerchantModel | null>(null);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [
    isVisibleModalMerchantDetailState,
    setIsVisibleModalMerchantDetailState,
  ] = useState(false);

  if (!listHomeBlocks) {
    return <DataLoading />;
  }

  const showModalMerchantDetail = async (merchantId: number) => {
    if (isLoadingState) return;
    setIsLoadingState(true);

    // fetch data merchant detail
    try {
      let merchant: TLMerchantModel | undefined = undefined;

      // nếu trong cache đã có thì ưu tiên lấy trong cache cho nhah
      if (listMerchantFromCache && listMerchantFromCache.length > 0) {
        merchant = listMerchantFromCache.find((m) => m.id === merchantId);
      }

      // nếu trong cache ko có thì gọi api
      if (!merchant) {
        merchant = await getTLMerchantDetailService(merchantId);
      }

      setMerchantDetailState(merchant);
      setIsVisibleModalMerchantDetailState(true);
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Có lỗi xảy ra!",
        type: "error",
      });
    } finally {
      setIsLoadingState(false);
    }
  };

  const viewBlock = listHomeBlocks.map((block) => {
    switch (block.type) {
      case tlHomeBlockTypeEnum.Slider:
        return <TichLuyHomeSlider key={block.id} block={block} />;
      case tlHomeBlockTypeEnum.Image:
        return <TichLuyHomeBlockFullImage key={block.id} block={block} />;
      case tlHomeBlockTypeEnum.ListImage:
        return <TichLuyHomeBlockListImage key={block.id} block={block} />;
      case tlHomeBlockTypeEnum.ListImageWithText:
        return (
          <TichLuyHomeBlockListImageWithText key={block.id} block={block} />
        );
      case tlHomeBlockTypeEnum.ListImageWithTextVertical:
        return (
          <TichLuyHomeBlockListImageWithTextVertical
            key={block.id}
            block={block}
          />
        );
      case tlHomeBlockTypeEnum.Merchants:
        return (
          <TichLuyHomeBlockListMerchant
            textButtonViewMore="Xem thêm 100+ cửa hàng"
            key={block.id}
            block={block}
            funcShowModalMerchantDetail={showModalMerchantDetail}
            funcOnClickBtnViewMore={() => {
              navigate(ROUTE_PATH_TICH_ALL_MERCHANT, {
                animate: false,
                replace: true,
              });
            }}
          />
        );
      default:
        return <></>;
    }
  });

  const viewModalMerchantDetail = merchantDetailState ? (
    <TichLuyModalMerchantDetail
      merchant={merchantDetailState}
      isVisible={isVisibleModalMerchantDetailState}
      funcSetModalVisible={setIsVisibleModalMerchantDetailState}
    />
  ) : (
    <></>
  );

  const viewModalDataLoading = isLoadingState ? (
    <ModalDataLoading visible={true} />
  ) : (
    <></>
  );

  return (
    <>
      {viewBlock}
      {viewModalMerchantDetail}
      {viewModalDataLoading}
    </>
  );
};

export default TichLuyHomeBlocks;
