import React from "react";
import { AnimationRoutes, useNavigate } from "zmp-ui";
import { Route, useSearchParams } from "react-router-dom";
import { cache_user } from "../../cache-manager";
import { userInfo } from "../../models";
import ModalConfirmCloseApp from "./modal-confirm-close-app";
import TichLuyModalAccountVerify from "../tich_luy/account/modal-account-verify";
import TichLuyModalRedirectExternal from "../tich_luy/share/modal-redirect-external";
//import { configAppView } from "zmp-sdk/apis";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH,
  ROUTE_PATH_NGHIEN_REVIEW_HOME,
  ROUTE_PATH_NGHIEN_REVIEW_USER,
  ROUTE_PATH_TICH_ALL_MERCHANT,
  ROUTE_PATH_TICH_LUY_ACCOUNT_MAPPING,
  ROUTE_PATH_TICH_LUY_APP_OPEN,
  ROUTE_PATH_TICH_LUY_DONATION,
  ROUTE_PATH_TICH_LUY_DONATION_DETAIL,
  ROUTE_PATH_TICH_LUY_HOME,
  ROUTE_PATH_TICH_LUY_ORDERS,
  ROUTE_PATH_TICH_LUY_USER,
} from "../../constants";
import HomePage from "../../pages/nghien_review";
import DealDetail from "../../pages/nghien_review/deal-detail";
import NghienReviewDealSearchPage from "../../pages/nghien_review/deal-search";
import DealsJoined from "../../pages/nghien_review/deals-joined";
import TichLuyHomePage from "../../pages/tich_luy";
import TichLuyAccountUpdatePage from "../../pages/tich_luy/account-update";
import TichLuyAllMerchantPage from "../../pages/tich_luy/merchants";
import TichLuyOrdersPage from "../../pages/tich_luy/orders";
import TichLuyUserPage from "../../pages/tich_luy/user";
import BottomNavigationCustom from "./bottom-navigation-custom";
import User from "../../pages/nghien_review/user";
import InternalTrackingPageView from "./tracking";
import TichLuyAppOpenPage from "../../pages/tich_luy/app-open";
import { tl_etoken_cookie } from "../../cookie-helpers";
import NotFound from "../../pages/share/not-found";
import Header from "./header";
import TichLuyDonationIndex from "../../pages/tich_luy/donation-index";
import TichLuyDonationDetail from "../../pages/tich_luy/donation-detail";

const Layout: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  // tạo callbackFunc sau khi lấy danh sách
  const callbackFuncForLoadCacheUser = async (user: userInfo) => {
    // dùng để load app nếu có path thì đến đúng path luôn
    const path = searchParams.get("path");
    if (path) {
      navigate(path, {
        animate: false,
      });
    }
  };

  // vào lấy liền user lưu vào cache để các page sau dùng ko cần đợi
  const userInfo = cache_user(callbackFuncForLoadCacheUser);

  const tlEtoken = tl_etoken_cookie;
  if(!tlEtoken || !userInfo) return <TichLuyAppOpenPage/>;

  return (
    <>
    <Header/>
      <AnimationRoutes>
      <Route
          path={ROUTE_PATH_TICH_LUY_DONATION_DETAIL}
          element={<TichLuyDonationDetail></TichLuyDonationDetail>}
        ></Route>
      <Route
          path={ROUTE_PATH_TICH_LUY_DONATION}
          element={<TichLuyDonationIndex></TichLuyDonationIndex>}
        ></Route>
      <Route
          path={ROUTE_PATH_TICH_LUY_APP_OPEN}
          element={<TichLuyAppOpenPage></TichLuyAppOpenPage>}
        ></Route>
        <Route
          path={ROUTE_PATH_TICH_LUY_HOME}
          element={<TichLuyHomePage></TichLuyHomePage>}
        ></Route>
        <Route
          path={ROUTE_PATH_TICH_LUY_ACCOUNT_MAPPING}
          element={<TichLuyAccountUpdatePage></TichLuyAccountUpdatePage>}
        ></Route>
        <Route
          path={ROUTE_PATH_TICH_LUY_USER}
          element={<TichLuyUserPage></TichLuyUserPage>}
        ></Route>
        <Route
          path={ROUTE_PATH_TICH_ALL_MERCHANT}
          element={<TichLuyAllMerchantPage></TichLuyAllMerchantPage>}
        ></Route>
        <Route
          path={ROUTE_PATH_TICH_LUY_ORDERS}
          element={<TichLuyOrdersPage></TichLuyOrdersPage>}
        ></Route>
        <Route
          path={ROUTE_PATH_NGHIEN_REVIEW_HOME}
          element={<HomePage></HomePage>}
        ></Route>
        <Route
          path={ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH}
          element={<NghienReviewDealSearchPage></NghienReviewDealSearchPage>}
        ></Route>
        <Route
          path={ROUTE_PATH_NGHIEN_REVIEW_USER}
          element={<User></User>}
        ></Route>
        <Route
          path={ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL}
          element={<DealDetail></DealDetail>}
        ></Route>
        <Route
          path={ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED}
          element={<DealsJoined></DealsJoined>}
        ></Route>
        {/* Route này sẽ bắt tất cả các route không khớp */}
        <Route path="*" element={<NotFound />} />
      </AnimationRoutes>
      <BottomNavigationCustom></BottomNavigationCustom>
      <TichLuyModalAccountVerify />
      <TichLuyModalRedirectExternal />
      <ModalConfirmCloseApp />
      <InternalTrackingPageView />
    </>
  );
};

export default Layout;
