import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { Icon, Page } from "zmp-ui";
import HomeDealItem from "../../components/nghien_review/home/home-deal-item";
import DataLoading from "../../components/share/data-loading";
import PageTitle from "../../components/share/page-title";
import { URL_LOGO_APP } from "../../constants";
import { dealItem, dealSearchPageModel } from "../../models";
import { getDealsBySearchService } from "../../services/deal-service";
import { cache_NRPageSearchDealResultState } from "../../state";

const NghienReviewDealSearchPage: React.FunctionComponent = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("q");
  const [searchPageState, setSearchPageState] = useState<dealSearchPageModel>({
    page_index: 1,
    page_size: 20,
    is_loading: false,
    visible_btn_load_more: false,
    is_loading_from_cache: false,
  });
  const [cacheSearchResult, setCacheSearchResult] = useRecoilState(
    cache_NRPageSearchDealResultState
  );
  const [valSearchState, setValSearchState] = useState("");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    console.log(searchQuery);
    console.log(searchPageState);
    // nếu chưa có tìm kiếm gì thì hiển thị trống và focus vào input search
    if (!searchQuery && inputRef.current) {
      inputRef.current.focus();
      return;
    }

    // nếu có thông tin tìm kiếm thì gọi API tìm kiếm
    if (searchQuery) {
      if (searchPageState.is_loading) return;

      // nếu load từ cache thì bỏ qua
      // vi lấy danh sách từ cache nên page index, query thay đổi
      // sẽ chạy vào đây 1 lần nữa nếu ko skip sẽ gọi API lấy lại dữ liệu
      if (searchPageState.is_loading_from_cache) {
        console.log("is_loading_from_cache: false,");
        setSearchPageState((prevState) => ({
          ...prevState,
          is_loading_from_cache: false,
        }));
        return;
      }

      // nếu load trang lần đầu kiêm tra trong cache đã có chưa, nếu có thì lấy dùng
      if (
        searchPageState.list_deal === undefined &&
        cacheSearchResult?.query === searchQuery
      ) {
        console.log("load cache");
        console.log(cacheSearchResult);
        setSearchPageState({
          ...cacheSearchResult,
          // chỉ set khi các đối tượng trong state thay đổi
          is_loading_from_cache:
            searchPageState.page_index !== cacheSearchResult.page_index ||
            searchPageState.page_size !== cacheSearchResult.page_size,
        });
        return;
      }

      // cache chưa có thì lấy từ API
      setSearchPageState((prevState) => ({
        ...prevState,
        is_loading: true,
        query: searchQuery,
      }));
      getDealsBySearchService(
        searchQuery,
        searchPageState.page_index,
        searchPageState.page_size
      )
        .then((listDeal) => {
          const newState = {
            ...searchPageState,
            list_deal: searchPageState.list_deal
              ? [...searchPageState.list_deal, ...listDeal]
              : listDeal,
            is_loading: false,
            visible_btn_load_more: listDeal?.length > 0,
          };
          setSearchPageState(newState);

          // cache lại state hiện tại để sử dụng cho back trang đỡ load lại
          console.log("save cache");
          console.log(newState);
          setCacheSearchResult(newState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [searchQuery, searchPageState.page_index, searchPageState.page_size]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const val = event.target.value;
    setValSearchState(val);
  };

  const onClickBtnLoadMore = () => {
    setSearchPageState((prevState) => ({
      ...prevState,
      page_index: prevState.page_index + 1,
    }));
  };

  const submitSearch = () => {
    if (valSearchState && valSearchState !== searchPageState.query) {
      setSearchParams({ q: valSearchState });
      setSearchPageState((prevState) => ({
        ...prevState,
        query: valSearchState,
        list_deal: [],
        page_index: 1,
      }));
    }
  };

  const viewClearTextForSearchMerchant = valSearchState ? (
    <div
      onClick={() => {
        setValSearchState("");
      }}
      className="flex m-a p-r-10"
    >
      <Icon style={{ color: "#ddd" }} icon={"zi-close-circle-solid"} />
    </div>
  ) : (
    <></>
  );

  const viewSearchMerchant = (
    <div
      className="m-t-10 flex border-solid-1 border-radius-12 p-l-5"
      style={{ flexFlow: "row" }}
    >
      <input
        onChange={handleInputChange}
        value={valSearchState}
        ref={inputRef}
        style={{ border: "none" }}
        placeholder="Nhập tên deal cần tìm kiếm..."
        className="form-control border-12 p-5 no-outline"
      />
      {viewClearTextForSearchMerchant}
      <div
        onClick={submitSearch}
        className="flex p-5 m-a btn-solid border-radius-right-12"
      >
        <Icon icon={"zi-search"} />
      </div>
    </div>
  );

  const viewButtonLoadMore = searchPageState.visible_btn_load_more ? (
    <div onClick={onClickBtnLoadMore} className="text-center">
      <button className="btn btn-solid btn-border btn-small">
        {searchPageState.is_loading ? "Đang tải dữ liệu..." : "Xem thêm"}
      </button>
    </div>
  ) : (
    <></>
  );

  const viewListDealItem = !searchPageState.list_deal ? (
    <></>
  ) : searchPageState.is_loading && searchPageState.page_index === 1 ? (
    <DataLoading />
  ) : searchPageState.list_deal.length === 0 ? (
    <div className="text-center m-t-25">
      <span className="text-danger">
        Không tìm thấy kết quả '{searchPageState.query}'
      </span>
    </div>
  ) : (
    <div className="m-t-15">
      <div className="m-b-15">Kết quả tìm kiếm '{searchPageState.query}'</div>
      {searchPageState.list_deal.map((item) => (
        <HomeDealItem key={item.id} item={item} />
      ))}
      {viewButtonLoadMore}
    </div>
  );

  return (
    <Page className="page" hideScrollbar={true}>
      <PageTitle title="Tìm kiếm deal"></PageTitle>
      {viewSearchMerchant}
      {viewListDealItem}
    </Page>
  );
};

export default NghienReviewDealSearchPage;
