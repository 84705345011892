import {
  callDealSlotUpdateInformation,
  categoryLv1s,
  listSlotStatus,
} from "../connect/nghien-review-client";
import {
  dealSlotUpdateInformationReponseModel,
  dealSlotUpdateInformationRequestModel,
  slotStatus,
} from "../models";

export const getListSlotStatus = async (
  apiAuthToken: string | undefined
): Promise<slotStatus[]> => {
  return listSlotStatus(apiAuthToken);
};

export const updateInformationForDealSlot = async (
  apiAuthToken: string | undefined,
  information: dealSlotUpdateInformationRequestModel
): Promise<dealSlotUpdateInformationReponseModel> => {
  return callDealSlotUpdateInformation(apiAuthToken, information);
};
