import React from "react";

const DataLoading: React.FunctionComponent = () => {
  return (
    <div className="m-a text-center">
      <p>Đang tải dữ liệu...</p>
    </div>
  );
};

export default DataLoading;
