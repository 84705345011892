import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Icon, Page, useNavigate, useSnackbar } from "zmp-ui";
import { dealDetailModel, dealItem } from "../../models";
import { useLocation } from "react-router";
import { copyTextToClipboard, formatCurrencyVND } from "../../utility";
import DataLoading from "../../components/share/data-loading";
import { getDealDetail } from "../../services/deal-service";
import DealDetailBuyProcess from "../../components/nghien_review/deal_detail/deal-detail-buy-process";
import { cache_user } from "../../cache-manager";
import { URL_LOGO_APP } from "../../constants";
import { dealSlotStatusEnum } from "../../enums";
import { useSearchParams } from "react-router-dom";
import { buildUrl_ZMA_Share } from "../../helper";
import Badge from "../../components/share/badge";

interface DealDetailProps {
  dealId: string | null;
}

const DealDetailChildComponent: React.FunctionComponent<DealDetailProps> = ({
  dealId,
}) => {
  const { openSnackbar } = useSnackbar();
  const [deal, setDeal] = useState<dealDetailModel | null>(null);
  const user = cache_user();
  const apiAuthToken = user?.nghien_review_user.api_auth_token;
  const navigate = useNavigate();

  const fetchDataDealDetail = useCallback(
    async (callbackFunc?: () => void) => {
      const deal = await getDealDetail(dealId as string, apiAuthToken);
      setDeal(deal);

      if (callbackFunc && typeof callbackFunc === "function") {
        console.log("callbackFunc");
        await setTimeout(() => {
          callbackFunc();
        }, 500);
      }
    },
    [dealId]
  );

  useEffect(() => {
    console.log("useEffect DealDetailChildComponent dealId: " + dealId);
    if (!dealId) {
      openSnackbar({
        text: "Mã deal không hợp lệ",
        type: "error",
      });
    } else {
      //console.log("fetch fetchDataDealDetail");
      fetchDataDealDetail();
    }

    return () => {
      //console.log("Cleanup functio deal detail");
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, [dealId]);

  if (!deal) {
    return <DataLoading></DataLoading>;
  }

  const onClickButtonShareLink = () => {
    const pathCurrentDeal = window.location.pathname + window.location.search;
    const linkShare = buildUrl_ZMA_Share(pathCurrentDeal);
    copyTextToClipboard(linkShare);
    openSnackbar({
      text: "Đã sao chép link chia sẻ bạn bè",
      type: "success",
    });
  };

  const htmlIsDeposit = deal.is_deposit && (
    <div className="m-a p-b-5">
      <Badge
        text="Đã ký quỹ đảm bảo hoàn tiền"
        type="success"
        icon={
          <Icon icon={"zi-check-circle-solid"} style={{ fontSize: "13px" }} />
        }
      />
    </div>
  );

  return (
    <Page className="page" hideScrollbar={true}>
      <div className="row space">
        <div
          // onClick={() => {
          //   navigate(-1);
          // }}
          className="deal-item-image m-a"
        >
          <img src={deal.image_url} />
        </div>

        <div className="view-deal-item-title">
          <label>{deal.name}</label>
        </div>
        {htmlIsDeposit}
        <div className="view-deal-item-price">
          <span className="deal-item-price">
            Giá bán: {formatCurrencyVND(deal.price, true)}
          </span>
          <span> - </span>
          <span className="deal-item-cashback">
            Hoàn tiền: {formatCurrencyVND(deal.cashback, true)}
          </span>
        </div>

        <div className="view-deal-item-description alert alert-warning">
          <Icon icon={"zi-info-circle"} className="text-success" />{" "}
          <span className="f-w-b">Deal hoàn tiền là gì?</span>
          <br />
          Là deal do người bán đề xuất, bạn mua sản phẩm trên{" "}
          {deal.merchant_name}, đánh giá sản phẩm và nhận hoàn tiền từ người bán
          trên Tích Lũy.
        </div>
        {/* <div
          onClick={onClickButtonShareLink}
          className="w-full alert alert-info"
        >
          <Icon icon={"zi-link"} className="text-success" />{" "}
          <span className="f-w-b">Chia sẻ ngay cho bạn bè</span>
          <Icon className="float-right" icon={"zi-arrow-right"} />
        </div> */}

        <div className="border-product"></div>

        {deal.deal_slot?.status_code === dealSlotStatusEnum.Blocked ? (
          <span className="text-danger">Bạn đã bị khóa lượt mua deal</span>
        ) : (
          <DealDetailBuyProcess
            item={deal}
            funcFetchDealDetail={fetchDataDealDetail}
          ></DealDetailBuyProcess>
        )}

        <div className="border-product m-t-15">
          <br />
          Mã deal {deal.id}.{user?.nghien_review_user.group_id}.
          {user?.nghien_review_user.id}
        </div>
      </div>
    </Page>
  );
};

const DealDetail: React.FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const dealId = searchParams.get("dealId");

  // ko dùng memo cache vì bị chạy fetch data deal 2 lần ban đầu do xug đột với
  // useSearchParams khi url thay đổi làm hook chạy và rerender lại component trong cache cũ
  // và component mới => chạy 2 lần useEffect
  //const MemoizedDealDetailChildComponent = memo(DealDetailChildComponent);
  const MemoizedDealDetailChildComponent = DealDetailChildComponent;
  return <MemoizedDealDetailChildComponent dealId={dealId} />;
};

export default DealDetail;
