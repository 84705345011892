import React, { useEffect, useState } from "react";
import { Page } from "zmp-ui";
import { cache_user } from "../../cache-manager";
import DataLoading from "../../components/share/data-loading";
import TichLuyListOrder from "../../components/tich_luy/order/list-order";
import TichLuyModalOrderDetail from "../../components/tich_luy/order/modal-order-detail";
import { URL_LOGO_APP } from "../../constants";
import { tlOrderModel } from "../../models";
import { getOrdersService } from "../../services/tl-order-service";

interface orderSearchModel {
  page_index: number;
  page_size: number;
  visible_btn_load_more: boolean;
  orders?: tlOrderModel[];
}

interface modalOrderDetailStateModel {
  visible: boolean;
  order?: tlOrderModel;
}

const TichLuyOrdersPage: React.FunctionComponent = () => {
  const userFromCache = cache_user();
  const [orderSearchState, setOrderSearchState] = useState<orderSearchModel>({
    page_index: 1,
    page_size: 20,
    visible_btn_load_more: false,
  });
  const [modalOrderDetailState, setModalOrderDetailState] =
    useState<modalOrderDetailStateModel>({
      visible: false,
    });
  const [isFetchingOrdersState, setIsFetchingOrdersState] = useState(false);

  useEffect(() => {
    console.log("useEffect Tich Luy Orders");

    // fetch data API orders
    setIsFetchingOrdersState(true);
    const ordersPromise = getOrdersService(
      orderSearchState.page_index,
      orderSearchState.page_size,
      userFromCache?.tich_luy_user?.token as string
    );
    ordersPromise.then((orders) => {
      setOrderSearchState((prevState) => ({
        ...prevState,
        orders: prevState.orders ? [...prevState.orders, ...orders] : orders,
        visible_btn_load_more: orders?.length > 0,
      }));
      setIsFetchingOrdersState(false);
    });

    return () => {
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, [orderSearchState.page_index, orderSearchState.page_size]);

  const funcSetModalVisible = (value: boolean, order?: tlOrderModel) => {
    // nếu hiển thị modal
    if (value && order) {
      setModalOrderDetailState({ visible: true, order: order });
    }
    // ẩn modal
    else {
      setModalOrderDetailState({ visible: false, order: undefined });
    }
  };

  const onClickBtnLoadMoreOrder = () => {
    if (isFetchingOrdersState) return;

    setIsFetchingOrdersState(true);
    setOrderSearchState((prevState) => ({
      ...prevState,
      page_index: prevState.page_index + 1,
    }));
  };

  const viewButtonLoadMoreOrder = orderSearchState.visible_btn_load_more ? (
    <div className="m-t-15 text-center">
      <button
        onClick={onClickBtnLoadMoreOrder}
        className="btn btn-solid btn-border btn-small"
      >
        {isFetchingOrdersState ? "Đang tải dữ liệu..." : "Xem thêm"}
      </button>
    </div>
  ) : (
    <></>
  );

  return (
    <Page className="page page-orders" hideScrollbar={true}>
     
      <div className="text-center">
        <h3>Danh Sách Đơn Hàng</h3>
      </div>
      <div className="m-t-15">
        {orderSearchState.orders ? (
          <TichLuyListOrder
            list_order={orderSearchState.orders}
            funcSetModalVisible={funcSetModalVisible}
          />
        ) : (
          <DataLoading />
        )}
      </div>
      {viewButtonLoadMoreOrder}
      <TichLuyModalOrderDetail
        order={modalOrderDetailState.order}
        funcSetModalVisible={funcSetModalVisible}
        isVisible={modalOrderDetailState.visible}
      />
    </Page>
  );
};

export default TichLuyOrdersPage;
