import { getAuthToken } from "../connect/nghien-review-client";
import { tl_etoken_cookie } from "../cookie-helpers";
import { TLUserModel, userInfo, userZaloInfo } from "../models";
import { getTLSettingsService } from "./tl-setting-service";
import {
  getAccountInfoTichLuyService,
  signinTichLuyService,
} from "./tl-user-service";

export const getUserFromZalo = async (): Promise<userZaloInfo | null> => {
  try {
    const userInfo = {
      id: "3368637342326461234",
      name: "User Name",
      avatar: "https://h5.zdn.vn/static/images/avatar.png",
    };
    return userInfo as userZaloInfo;
  } catch (error) {
    // xử lý khi gọi api thất bại
    console.log(error);
    return null;
  }
};

export const getUserInfoFromAPI = async (): Promise<userInfo | null> => {
  // lấy etoken của Tích Lũy dưới cookie
  const tl_etoken = tl_etoken_cookie;
  // nếu ko có giá trị thì return
  if (!tl_etoken) {
    console.error("Lỗi: App chưa được đăng nhập");
    return null;
  }

  // login Tích Lũy bằng eToken
  const infoAuthTichLuyPromise = signinTichLuyService(tl_etoken);

  // lấy appsetting
  const appSettingsPromise = getTLSettingsService();

  const [infoAuthTichLuy, appSettings] = await Promise.all([
    infoAuthTichLuyPromise,
    appSettingsPromise,
  ]);

  if (!infoAuthTichLuy || !infoAuthTichLuy.token) return null;
  
  // DEBUG API localhost
  infoAuthTichLuy.token = 'f86aea54-b9c3-4e51-935a-d387821d6a82-1';

  // 2023-06-23 nam.nguyen lấy thêm thông tin user về số tiền, ... lưu vào cache luôn
  const tlUserInfoDetail = await getAccountInfoTichLuyService(
    infoAuthTichLuy.token
  );
  infoAuthTichLuy.info_detail = tlUserInfoDetail;

  // lấy auth_token của API hệ thống Nghiện Review
  const authTokenNghienReview = await getAuthToken(
    tl_etoken,
    tlUserInfoDetail.name,
    tlUserInfoDetail.email,
    tlUserInfoDetail.facebook_user_id
  );

  const userInfo: userInfo = {
    name: tlUserInfoDetail.name,
    avatar:
      "https://r1.tichluy.vn/Content/desktop/img/favicon-trang.ico?v=r4634&t=638309068395071105", // TODO
    nghien_review_user: authTokenNghienReview,
    tich_luy_user: infoAuthTichLuy,
    app_settings: appSettings,
    is_showed_modal_home_popup_fullscreen: false,
  };

  return userInfo;
};
