import {
  API_GET_AUTH_TOKEN,
  API_GET_BANKS,
  API_GET_CATEGORIES_FOR_BUYER_COLLECT,
  API_GET_CATEGORY_LV1,
  API_GET_DEALS_BY_SEARCH,
  API_GET_DEALS_JOINED,
  API_GET_DEAL_DETAIL,
  API_GET_HOME_DEALS,
  API_GET_LIST_SLOT_STATUS,
  API_POST_CATEGORIES_FOR_BUYER_COLLECT,
  API_POST_DEAL_SLOT_UPDATE_INFORMATION,
  API_POST_JOIN_DEAL,
  DOMAIN_API_WITH_SCHEME,
  SECRET_KEY_SIGN_REQUEST_NGHIEN_REVIEW,
  SOURCE_AUTH_TYPE,
} from "../constants";
import { signRequest } from "../helper";
import {
  NghienReviewAuthTokenAPIModel,
  baseErrorFromAPIModel,
  categoryLv1,
  dealDetailModel,
  dealItem,
  dealJoined,
  dealSlotUpdateInformationReponseModel,
  dealSlotUpdateInformationRequestModel,
  errorfromAPIModel,
  joinDealModel,
  ReponseFromAPIModel,
  slotStatus,
  categoriesForBuyerCollectResponseModel,
  setCategoriesForBuyerCollectResponseModel,
  nghienReviewBankModel,
} from "../models";

export const setCategoriesForBuyerCollectNghienReviewClient = async (
  apiAuthToken: string | undefined,
  list_category_id: number[]
): Promise<setCategoriesForBuyerCollectResponseModel> => {
  const body = {
    categoryIds: list_category_id,
  };
  const response = await fetchWithAuth(
    `${API_POST_CATEGORIES_FOR_BUYER_COLLECT}`,
    apiAuthToken,
    "POST",
    body
  );
  const categories = await getDataAfterFetch(response);
  return categories;
};

export const getCategoriesForBuyerCollectNghienReviewClient = async (
  apiAuthToken: string | undefined
): Promise<categoriesForBuyerCollectResponseModel> => {
  const response = await fetchWithAuth(
    `${API_GET_CATEGORIES_FOR_BUYER_COLLECT}`,
    apiAuthToken
  );
  const categories = await getDataAfterFetch(response);
  return categories;
};

export const getBanksNghienReviewClient = async (): Promise<
  nghienReviewBankModel[]
> => {
  const response = await fetch(`${API_GET_BANKS}`);
  const banks = await getDataAfterFetch(response);
  return banks;
};

export const dealDetail = async (
  dealId: string,
  apiAuthToken: string | undefined
): Promise<dealDetailModel> => {
  const params = new URLSearchParams({
    dealId: dealId,
  }).toString();
  const response = await fetchWithAuth(
    `${API_GET_DEAL_DETAIL}?${params}`,
    apiAuthToken
  );
  const deal = await getDataAfterFetch(response);
  return deal;
};

export const dealsBySearch = async (
  name: string,
  pageIndex: number,
  pageSize: number = 20
): Promise<dealItem[]> => {
  const params = new URLSearchParams({
    name: name,
    pageIndex: pageIndex.toString(),
    pageSize: pageSize.toString(),
  }).toString();
  const response = await fetch(`${API_GET_DEALS_BY_SEARCH}?${params}`);
  const deals = await getDataAfterFetch(response);
  return deals;
};

export const dealsForPageHome = async (
  apiAuthToken: string | undefined,
  categoryLv1Name: string,
  pageSize: number = 100
): Promise<dealItem[]> => {
  const params = new URLSearchParams({
    cateLv1Name: categoryLv1Name,
    pageSize: pageSize.toString(),
  }).toString();
  const response = await fetchWithAuth(
    `${API_GET_HOME_DEALS}?${params}`,
    apiAuthToken
  );
  const deals = await getDataAfterFetch(response);
  return deals;
};

export const categoryLv1s = async (): Promise<categoryLv1[]> => {
  const url = `${API_GET_CATEGORY_LV1}`;
  const response = await fetch(url);
  const categoryLv1s = await getDataAfterFetch(response);
  return categoryLv1s;
};

export const listSlotStatus = async (
  apiAuthToken: string | undefined
): Promise<slotStatus[]> => {
  const url = `${API_GET_LIST_SLOT_STATUS}`;
  const response = await fetchWithAuth(url, apiAuthToken);
  const listSlotStatus = await getDataAfterFetch(response);
  return listSlotStatus;
};

export const getAuthToken = async (
  eTokenTichLuy: string,
  name: string,
  email: string,
  facebookUserId?: string | null
): Promise<NghienReviewAuthTokenAPIModel> => {
  const url = `${API_GET_AUTH_TOKEN}`;
  const params: Record<string, string> = {
    sourceAuthId: eTokenTichLuy,
    type: SOURCE_AUTH_TYPE,
    userAccountName: name,
    email: email,
  };

  // 2023-09-22 nam.nguyen khi ko có dữ liệu ko nên cho vào vì ảnh hưởng đến sign
  // request qua bên api sẽ có giá trị "null" chứ ko phải null làm ảnh hưởng sign
  if(facebookUserId){
    params["facebookUserId"] = facebookUserId
  }

  const response = await fetchWithSign(url, params);
  const apiAuthToken = await getDataAfterFetch(response);
  return apiAuthToken;
};

export const dealsJoined = async (
  slotStatusCode: string | null,
  apiAuthToken: string | undefined,
  pageIndex: number,
  pageSize: number
): Promise<dealJoined[]> => {
  const params = new URLSearchParams({
    status: slotStatusCode as string,
    pageIndex: pageIndex.toString(),
    pageSize: pageSize.toString(),
  }).toString();

  const url = `${API_GET_DEALS_JOINED}?${params}`;

  const response = await fetchWithAuth(url, apiAuthToken);
  const dealsJoined = await getDataAfterFetch(response);
  return dealsJoined;
};

export const callJoinDeal = async (
  dealId: string,
  apiAuthToken: string | undefined
): Promise<joinDealModel> => {
  const url = `${API_POST_JOIN_DEAL}`;
  const dataBody = {
    dealId: dealId,
  };

  const response = await fetchWithAuth(url, apiAuthToken, "POST", dataBody);
  const joinDealModel = await getDataAfterFetch(response);

  return joinDealModel;
};

export const callDealSlotUpdateInformation = async (
  apiAuthToken: string | undefined,
  information: dealSlotUpdateInformationRequestModel
): Promise<dealSlotUpdateInformationReponseModel> => {
  const url = `${API_POST_DEAL_SLOT_UPDATE_INFORMATION}`;
  console.log(information);
  const response = await fetchWithAuth(url, apiAuthToken, "POST", information);
  const result = await getDataAfterFetch(response);

  return result;
};

const fetchWithAuth = (
  url: string,
  apiAuthToken: string | undefined,
  method: "GET" | "POST" = "GET",
  dataBody?: object
): Promise<Response> => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", apiAuthToken ?? "");
  requestHeaders.set("Content-Type", "application/json" ?? "");

  return fetch(url, {
    method: method,
    headers: requestHeaders,
    body: dataBody ? JSON.stringify(dataBody) : undefined,
  });
};

const fetchWithSign = (
  url: string,
  params: Record<string, string>
): Promise<Response> => {
  const queyString = new URLSearchParams(params).toString();
  url = `${url}?${queyString}`;

  const urlT = new URL(url);
  const apiPath = urlT.pathname;
  const sign = signRequest(
    params,
    apiPath,
    SECRET_KEY_SIGN_REQUEST_NGHIEN_REVIEW
  );

  const requestHeaders: HeadersInit = new Headers();
  if (sign) {
    requestHeaders.set("sign", sign);
  }

  return fetch(url, {
    headers: requestHeaders,
  });
};

const getDataAfterFetch: any = async (response: Response) => {
  const resData = await response.json();

  // nếu có lỗi
  if (resData.Message && !resData.data) {
    console.log(resData);

    const error: errorfromAPIModel = {
      error_code: 1,
      error_msg: resData.Message,
    };
    const baseErorr: baseErrorFromAPIModel = {
      error_from_api: error,
    };
    return baseErorr;
  }

  const resDataModel = resData as ReponseFromAPIModel;
  // nếu lỗi
  if (resDataModel.error_code) {
    console.log(resData);

    const error: errorfromAPIModel = {
      error_code: resDataModel.error_code,
      error_msg: resDataModel.error_msg,
    };
    const baseErorr: baseErrorFromAPIModel = {
      error_from_api: error,
    };
    return resDataModel.data
      ? { ...resDataModel.data, ...baseErorr }
      : baseErorr;
  }

  // thành công thì trả ra data
  return resDataModel.data;
};
