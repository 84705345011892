import React from "react";
import { Icon } from "zmp-ui";
import { IconProps } from "zmp-ui/icon";

interface BadgeProps {
  text: string;
  type:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark";
  style?: "contextual" | "pill";
  classNameExtends?: string;
  icon?: any;
}

const Badge: React.FunctionComponent<BadgeProps> = ({
  text,
  type,
  style = "contextual",
  classNameExtends,
  icon,
}) => {
  return (
    <span
      className={`badge ${style} badge-${type} ${
        classNameExtends && classNameExtends
      }`}
    >
      {icon ? <>{icon} </> : <></>}
      {text && <span className="m-t-auto m-b-auto">{text}</span>}
    </span>
  );
};

export default Badge;
