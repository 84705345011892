import { atom, atomFamily } from "recoil";

import {
  cache_homeDeals,
  cache_categoryLv1,
  cache_SlotStatus,
  userInfo,
  cache_userInfo,
  pageIndexPositionModel,
  dealItem,
  cache_TLHomeBlocks,
  cache_TLAllMerchant,
  cache_TLAllCategory,
  TLModalAccountVerifyModel,
  TLModalRedirectExternalModel,
  dealSearchPageModel,
  cache_NRBanksModel,
  cache_TLDonationCampaigns,
} from "../src/models";

export const cache_UserState = atom<cache_userInfo | null>({
  key: "user",
  default: null,
});

export const cache_HomeDealsState = atomFamily<cache_homeDeals | null, string>({
  key: "cache_home_deals_by_category",
  default: null,
});

export const cache_CategoryLv1sState = atom<cache_categoryLv1 | null>({
  key: "cache_category_lv1s",
  default: null,
});

export const cache_NRBanksState = atom<cache_NRBanksModel | null>({
  key: "cache_NRBanks",
  default: null,
});

export const cache_ListSlotStatusState = atom<cache_SlotStatus | null>({
  key: "cache_list_slot_status",
  default: null,
});

export const cache_TLHomeBlocksState = atom<cache_TLHomeBlocks | null>({
  key: "cache_list_tl_home_blocks",
  default: null,
});

export const cache_TLAllMerchantState = atom<cache_TLAllMerchant | null>({
  key: "cache_tl_all_merchant",
  default: null,
});

export const cache_TLAllCategoryState = atom<cache_TLAllCategory | null>({
  key: "cache_tl_all_category",
  default: null,
});

export const cache_TLDonationCampaignsState = atom<cache_TLDonationCampaigns | null>({
  key: "cache_tl_donation_campaigns",
  default: null,
});

export const cache_NRPageSearchDealResultState =
  atom<dealSearchPageModel | null>({
    key: "cache_nr_page_search_deal_result",
    default: null,
  });

export const tichLuyModalAccountVerifyState = atom<TLModalAccountVerifyModel>({
  key: "tichluy_modal_account_verify",
  default: {
    isVisible: false,
  } as TLModalAccountVerifyModel,
});

export const tichLuyModalRedirectExternalState =
  atom<TLModalRedirectExternalModel>({
    key: "tichluy_modal_redirect_external",
    default: {
      isVisible: false,
    } as TLModalRedirectExternalModel,
  });
