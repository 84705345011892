import React from "react";
import { Text } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { ACTION_TYPE_OF_NGHIEN_REVIEW } from "../../../constants";
import { tlActionTypeEnum } from "../../../enums";
import {
  TLHomeBlockModel,
} from "../../../models";
import { capitalizeFirstLetter } from "../../../utility";
import DataLoading from "../../share/data-loading";
import LazyImage from "../../share/lazy-image";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeBlockListImageWithTextProps {
  block: TLHomeBlockModel;
}

const TichLuyHomeBlockListImageWithTextVertical: React.FunctionComponent<
  TichLuyHomeBlockListImageWithTextProps
> = ({ block }) => {
  const userFromCache = cache_user();

  if (!userFromCache) {
    return <DataLoading />;
  }

  const blockItemViewAllDeal = block.list_home_block_item.find(
    (x) =>
      x.action_type === tlActionTypeEnum.ScreenNghienReviewDealAll
  );

  let viewImage: any = <></>;
  if (block.list_home_block_item?.length > 0 && userFromCache) {
    viewImage = block.list_home_block_item.map((item) => {
      // nếu là loại xem tất cả thì ko hiển thị theo dạng item như list ngang
      // mà ẩn luôn để hiển thị tất cả ngag với title
      if (
        item.action_type ===
        tlActionTypeEnum.ScreenNghienReviewDealAll
      ) {
        return <React.Fragment key={item.id}></React.Fragment>;
      }

      return (
        <ProcessActionTypeTichLuy
          action_type={item.action_type}
          action_data={item.action_data}
          // nếu là loại deal TL thì ko cần auth
          requiredLogin={
            !ACTION_TYPE_OF_NGHIEN_REVIEW.includes(item.action_type)
          }
          key={item.id}
        >
          <div
            className="block-with-text-vertical-container"
            style={{ width: `${item.image_width ?? 100}%` }}
          >
            <LazyImage className="w-full" src={item.image_url} />
            <div className="block-with-text-line-1">
              <span>{item.text_line_1}</span>
            </div>
            {item.text_line_2 ? (
              <div className="block-with-text-line-2">
                <span>{item.text_line_2}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </ProcessActionTypeTichLuy>
      );
    });
  }

  const viewMore = blockItemViewAllDeal ? (
    <ProcessActionTypeTichLuy
      action_type={blockItemViewAllDeal.action_type}
      action_data={blockItemViewAllDeal.action_data}
      // nếu là loại deal TL thì ko cần auth
      requiredLogin={
        !ACTION_TYPE_OF_NGHIEN_REVIEW.includes(blockItemViewAllDeal.action_type)
      }
      key={blockItemViewAllDeal.id}
    >
      <div className="text-center">
        <button className="btn btn-solid btn-border">
          Xem thêm 100+ deal hấp dẫn
        </button>
      </div>
    </ProcessActionTypeTichLuy>
  ) : (
    <></>
  );

  return (
    <div className="block-container">
      <div className="row">
        <Text.Title className="m-b-10 flex-1" size="normal">
          {capitalizeFirstLetter(block.title ?? "")}
        </Text.Title>
      </div>
      <div className="row-2-colum">{viewImage}</div>
      {viewMore}
    </div>
  );
};

export default TichLuyHomeBlockListImageWithTextVertical;
