import React, { useMemo, useState } from "react";
import { NonIndexRouteObject } from "react-router";
import { useSnackbar } from "zmp-ui";
import { cache_NRBanks, cache_user } from "../../../../cache-manager";
import { getTransferTypeName } from "../../../../enum-helpers";
import {
  dealSlotTransferTypeEnum,
  DealSlotUpdateInformationActionTypeEnum,
} from "../../../../enums";
import {
  dealSlot,
  dealSlotUpdateInformationRequestModel,
  fileImageInformation,
  selectOptionModel,
} from "../../../../models";
import { updateInformationForDealSlot } from "../../../../services/slot-service";
import DataLoading from "../../../share/data-loading";
import ImageWithViewDetail from "../../../share/image-with-view-detail";
import SelectWithSearch from "../../../share/select-with-search";

interface FormUpdateReviewProps {
  dealSlot: dealSlot;
  funcFetchDataDealDetail(): Promise<void>;
  funcSetShowFormUploadImageReviewState(value: boolean): void;
}

interface FormState {
  imageUrls: fileImageInformation[];
  transferTypeSelected?: dealSlotTransferTypeEnum;
  transferBankAccountName?: string;
  transferBankName?: string;
  transferBankId?: number;
  transferBankAccountNumber?: string;
  transferInfoMomo?: string;
  transferInfoShopeePay?: string;
  transferInfoZaloPay?: string;
}

const FormUpdateReview: React.FC<FormUpdateReviewProps> = ({
  dealSlot,
  funcFetchDataDealDetail,
  funcSetShowFormUploadImageReviewState,
}) => {
  const transferInfo = dealSlot.transfer_info;
  const [formState, setFormState] = useState<FormState>({
    imageUrls: [],
    transferTypeSelected: dealSlot.transfer_type_selected,
    transferBankAccountName: transferInfo.bank_account_name,
    transferBankAccountNumber: transferInfo.bank_account_number,
    transferBankId: transferInfo.bank_id,
    transferBankName: transferInfo.bank_name,
    transferInfoMomo: transferInfo.momo,
    transferInfoShopeePay: transferInfo.shopeepay,
    transferInfoZaloPay: transferInfo.zalopay,
  });
  const user = cache_user();
  const banks = cache_NRBanks();
  const apiAuthToken = user?.nghien_review_user.api_auth_token;
  const [uploading, setUploading] = useState<boolean>(false);
  const { openSnackbar } = useSnackbar();

  if (!apiAuthToken || !banks) {
    return <DataLoading />;
  }

  const dataSelectForBanks = banks.map(
    (b) => ({ title: b.name, value: b.id.toString() } as selectOptionModel)
  );

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let value: any = event.target.value;
    let transferBankNameUpdate: string | undefined;

    switch (name) {
      // nếu là giá trị cách thức nhận tiền thì parse quá số vì là enum
      case "transferTypeSelected": {
        value = parseInt(value);
        break;
      }
      // nếu cập nhật bankId thì cập nhật luôn bankName luôn để hiển thị selectInput
      case "transferBankId": {
        if (value) {
          value = parseInt(value);
          transferBankNameUpdate = banks.find((b) => b.id === value)?.name;
        }
        break;
      }
      default:
        break;
    }

    setFormState((prevState) => ({
      ...prevState,
      transferBankName: transferBankNameUpdate,
      [name]: value,
    }));
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const urls: fileImageInformation[] = [];
      const maxlengthFile = 3;

      for (let i = 0; i < Math.min(files.length, maxlengthFile); i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          urls.push({
            name: file.name,
            type: file.type,
            base64_url: reader.result as string,
          });
          if (urls.length === Math.min(files.length, maxlengthFile)) {
            setFormState((prevState) => ({
              ...prevState,
              imageUrls: urls,
            }));
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveImage = async () => {
    // chặn trường hợp ấn nhiều lần
    if (uploading) return;

    console.log("BEGIN UPDATE REVIEW");

    try {
      setUploading(true);
      const informationUpdate: dealSlotUpdateInformationRequestModel = {
        deal_slot_id: dealSlot.id,
        action_type: DealSlotUpdateInformationActionTypeEnum.updateReview,
        list_images: formState.imageUrls,
        transfer_type: formState.transferTypeSelected,
        transfer_info_momo: formState.transferInfoMomo,
        transfer_info_shopeepay: formState.transferInfoShopeePay,
        transfer_info_zalopay: formState.transferInfoZaloPay,
        transfer_info_bank_name: formState.transferBankName,
        transfer_info_bank_id: formState.transferBankId,
        transfer_info_bank_account_name: formState.transferBankAccountName,
        transfer_info_bank_account_number: formState.transferBankAccountNumber,
      };

      // gọi API
      const response = await updateInformationForDealSlot(
        apiAuthToken,
        informationUpdate
      );
      console.log(response);
      // Xử lý thành công
      if (!response.error_from_api) {
        // refresh lại data deal
        funcFetchDataDealDetail();
        // ẩn form update review
        funcSetShowFormUploadImageReviewState(false);

        openSnackbar({
          text: "Cập nhật thành công",
          type: "success",
        });
      }
      // Xử lý khi có lỗi
      else {
        openSnackbar({
          text: `${response.error_from_api.error_msg}. Code[${response.error_from_api.error_code}]`,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Có lỗi xảy ra!",
        type: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  let viewTransferInformation = <></>;
  switch (formState.transferTypeSelected) {
    case dealSlotTransferTypeEnum.Bank: {
      viewTransferInformation = (
        <>
          <div className="form-group">
            <label className="font-weight-bold">Ngân hàng</label>
            <SelectWithSearch
              label={"Ngân hàng"}
              options={dataSelectForBanks}
              placeholder={"Chọn ngân hàng"}
              input_name={"transferBankId"}
              onChangeCallback={handleInputChange}
              default_value={formState.transferBankId?.toString()}
            />
            {/* <input
              name="transferBankName"
              className="form-control"
              type="text"
              placeholder="Tên ngân hàng"
              value={formState.transferBankName}
              onChange={handleInputChange}
            /> */}
          </div>
          <div className="form-group">
            <label className="font-weight-bold">Tên chủ tài khoản</label>
            <input
              name="transferBankAccountName"
              className="form-control"
              type="text"
              placeholder="Tên chủ tài khoản"
              value={formState.transferBankAccountName}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label className="font-weight-bold">Số tài khoản</label>
            <input
              name="transferBankAccountNumber"
              className="form-control"
              type="text"
              placeholder="Số tài khoản"
              value={formState.transferBankAccountNumber}
              onChange={handleInputChange}
            />
          </div>
        </>
      );
      break;
    }
    case dealSlotTransferTypeEnum.Momo:
    case dealSlotTransferTypeEnum.ZaloPay:
    case dealSlotTransferTypeEnum.ShopeePay: {
      viewTransferInformation = (
        <div className="form-group">
          <label className="font-weight-bold">
            Số điện thoại {getTransferTypeName(formState.transferTypeSelected)}
          </label>
          <input
            name={`transferInfo${getTransferTypeName(
              formState.transferTypeSelected
            )}`}
            className="form-control"
            type="text"
            placeholder="Số điện thoại"
            value={
              formState.transferTypeSelected === dealSlotTransferTypeEnum.Momo
                ? formState.transferInfoMomo
                : formState.transferTypeSelected ===
                  dealSlotTransferTypeEnum.ShopeePay
                ? formState.transferInfoShopeePay
                : formState.transferInfoZaloPay
            }
            onChange={handleInputChange}
          />
        </div>
      );
      break;
    }
  }

  let viewImagesOld = <></>;
  if (dealSlot.review_image_link && formState.imageUrls.length === 0) {
    viewImagesOld = (
      <div className="flex m-t-10 m-b-10">
        {dealSlot.review_image_link.map((imageUrl, index) => (
          <ImageWithViewDetail
            key={index}
            className="img-square-50px m-r-10"
            src={imageUrl}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      <div className="form-group m-b-0">
        <label className="font-weight-bold">Hình ảnh đánh giá SP</label>
        {viewImagesOld}
        <input
          className="form-control"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          multiple
        />
      </div>
      <div className="m-b-10 font-size-12 font-style-italic">
        <span>* Tối đa 3 ảnh</span>
      </div>
      <div className="flex m-t-10 m-b-10">
        {formState.imageUrls.map((image, index) => (
          <img
            className="img-square-50px m-r-10"
            key={index}
            src={image.base64_url}
          />
        ))}
      </div>
      <div className="form-group">
        <label className="font-weight-bold">Chọn cách nhận tiền hoàn lại</label>
        <br />
        {dealSlot.deal_transfer_types.map((transferType, index) => {
          return (
            <div key={index}>
              <input
                name="transferTypeSelected"
                onChange={handleInputChange}
                type="radio"
                checked={formState.transferTypeSelected == transferType}
                value={transferType}
              />
              <label
                onClick={() => {
                  const eventFake: any = {
                    target: {
                      name: "transferTypeSelected",
                      value: transferType,
                    },
                  };
                  handleInputChange(eventFake);
                }}
              >
                Nhận tiền qua {getTransferTypeName(transferType)}
              </label>
            </div>
          );
        })}
      </div>
      {viewTransferInformation}
      <button
        className="btn btn-small btn-solid"
        onClick={handleSaveImage}
        disabled={uploading}
      >
        {uploading ? "Đang lưu..." : "Lưu"}
      </button>
    </div>
  );
};

export default FormUpdateReview;
