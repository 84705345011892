import { sendInternalTrackingTichLuyClient } from "../connect/tich-luy-client";
import {
  tlSendInternalTrackingReponseAPIModel,
  tlSendInternalTrackingRequestAPIModel,
} from "../models";

export const sendInternalTrackingTLService = async (
  tlAuthToken: string | undefined,
  requestModel: tlSendInternalTrackingRequestAPIModel
): Promise<tlSendInternalTrackingReponseAPIModel> => {
  return sendInternalTrackingTichLuyClient(tlAuthToken ,requestModel);
};
