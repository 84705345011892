import React from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, List, Text, Box, Page, Button, Icon } from "zmp-ui";
import { cache_user } from "../../cache-manager";

const UserPage = () => {
  const user = cache_user();

  const navigate = useNavigate();
  return (
    <Page className="page">
      <Box
        flex
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box>
          <Avatar
            story="default"
            size={96}
            online
            src={user?.avatar.startsWith("http") ? user.avatar : undefined}
          >
            {user?.avatar}
          </Avatar>
        </Box>
        <Box flex flexDirection="row" alignItems="center" ml={8}>
          <Box className="m-l-s-10">
            <Text.Title>{user?.name}</Text.Title>
          </Box>
          <Box ml={4}>
            {/* <Button
              onClick={() => {
                navigate("/form");
              }}
              size="small"
              icon={<Icon icon="zi-edit" />}
            /> */}
          </Box>
        </Box>
      </Box>
      <Box m={0} p={0} mt={4}>
        <div className="section-container">
          <List>
            <List.Item title="Tên tài khoản" subTitle={user?.name} />
            {/* <List.Item title="ID" subTitle={user?.zalo_id} /> */}
          </List>
        </div>
      </Box>
    </Page>
  );
};

export default UserPage;
