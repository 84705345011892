import React, { useState } from "react";
import { Icon, Modal, Spinner, useSnackbar } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { dealSlotStatusEnum, stepBuyDealEnum } from "../../../enums";
import {
  buildUrl_NghienReview_External_DealDetail,
  openUrlInWebView,
} from "../../../helper";
import { dealDetailModel, slotStatus } from "../../../models";
import { joinDeal } from "../../../services/deal-service";
import { copyTextToClipboard } from "../../../utility";
import DealDetailBuyProcessStep from "./deal-detail-buy-process-step";
import NoteOpenLinkExternal from "./deal-detail-note-open-external";
import ModalDataLoading from "../../share/modal-data-loading";

interface DealItemProps {
  item: dealDetailModel;
  funcFetchDealDetail: (callbackFunc?: () => void) => Promise<void>;
}

interface classNamesForHtml {
  step_info_status_text: string;
  step_bar: string;
}

const DealDetailBuyProcess: React.FunctionComponent<DealItemProps> = ({
  item,
  funcFetchDealDetail,
}) => {
  const user = cache_user();
  const apiAuthToken = user?.nghien_review_user.api_auth_token;
  const slotStatus: dealSlotStatusEnum | null | undefined =
    item.deal_slot?.status_code;
  const textDescriptionPopupViewDetailDefault = "xem chi tiết deal";
  const [textDescriptionPopupViewDetail, setTextDescriptionPopupViewDetail] =
    useState(textDescriptionPopupViewDetailDefault);
  const [popupAffLinkVisible, setPopupAffLinkVisible] = useState(false);
  const [popupViewDetailVisible, setPopupViewDetailVisible] = useState(false);
  const [popupDataLoadingVisible, setpopupDataLoadingVisible] = useState(false);
  const [affLink, setAffLink] = useState("");
  const { openSnackbar } = useSnackbar();
  const urlExternalDealDetail = buildUrl_NghienReview_External_DealDetail(
    item.detail_link,
    apiAuthToken as string
  );

  const callAPIJoinDeal = async () => {
    setpopupDataLoadingVisible(true);
    const resultJoinDeal = await joinDeal(item.id_e, apiAuthToken);

    // thành công
    if (resultJoinDeal.aff_link) {
      setAffLink(resultJoinDeal.aff_link);

      const callbackFunc = () => {
        setpopupDataLoadingVisible(false);

        // 2023-06-28 nam.nguyen nếu app setting mở trực tiếp link webview thì mở luôn
        if (user?.app_settings?.auto_redirect === true) {
          window.location.href = resultJoinDeal.aff_link;
        } else {
          setPopupAffLinkVisible(true);
        }
      };

      funcFetchDealDetail(callbackFunc);
    }
    // lỗi
    else {
      setpopupDataLoadingVisible(false);

      openSnackbar({
        text: resultJoinDeal.error_from_api
          ? resultJoinDeal.error_from_api.error_msg
          : "Đã có lỗi xảy ra",
        type: "error",
      });
    }
  };

  const onClickBtnJoinDeal = (event: React.MouseEvent<HTMLElement>) => {
    callAPIJoinDeal();
  };

  const copyAffLinkToClipboard = () => {
    copyTextToClipboard(affLink);
    openSnackbar({
      text: "Đã sao chép liên kết",
      type: "success",
    });
  };

  const onClickCopyAffLink = (event: React.MouseEvent<HTMLElement>) => {
    copyAffLinkToClipboard();
    setPopupAffLinkVisible(false);
  };

  const onClickBtnViewDetail = (
    event: React.MouseEvent<HTMLElement>,
    actionText?: string
  ) => {
    actionText = actionText ?? textDescriptionPopupViewDetailDefault;
    setTextDescriptionPopupViewDetail(actionText);
    setPopupViewDetailVisible(true);
  };

  const copyUrlExtternalDealDetailToClipboard = () => {
    copyTextToClipboard(urlExternalDealDetail);
    openSnackbar({
      text: "Đã sao chép liên kết",
      type: "success",
    });
  };

  const onClickCopyUrlExternalDealDetail = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    copyUrlExtternalDealDetailToClipboard();
    setPopupViewDetailVisible(false);
  };

  const getClassNamesForHtml = (
    step: stepBuyDealEnum,
    slotStatus: dealSlotStatusEnum | null | undefined
  ): classNamesForHtml => {
    let classNameForStatusStep = "step-info-status-text ";
    let classNameForStepBar = "process-step ";
    switch (slotStatus) {
      case null:
      case undefined:
      case dealSlotStatusEnum.Expired:
      case dealSlotStatusEnum.Blocked: {
        if (step === stepBuyDealEnum.B1) {
          classNameForStatusStep += "color-black";
          classNameForStepBar += "active";
        }
        break;
      }
      case dealSlotStatusEnum.WaitingForBuyer: {
        if (step === stepBuyDealEnum.B2) {
          classNameForStatusStep += "color-black";
          classNameForStepBar += "active";
        }
        break;
      }
      case dealSlotStatusEnum.WaitingForBuyerReview: {
        if (step === stepBuyDealEnum.B3) {
          classNameForStatusStep += "color-black";
          classNameForStepBar += "active";
        }
        break;
      }
      case dealSlotStatusEnum.WaitingForSeller:
      case dealSlotStatusEnum.Approved:
      case dealSlotStatusEnum.Rejected:
      case dealSlotStatusEnum.Rewarded: {
        if (step === stepBuyDealEnum.B4) {
          classNameForStatusStep += "color-black";
          classNameForStepBar += "active";
        }
        break;
      }
    }

    return {
      step_info_status_text: classNameForStatusStep,
      step_bar: classNameForStepBar,
    };
  };

  return (
    <>
      <div className="view-title-mutiple-step-join-deal">
        <label>📢 Các bước mua Deal</label>
      </div>
      <div className="view-deal-item-process">
        <div
          className={
            getClassNamesForHtml(stepBuyDealEnum.B1, slotStatus).step_bar
          }
        >
          <div className="step-bar">
            <div className="step-bar-circle"></div>
            <div className="step-bar-line"></div>
          </div>
          <div className="step-info">
            <div
              className={
                getClassNamesForHtml(stepBuyDealEnum.B1, slotStatus)
                  .step_info_status_text
              }
            >
              B1. Đăng ký mua Deal
            </div>
            <DealDetailBuyProcessStep
              funcFetchDataDealDetail={funcFetchDealDetail}
              funcOnClickBtnJoinDeal={onClickBtnJoinDeal}
              step={stepBuyDealEnum.B1}
              dealSlot={item.deal_slot}
            />
            <div className="step-info-divider"></div>
          </div>
        </div>
        <div
          className={
            getClassNamesForHtml(stepBuyDealEnum.B2, slotStatus).step_bar
          }
        >
          <div className="step-bar">
            <div className="step-bar-circle"></div>
            <div className="step-bar-line"></div>
          </div>
          <div className="step-info">
            <div
              className={
                getClassNamesForHtml(stepBuyDealEnum.B2, slotStatus)
                  .step_info_status_text
              }
            >
              B2. Cập nhật ảnh đơn hàng
            </div>
            <DealDetailBuyProcessStep
              funcFetchDataDealDetail={funcFetchDealDetail}
              funcOnClickBtnUpdateInfo={onClickBtnViewDetail}
              step={stepBuyDealEnum.B2}
              dealSlot={item.deal_slot}
            />
            <div className="step-info-divider"></div>
          </div>
        </div>
        <div
          className={
            getClassNamesForHtml(stepBuyDealEnum.B3, slotStatus).step_bar
          }
        >
          <div className="step-bar">
            <div className="step-bar-circle"></div>
            <div className="step-bar-line"></div>
          </div>
          <div className="step-info">
            <div
              className={
                getClassNamesForHtml(stepBuyDealEnum.B3, slotStatus)
                  .step_info_status_text
              }
            >
              B3. Cập nhật ảnh đánh giá sản phẩm
            </div>
            <DealDetailBuyProcessStep
              funcFetchDataDealDetail={funcFetchDealDetail}
              funcOnClickBtnUpdateInfo={onClickBtnViewDetail}
              step={stepBuyDealEnum.B3}
              dealSlot={item.deal_slot}
            />
            <div className="step-info-divider"></div>
          </div>
        </div>
        <div
          className={
            getClassNamesForHtml(stepBuyDealEnum.B4, slotStatus).step_bar
          }
        >
          <div className="step-bar">
            <div className="step-bar-circle"></div>
            <div className="step-bar-line"></div>
          </div>
          <div className="step-info">
            <div
              className={
                getClassNamesForHtml(stepBuyDealEnum.B4, slotStatus)
                  .step_info_status_text
              }
            >
              B4. Chờ duyệt ảnh và hoàn tiền
            </div>
            <DealDetailBuyProcessStep
              funcFetchDataDealDetail={funcFetchDealDetail}
              funcOnClickBtnUpdateInfo={onClickBtnViewDetail}
              step={stepBuyDealEnum.B4}
              dealSlot={item.deal_slot}
            />
          </div>
        </div>
      </div>
      <ModalDataLoading visible={popupDataLoadingVisible} />
      <Modal
        visible={popupViewDetailVisible}
        title="🔔 Thông báo"
        onClose={() => {
          copyUrlExtternalDealDetailToClipboard();
          setPopupViewDetailVisible(false);
        }}
        verticalActions="true"
        description={`Bạn vui lòng sao chép và mở liên kết bằng trình duyệt điện thoại để ${textDescriptionPopupViewDetail.toLowerCase()}.`}
      >
        <div className="text-center m-t-15">
          <input
            className="w-full h-30"
            type="text"
            value={urlExternalDealDetail}
            disabled
          />
        </div>
        <div className="text-center m-t-15">
          <button
            onClick={onClickCopyUrlExternalDealDetail}
            className="btn btn-solid"
          >
            <Icon className="m-b-5 font-size-18" icon="zi-copy" /> Sao chép liên
            kết
          </button>
        </div>
        <NoteOpenLinkExternal></NoteOpenLinkExternal>
      </Modal>
      <Modal
        visible={popupAffLinkVisible}
        title={
          slotStatus === 0 || slotStatus
            ? "🔔 Thông báo"
            : "✅ Đăng ký thành công"
        }
        //afterClose={funcFetchDealDetail}
        onClose={() => {
          copyAffLinkToClipboard();
          setPopupAffLinkVisible(false);
        }}
        verticalActions="true"
        description="Đây là link mua deal của bạn, hãy sao chép và mở trên trình duyệt điện thoại."
      >
        <div className="text-center m-t-15">
          <input className="w-full h-30" type="text" value={affLink} disabled />
        </div>
        <div className="text-center m-t-15">
          <button onClick={onClickCopyAffLink} className="btn btn-solid">
            <Icon className="m-b-5 font-size-18" icon="zi-copy" /> Sao chép liên
            kết
          </button>
        </div>
        <NoteOpenLinkExternal />
      </Modal>
    </>
  );
};

export default DealDetailBuyProcess;
