import React from "react";
import { tlDonationCampaignModel } from "../../../models";
import { cache_tlDonationCampaigns } from "../../../cache-manager";
import DataLoading from "../../share/data-loading";
import {
  getTLDonationCampaignOnGoingForDetailService,
  getTLDonationCampaignOthersForDetailService,
} from "../../../services/tl-donation-campaign-service";
import TichLuyDonationListItem from "./donation-list-item";
import { useNavigate } from "zmp-ui";
import { ROUTE_PATH_TICH_LUY_DONATION } from "../../../constants";

interface TichLuyDonationDetailOthersCampaignProps {
  campaignDetail: tlDonationCampaignModel;
}

const TichLuyDonationDetailOthersCampaign: React.FunctionComponent<
  TichLuyDonationDetailOthersCampaignProps
> = ({ campaignDetail }) => {
  const pageSizeCampaign = 3;
  const navigate = useNavigate();
  const campaignsFromCache = cache_tlDonationCampaigns();

  if (!campaignsFromCache) return <DataLoading />;

  const listCampaigns = getTLDonationCampaignOthersForDetailService(
    campaignDetail,
    campaignsFromCache,
    pageSizeCampaign
  );

  if (!listCampaigns || listCampaigns.length === 0) return <></>;

  const onClickBtnViewAll = () => {
    navigate(ROUTE_PATH_TICH_LUY_DONATION, {
      animate: false,
    });
  };

  // nếu SL chiến dịch lấy ra từ service = SL pageSize thì hiển thị nút xem tất cả
  const viewBtnViewAll = listCampaigns.length === pageSizeCampaign && (
    <div className="text-center m-t-15">
      <button
        onClick={onClickBtnViewAll}
        className="btn btn-outline-solid btn-border btn-small cursor-pointer"
      >
        Xem tất cả
      </button>
    </div>
  );

  return (
    <>
      <div className="m-t-35 font-size-18 text-bold">
        Các chương trình quyên góp khác
      </div>
      <div className="grid grid-cols-1 gap-1.5rem md:grid-cols-2 lg:grid-cols-3 m-t-15">
        {listCampaigns.map((campaign) => (
          <TichLuyDonationListItem
            key={campaign.id}
            donationCampaign={campaign}
          />
        ))}
      </div>
      {viewBtnViewAll}
    </>
  );
};

export default TichLuyDonationDetailOthersCampaign;
