import React from "react";
import { Page, Tabs } from "zmp-ui";
import { dealItem } from "../../models";

import PageTitle from "../../components/share/page-title";
import DealsJoinedItem from "../../components/nghien_review/deals_joined/deals-joined-item";
import DealsJoinedTabs from "../../components/nghien_review/deals_joined/deals-joined-tabs";
import { URL_LOGO_APP } from "../../constants";

const DealsJoined: React.FunctionComponent = () => {
  const title: string = "Deal đã tham gia";
  const pageHideScrollnbar: boolean = true;

  return (
    <Page className="page" hideScrollbar={pageHideScrollnbar}>
      <PageTitle title={title}></PageTitle>

      <DealsJoinedTabs></DealsJoinedTabs>
    </Page>
  );
};

export default DealsJoined;
