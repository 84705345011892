import React, { memo, useCallback, useEffect, useRef } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { Tabs, useNavigate } from "zmp-ui";
import { cache_categoryLv1s } from "../../../cache-manager";
import {
  buildUrl_NghienReview_Internal_PageDealDetail,
  setPageHomeLastViewProductId,
} from "../../../helper";
import { categoryLv1 } from "../../../models";
import DataLoading from "../../share/data-loading";
import HomeListDealItem from "./home-list-deal-item";

interface HomeTabsChildComponentProps {
  cateLv1: string | null;
  funcSetSearchParamCateLv1(params: object): any;
}

const HomeTabsChildComponent: React.FunctionComponent<
  HomeTabsChildComponentProps
> = ({ cateLv1, funcSetSearchParamCateLv1 }) => {
  const listCategoryLv1 = cache_categoryLv1s();
  const myElementRef = useRef<null | HTMLDivElement>(null);
  //console.log("begin render cateLv1 " + cateLv1);

  useEffect(() => {
    console.log("useEffect Hometab");
    if (myElementRef.current) {
      myElementRef.current.scrollIntoView({
        block: "end",
        inline: "center",
      });
    }

    return () => {
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, [cateLv1, listCategoryLv1]);

  const handleClick = useCallback(
    (cateLv1: string) => {
      //console.log(cateLv1);
      setPageHomeLastViewProductId(undefined);
      funcSetSearchParamCateLv1({ cateLv1: cateLv1 });
    },
    [funcSetSearchParamCateLv1]
  );

  if (!listCategoryLv1) {
    return <DataLoading></DataLoading>;
  }

  return (
    <>
      <div className="theme-tab">
        <ul className="tabs tab-title">
          {listCategoryLv1.map((cate: categoryLv1, index: number) => {
            const isCurrent =
              cate.name === cateLv1 || (!cateLv1 && index === 0);
            return (
              <li
                ref={isCurrent ? myElementRef : (null as any)}
                key={cate.name}
                className={isCurrent ? "current" : ""}
              >
                <a
                  data-category={cate.name}
                  onClick={() => {
                    handleClick(cate.name);
                  }}
                >
                  {cate.name}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
      <HomeListDealItem category_name={cateLv1 ?? ""}></HomeListDealItem>
    </>
  );
};

const HomeTabs: React.FunctionComponent = () => {
  // 2023-06-07 nam.nguyen ko sử dụng getQueryParams
  // vì khi dùng cache memo thì component sẽ ko lấy lại
  // đc giá trị querystring phải dùng hooks useParams/useSearchParams thì
  // component mới lấy lại đc khi có cache
  // const queryParams = getQueryParams(location);
  //const cateLv1 = queryParams.get("cateLv1");
  const [searchParams, setSearchParams] = useSearchParams();
  const cateLv1 = searchParams.get("cateLv1");
  // cache lại tránh tạo func nhiều lần dưới component con
  // chỉ thay đổi khi cateLv1 thay đổi
  const funcSetSearchParamCateLv1 = useCallback(setSearchParams, [cateLv1]);
  //console.log("Home tabs " + cateLv1);
  // hiện tại ko dùng memo cache vì xung đột với hook useSearchParams
  const HomeTabsCached = HomeTabsChildComponent;

  return (
    <HomeTabsCached
      funcSetSearchParamCateLv1={funcSetSearchParamCateLv1 as any}
      cateLv1={cateLv1}
    />
  );
};

export default HomeTabs;
