import React from "react";
import { Text } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { TLHomeBlockModel } from "../../../models";
import { capitalizeFirstLetter } from "../../../utility";
import LazyImage from "../../share/lazy-image";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeBlockListImageProps {
  block: TLHomeBlockModel;
}

const TichLuyHomeBlockListImage: React.FunctionComponent<
  TichLuyHomeBlockListImageProps
> = ({ block }) => {
  const userFromCache = cache_user();

  let viewImage: any = <></>;
  if (block.list_home_block_item?.length > 0 && userFromCache) {
    viewImage = block.list_home_block_item.map((item) => (
      <ProcessActionTypeTichLuy
        action_type={item.action_type}
        action_data={item.action_data}
        requiredLogin={true}
        key={item.id}
      >
      <LazyImage
        className="border-12 border-solid-1 h-full m-r-12"
        src={item.image_url}
        style={{ width: `${item.image_width ?? 100}%` }}
      />
      </ProcessActionTypeTichLuy>
    ));
  }

  return (
    <div className="block-container">
      <Text.Title className="m-b-10" size="normal">
        {capitalizeFirstLetter(block.title ?? "")}
      </Text.Title>
      <div className="scrolling-wrapper">{viewImage}</div>
    </div>
  );
};

export default TichLuyHomeBlockListImage;
