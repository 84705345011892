import React, { useEffect, useState } from "react";
import { Page } from "zmp-ui";

import { cache_user, set_cache_user_info } from "../../cache-manager";
import { URL_LOGO_APP } from "../../constants";
import TichLuyHomeBlocks from "../../components/tich_luy/home/blocks";
import TichLuyModalCollectCategories from "../../components/tich_luy/home/modal-collect-categories";
import {
  nghienReviewCategoryModel,
  TLHomePopupModel,
  userInfo,
} from "../../models";
import { getNRCategoriesForBuyerCollectService } from "../../services/category-service";
import { useRecoilState } from "recoil";
import { cache_UserState } from "../../state";
import DataLoading from "../../components/share/data-loading";
import { getTLHomePopupService } from "../../services/tl-home-popup";
import TichLuyHomeModalFullImage from "../../components/tich_luy/home/modal-full-image";
import Header from "../../components/share/header";

interface modalCategoriesForBuyerCollectModel {
  visibile: boolean;
  categories?: nghienReviewCategoryModel[];
}

interface modalTichLuyHomePopupModel {
  visibile: boolean;
  homePopup?: TLHomePopupModel;
}

const TichLuyHomePage: React.FunctionComponent = () => {
  const pageHideScrollnbar: boolean = true;
  const [
    modalCategoriesForBuyerCollectState,
    setModalCategoriesForBuyerCollectState,
  ] = useState<modalCategoriesForBuyerCollectModel>({ visibile: false });
  const [modalTichLuyHomePopupState, setModalTichLuyhomePopupState] =
    useState<modalTichLuyHomePopupModel>({ visibile: false });
  const [cacheUserInfoState, setCacheUserInfoState] =
    useRecoilState(cache_UserState);

  const funcSetVisibleModalCategoriesForBuyerCollect = (val: boolean) => {
    setModalCategoriesForBuyerCollectState((prevState) => ({
      ...prevState,
      visibile: val,
    }));
  };

  const funcSetVisibleModalTichLuyHomePopup = (val: boolean) => {
    setModalTichLuyhomePopupState((prevState) => ({
      ...prevState,
      visibile: val,
    }));
  };

  const userFromCache = cache_user();

  if (!userFromCache) return <DataLoading />;

  const getTLHomePopup = () => {
    if (userFromCache.is_showed_modal_home_popup_fullscreen !== true) {
      try {
        getTLHomePopupService(userFromCache.tich_luy_user?.token).then(
          (homePoup) => {
            // nếu có dữ liệu thì hiển thị
            if (homePoup) {
              setModalTichLuyhomePopupState({
                visibile: true,
                homePopup: homePoup,
              });
            }

            // lưu lại cờ đã hiển thị modal sau này chuyển trang ko fetch nữa
            const newUser: userInfo = {
              ...userFromCache,
              is_showed_modal_home_popup_fullscreen: true,
            };
            set_cache_user_info(
              cacheUserInfoState,
              setCacheUserInfoState,
              newUser
            );
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    // nếu chưa hiển thị modal thu thập dữ liệu ngành hàng thì hiển thị
    if (
      userFromCache.nghien_review_user.is_showed_modal_collect_categories !==
      true
    ) {
      try {
        getNRCategoriesForBuyerCollectService(
          userFromCache.nghien_review_user.api_auth_token
        ).then((categoriesForBuyerCollect) => {
          // nếu hiển thị modal
          if (categoriesForBuyerCollect.visible_form_collect) {
            setModalCategoriesForBuyerCollectState({
              visibile: true,
              categories: categoriesForBuyerCollect.categories,
            });
          }
          // 2023-07-21 nam.nguyen hiển thị modal poup quảng cáo khuyến mãi
          else {
            getTLHomePopup();
          }

          // lưu lại cờ đã hiển thị modal sau này chuyển trang ko fetch nữa
          const newUser: userInfo = {
            ...userFromCache,
            nghien_review_user: {
              ...userFromCache.nghien_review_user,
              is_showed_modal_collect_categories: true,
            },
          };
          set_cache_user_info(
            cacheUserInfoState,
            setCacheUserInfoState,
            newUser
          );
        });
      } catch (error) {
        console.log(error);
      }
    }
    // 2023-07-21 nam.nguyen bổ sung thêm hiển thị popup màn hình home quảng cáo
    else {
      getTLHomePopup();
    }
  }, [
    userFromCache?.nghien_review_user.api_auth_token,
    userFromCache?.tich_luy_user?.token,
  ]);

  const viewModalCategoriesForBuyerCollect =
    modalCategoriesForBuyerCollectState &&
    modalCategoriesForBuyerCollectState.categories &&
    modalCategoriesForBuyerCollectState.categories.length > 0 ? (
      <TichLuyModalCollectCategories
        visible={modalCategoriesForBuyerCollectState.visibile}
        funcSetModalVisible={funcSetVisibleModalCategoriesForBuyerCollect}
        categories={modalCategoriesForBuyerCollectState.categories}
      />
    ) : (
      <></>
    );

  const viewModalHomePopup =
    modalTichLuyHomePopupState && modalTichLuyHomePopupState.homePopup ? (
      <TichLuyHomeModalFullImage
        visible={modalTichLuyHomePopupState.visibile}
        funcSetModalVisible={funcSetVisibleModalTichLuyHomePopup}
        homePopup={modalTichLuyHomePopupState.homePopup}
      />
    ) : (
      <></>
    );

  return (
    <Page className="page" hideScrollbar={pageHideScrollnbar}>
      <div className="m-t-15">
        <TichLuyHomeBlocks />
      </div>
      {viewModalHomePopup}
      {viewModalCategoriesForBuyerCollect}
    </Page>
  );
};

export default TichLuyHomePage;
