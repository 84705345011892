import React, { useEffect, useState } from "react";
import { Tabs } from "zmp-ui";
import { cache_listSlotStatus } from "../../../cache-manager";
import { slotStatus } from "../../../models";
import DataLoading from "../../share/data-loading";
import DealsJoinedListItem from "./deals-joined-list-item";

const DealsJoinedTabs: React.FunctionComponent = () => {
  const tabsScrollable: boolean = true;
  const listSlotStatus = cache_listSlotStatus();

  return listSlotStatus ? (
    <Tabs defaultActiveKey="" id="contact-list" scrollable={tabsScrollable}>
      {listSlotStatus.map((slotStatus: slotStatus) => (
        <Tabs.Tab key={slotStatus.value} label={slotStatus.name}>
          <DealsJoinedListItem
            slotStatus={slotStatus.value}
          ></DealsJoinedListItem>
        </Tabs.Tab>
      ))}
    </Tabs>
  ) : (
    <DataLoading></DataLoading>
  );
};

export default DealsJoinedTabs;
