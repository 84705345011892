import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Icon, useNavigate } from "zmp-ui";
import {
  buildUrl_NghienReview_Internal_PageDealDetail,
  getPageHomeLastViewProductId,
  setPageHomeLastViewProductId,
} from "../../../helper";
import { dealItem } from "../../../models";
import { formatCurrencyVND } from "../../../utility";
import Badge from "../../share/badge";
import LazyImage from "../../share/lazy-image";

interface DealItemProps {
  item: dealItem;
}

const HomeDealItem: React.FunctionComponent<DealItemProps> = ({ item }) => {
  const myElementRef = useRef<null | HTMLDivElement>(null);

  //console.log("load component HomeDealItem");

  const navigate = useNavigate();
  const redirectToDealDetail = (dealId: string) => {
    setPageHomeLastViewProductId(dealId);

    const urlPageDealDetail =
      buildUrl_NghienReview_Internal_PageDealDetail(dealId);
    console.log(urlPageDealDetail);
    navigate(urlPageDealDetail, { animate: true });
  };

  useEffect(() => {
    setTimeout(() => {
      const pageHomeLastViewProductId = getPageHomeLastViewProductId();

      if (myElementRef.current && item.id_e === pageHomeLastViewProductId) {
        console.log("scrollIntoView " + pageHomeLastViewProductId);
        myElementRef.current.scrollIntoView({
          block: "center",
        });
      }
    }, 0);
  }, [item]);

  const htmlIsDeposit = item.is_deposit ? (
    <div className="tag-deposit">
      <Badge
        text="Đã ký quỹ"
        type="success"
        classNameExtends="p-05em"
        icon={
          <Icon icon={"zi-check-circle-solid"} style={{ fontSize: "13px" }} />
        }
      />
    </div>
  ) : (
    <></>
  );

  return (
    <div
      ref={myElementRef}
      key={item.id}
      className="product-box"
      onClick={() => redirectToDealDetail(item.id_e)}
    >
      <div className="img-wrapper">
        <div className="front position-relative">
          {htmlIsDeposit}
          <LazyImage className="w-full" src={item.image_url} alt="" />
        </div>
      </div>
      <div className="product-detail">
        <div className="rating"></div>
        <h6 className="text-deal">{item.name}</h6>
        <span className="text-danger f-w-b">
          {formatCurrencyVND(item.actual_price)}
        </span>
        <br />
        <span className="text-danger">hoàn tiền {item.cashback_rate}%</span>
      </div>
    </div>
  );
};

export default HomeDealItem;
