import React, { useState } from "react";
import { Icon, useSnackbar } from "zmp-ui";
import { TLUserModel, userInfo } from "../../../models";
import { mappingAccountTichLuyService } from "../../../services/tl-user-service";

interface TichLuyFormMappingTokenProps {
  userFromCache: userInfo;
  isLoadingState: boolean;
  setIsLoadingState: React.Dispatch<React.SetStateAction<boolean>>;
  setVisibleFormMappingTokenState: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  processAfterAccountVeirySuccess(tlUser: TLUserModel): Promise<void>;
}

interface FormState {
  mappingCode: string;
}

const TichLuyFormMappingToken: React.FunctionComponent<
  TichLuyFormMappingTokenProps
> = ({
  userFromCache,
  isLoadingState,
  setIsLoadingState,
  setVisibleFormMappingTokenState,
  processAfterAccountVeirySuccess,
}) => {
  const { openSnackbar } = useSnackbar();
  const [visibleImageGuideToGetCode, setVisibleImageGuideToGetCode] =
    useState(false);
  const [formState, setFormState] = useState<FormState>({ mappingCode: "" });

  const toggleVisibleImageGuideToGetCode = () => {
    setVisibleImageGuideToGetCode(!visibleImageGuideToGetCode);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = event.target;
    let value: any = event.target.value;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onClickBtnSubmit = async () => {
    if (isLoadingState) return;

    try {
      setIsLoadingState(true);

      // validate
      if (!formState.mappingCode) {
        openSnackbar({
          text: "Vui lòng nhập [Mã liên kết]",
          type: "error",
        });
        return;
      }

      // gọi lên API để xử lý
      const resultMappingAccount = await mappingAccountTichLuyService(
        "", // TODO hiện tại bên đây ko cần dùng form này nên để tạm là empty
        formState.mappingCode
      );

      // có lỗi
      if (resultMappingAccount.error_from_api) {
        openSnackbar({
          text: resultMappingAccount.error_from_api.error_msg,
          type: "error",
        });
        return;
      }

      // xử lý thành công
      // set thông tin user Tích Lũy vào cache
      await processAfterAccountVeirySuccess(resultMappingAccount);
      // thông báo thành công
      openSnackbar({
        text: "Liên kết tài khoản Tích Lũy thành công",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Đã có lỗi xảy ra",
        type: "error",
      });
    } finally {
      setIsLoadingState(false);
    }
  };

  const viewImageGuideToGetCode = visibleImageGuideToGetCode ? (
    <div>
      <img
        className="w-full"
        src="https://r3.tichluy.vn/Content/app/huong-dan-lay-ma-lien-ket-tich-luy.gif"
      />
    </div>
  ) : (
    <></>
  );

  return (
    <>
      <div className="modal-title">Liên kết tài khoản Tích Lũy</div>
      <div>
        Nhập mã liên kết từ ứng dụng Tích Lũy để đồng bộ đơn hàng và số tiền
        hoàn lại.
      </div>
      <div className="j-c-center m-t-25">
        <div className="form-group">
          <label className="font-weight-bold">Mã liên kết</label>
          <input
            onChange={handleInputChange}
            name="mappingCode"
            value={formState.mappingCode}
            type="text"
            className="form-control form-control-large"
          />
        </div>
        <div className="text-center m-t-15">
          <button
            onClick={() => {
              setVisibleFormMappingTokenState(false);
            }}
            className="btn btn-disabled btn-border btn-small color-black"
          >
            <Icon icon={"zi-chevron-left"} className="font-size-18" />
            Quay lại
          </button>
          <button
            onClick={onClickBtnSubmit}
            className="btn btn-solid btn-border btn-small m-l-15"
          >
            <Icon icon={"zi-check"} className="font-size-18" /> Xác nhận
          </button>
        </div>
        <div className="m-t-25 text-center">
          <label className="text-success">Hướng dẫn lấy mã liên kết</label>
          <label>
            Vào ứng dụng Tích Lũy {"->"} Tài khoản {"->"} Mã liên kết
          </label>
        </div>
      </div>
    </>
  );
};

export default TichLuyFormMappingToken;
