import { getAllMerchantTichLuyClient, getMerchantDetailTichLuyClient } from "../connect/tich-luy-client";
import { TLMerchantModel } from "../models";

export const getTLAllMerchantService = async (
): Promise<TLMerchantModel[]> => {
  return getAllMerchantTichLuyClient();
};

export const getTLMerchantDetailService = async (
  merchantId: number
): Promise<TLMerchantModel> => {
  return getMerchantDetailTichLuyClient(merchantId);
};
