import React, { useState } from "react";
import { Modal, Spinner } from "zmp-ui";

interface imageWithViewDetailProps {
  src: string;
  className?: string;
}

const ImageWithViewDetail: React.FunctionComponent<
  imageWithViewDetailProps
> = ({ src, className }) => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      <img
        onClick={() => {
          setModalVisible(true);
        }}
        className={className}
        src={src}
      />
      <Modal
        className="hide-modal-main"
        visible={modalVisible}
        coverSrc={src}
        onClose={() => {
          setModalVisible(false);
        }}
        actions={[
          {
            text: "Đóng",
            close: true,
            highLight: true,
          },
        ]}
      />
    </>
  );
};

export default ImageWithViewDetail;
