import React, { useEffect, useState } from "react";
//import { onConfirmToExit, offConfirmToExit, closeApp } from "zmp-sdk/apis";
import { App, Modal } from "zmp-ui";

const ModalConfirmCloseApp: React.FunctionComponent = () => {
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  useEffect(() => {
    // onConfirmToExit(() => setConfirmModalVisible(true));
    // return () => offConfirmToExit();
  }, []);

  return (
    <App>
      <Modal
        visible={confirmModalVisible}
        title="Tích Lũy"
        description="Bạn có chắc muốn thoát khỏi Mini App?"
        actions={[
          {
            text: "Rời khỏi",
            onClick: () => {
              //closeApp();
            },
          },
          {
            text: "Ở lại Mini App",
            onClick: () => {
              setConfirmModalVisible(false);
            },
            highLight: true,
          },
        ]}
      />
    </App>
  );
};

export default ModalConfirmCloseApp;
