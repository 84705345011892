import {
  RecoilState,
  SetterOrUpdater,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from "recoil";
import {
  cacheBase,
  cacheProcessingModel,
  cache_categoryLv1,
  cache_homeDeals,
  cache_NRBanksModel,
  cache_SlotStatus,
  cache_TLAllCategory,
  cache_TLAllMerchant,
  cache_TLHomeBlocks,
  cache_userInfo,
  dealSearchPageModel,
  userInfo,
  cache_TLDonationCampaigns,
} from "./models";
import { getNRBanksService } from "./services/bank-service";
import { getCategoryLv1s } from "./services/category-service";
import { getDealsForPageHome, getDealsJoined } from "./services/deal-service";
import { getListSlotStatus } from "./services/slot-service";
import { getTLAllCategoryService } from "./services/tl-category-service";
import { getTLHomeBlocksService } from "./services/tl-home-block-serivce";
import { getTLAllMerchantService } from "./services/tl-merchant-service";
import { getUserFromZalo, getUserInfoFromAPI } from "./services/user-service";
import {
  cache_CategoryLv1sState,
  cache_HomeDealsState,
  cache_ListSlotStatusState,
  cache_NRBanksState,
  cache_NRPageSearchDealResultState,
  cache_TLAllCategoryState,
  cache_TLAllMerchantState,
  cache_TLDonationCampaignsState,
  cache_TLHomeBlocksState,
  cache_UserState,
} from "./state";
import { addMinutes, addSeconds } from "./utility";
import { getTLDonationCampaignsService } from "./services/tl-donation-campaign-service";

const getCacheProcessManage = () => {
  let cacheProcessing = window.CACHE_PROCESS_MANAGE as
    | cacheProcessingModel[]
    | undefined;

  if (!cacheProcessing) {
    window.CACHE_PROCESS_MANAGE = [];
    cacheProcessing = [];
  }

  return cacheProcessing;
};

const setStatusCacheIsProcessing = (key: string, isProcessing: boolean) => {
  let listCacheProcessManage = getCacheProcessManage();
  const cacheIndex = listCacheProcessManage.findIndex((obj) => obj.key === key);

  // nếu đã tồn tại cache trong manage list thì update trạng thái
  if (cacheIndex > -1) {
    listCacheProcessManage = listCacheProcessManage.map((obj) => {
      if (obj.key === key) {
        return { ...obj, isProcessing: isProcessing };
      }

      return obj;
    });
  }
  // nếu chưa có thì thêm vào danh sách quản lý
  else {
    listCacheProcessManage.push({ key: key, isProcessing: isProcessing });
  }
  window.CACHE_PROCESS_MANAGE = listCacheProcessManage;
  console.log(listCacheProcessManage.find((obj) => obj.key === key));
};

const IsCacheProcessing = (key: string) => {
  const cacheProcessing = getCacheProcessManage();

  return (
    cacheProcessing.findIndex(
      (obj) => obj.key === key && obj.isProcessing === true
    ) > -1
  );
};

interface authTokenForFetchDataToCacheModel {
  nghienReviewApiAuthToken: string | undefined;
  tichLuyApiAuthToken: string | undefined;
}

const cacheProcessBase: <T extends cacheBase | null, U>(
  key: string,
  cacheState: RecoilState<T | null>,
  fnGetDataFromCache: (cacheData: T) => U, // fn lấy data trong cache
  fnFetchDataToCache: (
    authToken: authTokenForFetchDataToCacheModel
  ) => Promise<U>, // fn lấy data để nạp vào cache
  fnCreateNewCache: (cacheBase: cacheBase, data: U) => T, // fn tạo object cache mới để nạp vào state
  cacheMinutes?: number // default = 10
) => U | null = (
  key,
  cacheState,
  fnGetDataFromCache,
  fnFetchDataToCache,
  fnCreateNewCache,
  cacheMinutes
) => {
  const defaultCacheMinutes = 10;
  const finalCacheMinutes: number = cacheMinutes || defaultCacheMinutes;
  const [cacheData, setCacheData] = useRecoilState(cacheState);
  const cacheUserState = useRecoilValue(cache_UserState);
  const nghienReviewApiAuthToken =
    cacheUserState?.user.nghien_review_user.api_auth_token;
  const tichLuyApiAuthToken = cacheUserState?.user.tich_luy_user?.token;

  // nếu đã có cache và cache còn hiệu lực
  if (
    cacheData &&
    cacheData.time_last_cache &&
    cacheData.minute_cache &&
    addMinutes(cacheData.time_last_cache, cacheData.minute_cache) >= new Date()
  ) {
    // trả ra danh sách cache
    return fnGetDataFromCache(cacheData);
  }

  // xử lý cho trường hợp
  // 1 component rerender gọi chung 1 cache nhiều lần mà chưa lấy data kịp
  // làm cho gọi API nhiều lần

  // nếu cache đang chạy lấy dữ liệu thì return null;
  if (IsCacheProcessing(key)) {
    console.log(`cache [${key}] dang xu ly nen skip`);
    return null;
  }

  // gắn trạng thái đang xử lý lấy dữ liệu cho cache
  setStatusCacheIsProcessing(key, true);

  // chưa có cache hoặc cache đã hết hiệu lực thì lấy lại thông tin
  const authTokenForFetchDataToCache: authTokenForFetchDataToCacheModel = {
    nghienReviewApiAuthToken: nghienReviewApiAuthToken,
    tichLuyApiAuthToken: tichLuyApiAuthToken,
  };
  fnFetchDataToCache(authTokenForFetchDataToCache).then((data) => {
    if (data === null || data === undefined) {
      // nếu data lỗi thì cho lần sau get lại
      return;
    }

    const cacheBase: cacheBase = {
      time_last_cache: new Date(),
      minute_cache: finalCacheMinutes,
    };
    const newCache = fnCreateNewCache(cacheBase, data);
    console.log(newCache);
    // lưu vào cache state
    setCacheData(newCache);
    // update trạng thái cache đã xử lý xong
    setStatusCacheIsProcessing(key, false);
  });

  // trong lúc chờ lấy dữ liệu thì return ra null để client hiển thị
  // đag lấy thông tin và khi đã lấy đc dữ liệu thì state sẽ tự update và
  // refresh lại giao diện với thông tin mới
  return null;
};

export const cache_dealsForPageHome = (categoryLv1Name: string) => {
  const state = cache_HomeDealsState(categoryLv1Name);

  return cacheProcessBase(
    `cache_dealsForPageHome_${categoryLv1Name}`,
    state,
    (cacheData: cache_homeDeals) => {
      return cacheData.deals;
    },
    ({ nghienReviewApiAuthToken }) => {
      return getDealsForPageHome(nghienReviewApiAuthToken, categoryLv1Name);
    },
    (cacheBase, data) => {
      const cacheNew: cache_homeDeals = { ...cacheBase, deals: data };
      return cacheNew;
    }
  );
};

export const cache_categoryLv1s = () => {
  return cacheProcessBase(
    "cache_categoryLv1s",
    cache_CategoryLv1sState,
    (cacheData: cache_categoryLv1) => {
      return cacheData.categoryLv1s;
    },
    () => {
      return getCategoryLv1s();
    },
    (cacheBase, data) => {
      const cacheNew: cache_categoryLv1 = { ...cacheBase, categoryLv1s: data };
      return cacheNew;
    }
  );
};

export const cache_NRBanks = () => {
  return cacheProcessBase(
    "cache_NRBanks",
    cache_NRBanksState,
    (cacheData: cache_NRBanksModel) => {
      return cacheData.banks;
    },
    () => {
      return getNRBanksService();
    },
    (cacheBase, data) => {
      const cacheNew: cache_NRBanksModel = { ...cacheBase, banks: data };
      return cacheNew;
    }
  );
};

export const cache_listSlotStatus = () => {
  const state = cache_ListSlotStatusState;

  return cacheProcessBase(
    "cache_listSlotStatus",
    state,
    (cacheData: cache_SlotStatus) => {
      return cacheData.listSlotStatus;
    },
    ({ nghienReviewApiAuthToken }) => {
      return getListSlotStatus(nghienReviewApiAuthToken);
    },
    (cacheBase, data) => {
      const cacheNew: cache_SlotStatus = { ...cacheBase, listSlotStatus: data };
      return cacheNew;
    },
    24 * 60 // vì enum ít thay đổi nên cache lâu 24h
  );
};

export const set_cache_user_info = (
  cacheUserState: cache_userInfo | null,
  setCacheUserState: SetterOrUpdater<cache_userInfo | null>,
  newUser: userInfo
) => {
  // nếu chưa có cache user thì chưa set tránh lỗi
  if (!cacheUserState) return;

  setCacheUserState({ ...cacheUserState, user: newUser });
};

export const cache_user = (
  callbackFuncAfterFetchUserSuccess?: (user: userInfo) => void
) => {
  const state = cache_UserState;

  return cacheProcessBase(
    "cache_user",
    state,
    (cacheData: cache_userInfo) => {
      return cacheData.user;
    },
    () => {
      return getUserInfoFromAPI();
    },
    (cacheBase, data) => {
      const userInfo = data as userInfo;
      const cacheNew: cache_userInfo = { ...cacheBase, user: userInfo };

      if (typeof callbackFuncAfterFetchUserSuccess === "function") {
        callbackFuncAfterFetchUserSuccess(userInfo);
      }

      return cacheNew;
    },
    24 * 60 // vì user ko thay đổi nên cache lâu 24h
  );
};

export const cache_tlListHomeBlock = () => {
  const state = cache_TLHomeBlocksState;

  return cacheProcessBase(
    "cache_tlListHomeBlock",
    state,
    (cacheData: cache_TLHomeBlocks) => {
      return cacheData.listHomeBlock;
    },
    ({ tichLuyApiAuthToken }) => {
      return getTLHomeBlocksService(tichLuyApiAuthToken);
    },
    (cacheBase, data) => {
      const cacheNew: cache_TLHomeBlocks = {
        ...cacheBase,
        listHomeBlock: data,
      };
      return cacheNew;
    },
    24 * 60 // vì enum ít thay đổi nên cache lâu 24h
  );
};

export const cache_tlAllMerchant = () => {
  const state = cache_TLAllMerchantState;

  return cacheProcessBase(
    "cache_tlAllMerchant",
    state,
    (cacheData: cache_TLAllMerchant) => {
      return cacheData.allMerchant;
    },
    (apiAuthToken) => {
      return getTLAllMerchantService();
    },
    (cacheBase, data) => {
      const cacheNew: cache_TLAllMerchant = {
        ...cacheBase,
        allMerchant: data,
      };
      return cacheNew;
    },
    24 * 60 // vì enum ít thay đổi nên cache lâu 24h
  );
};

export const cache_tlAllCategory = () => {
  const state = cache_TLAllCategoryState;

  return cacheProcessBase(
    "cache_tlAllCategory",
    state,
    (cacheData: cache_TLAllCategory) => {
      return cacheData.allCategory;
    },
    (apiAuthToken) => {
      return getTLAllCategoryService();
    },
    (cacheBase, data) => {
      const cacheNew: cache_TLAllCategory = {
        ...cacheBase,
        allCategory: data,
      };
      return cacheNew;
    },
    24 * 60 // vì enum ít thay đổi nên cache lâu 24h
  );
};

export const cache_tlDonationCampaigns = () => {
  const state = cache_TLDonationCampaignsState;

  return cacheProcessBase(
    "cache_TLDonationCampaignsState",
    state,
    (cacheData: cache_TLDonationCampaigns) => {
      return cacheData.donationCampaigns;
    },
    (apiAuthToken) => {
      return getTLDonationCampaignsService(
        1,
        18,
        apiAuthToken.tichLuyApiAuthToken
      );
    },
    (cacheBase, data) => {
      const cacheNew: cache_TLDonationCampaigns = {
        ...cacheBase,
        donationCampaigns: data,
      };
      return cacheNew;
    },
    24 * 60 // vì enum ít thay đổi nên cache lâu 24h
  );
};