import React, { useEffect, useState } from "react";
import { Page } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { dealItem, dealJoined } from "../../../models";
import { getDealsJoined } from "../../../services/deal-service";
import DataLoading from "../../share/data-loading";
import DealsJoinedItem from "../deals_joined/deals-joined-item";

interface DealsJoinedListItemProps {
  slotStatus: string;
}

interface DealsJoinedListItemState {
  listDealJoined: dealJoined[] | null;
  currentPageIndex: number;
  showLoadMoreButton: boolean;
}

const DealsJoinedListItem: React.FunctionComponent<
  DealsJoinedListItemProps
> = ({ slotStatus }) => {
  const [getComponentState, setComponentState] =
    useState<DealsJoinedListItemState>({
      listDealJoined: null,
      currentPageIndex: 1,
      showLoadMoreButton: true,
    });

  const { listDealJoined, currentPageIndex, showLoadMoreButton } =
    getComponentState;
  const apiAuthToken = cache_user()?.nghien_review_user.api_auth_token;

  useEffect(() => {
    const fetchData = async () => {
      const listItem = await getDealsJoined(
        slotStatus,
        apiAuthToken,
        currentPageIndex
      );

      const listDealJoinedNew = [
        ...(getComponentState.listDealJoined ?? []),
        ...listItem,
      ];
      setComponentState({
        ...getComponentState,
        listDealJoined: listDealJoinedNew,
        // hiển thị lại nút load more để load tiếp trang sau
        showLoadMoreButton: listItem && listItem.length > 0,
      });

      // nếu còn dữ liệu thì xử lý còn lại thì ko thay đổi state
      // tránh render lại ngoài ý muốn
      if (listItem && listItem.length > 0) {
      }
    };

    fetchData();
  }, [slotStatus, currentPageIndex]);

  const onClickBtnLoadMore = (event: React.MouseEvent<HTMLElement>) => {
    setComponentState({
      ...getComponentState,
      currentPageIndex: currentPageIndex + 1,
      showLoadMoreButton: false,
    });
  };

  return listDealJoined ? (
    <Page>
      {listDealJoined.length > 0 ? (
        <div className="m-t-15">
          {listDealJoined.map((item: dealJoined) => (
            <DealsJoinedItem
              key={item.deal_slot.id}
              item={item}
            ></DealsJoinedItem>
          ))}
          <div className="text-center">
            <a
              style={{ display: showLoadMoreButton ? "" : "none" }}
              onClick={onClickBtnLoadMore}
              className="btn btn-solid"
            >
              Xem thêm
            </a>
          </div>
        </div>
      ) : (
        <div className="m-a text-center text-danger">
          <p>Đã hết dữ liệu</p>
        </div>
      )}
    </Page>
  ) : (
    <DataLoading></DataLoading>
  );
};

export default DealsJoinedListItem;
