import React from "react";

interface PageTitleProps {
  title: string;
}

const PageTitle: React.FunctionComponent<PageTitleProps> = ({ title }) => {
  return (
    <div className="title text-center">
      <h2 className="text-uppercase">
        {title}
        <hr role="tournament6" />
      </h2>
    </div>
  );
};

export default PageTitle;
