import { string } from "prop-types";
import {
  SECRET_KEY_SIGN_REQUEST_TICH_LUY_AUTH,
  TL_API_AUTH_VALID_ETOKEN,
  TL_API_AUTH_ZALO_ACCOUNT_MAPPING,
  TL_API_AUTH_ZALO_SIGNUP,
  TL_API_GET_ACCOUNT_INFO,
  TL_API_GET_ALL_CATEGORY,
  TL_API_GET_ALL_MERCHANT,
  TL_API_GET_APP_SETTINGS,
  TL_API_GET_DONATION_CAMPAIGNS,
  TL_API_GET_DONATION_CAMPAIGN_CUSTOMERS,
  TL_API_GET_DONATION_CAMPAIGN_DETAIL,
  TL_API_GET_HOME_BLOCKS,
  TL_API_GET_HOME_POPUP,
  TL_API_GET_MERCHANT_DETAIL,
  TL_API_GET_ORDERS,
  TL_API_INTERNAL_TRACKING_SECRET_KEY,
  TL_API_POST_DONATION_CUSTOMER_CREATE,
  TL_API_SEND_INTERNAL_TRACKING,
  TL_HOME_BLOCK_DISPLAY_SLOT,
} from "../constants";
import { signRequestSimple } from "../helper";
import {
  baseErrorFromAPIModel,
  errorfromAPIModel,
  ReponseFromAPIModel,
  TLCategoryModel,
  TLHomeBlockModel,
  TLMerchantModel,
  tlOrderModel,
  tlAppSettingsModel,
  TLUserInfoModel,
  TLUserModel,
  tlSendInternalTrackingReponseAPIModel,
  tlSendInternalTrackingRequestAPIModel,
  TLHomePopupModel,
  tlDonationCampaignModel,
  tlCustomerDonationModel,
  tlPostDonationCustomerCreateResponseAPIModel,
  tlPostDonationCustomerCreateRequestAPIModel,
} from "../models";
import { tlGetDonationCampaignCustomersTypeEnum } from "../enums";

const deviceNameForZaloMiniApp = "zalo-mini-app";

export const getDonationCampaignCustomersTichLuyClient = async (
  campaignId: number,
  type: tlGetDonationCampaignCustomersTypeEnum,
  pageIndex: number,
  pageSize: number
): Promise<tlCustomerDonationModel[]> => {
  const params = new URLSearchParams({
    campaignId: campaignId.toString(),
    type: type.toString(),
    pageIndex: pageIndex.toString(),
    pageSize: pageSize.toString(),
  }).toString();

  const url = `${TL_API_GET_DONATION_CAMPAIGN_CUSTOMERS}?${params}`;

  const response = await fetchForTL(url, "1.0", undefined, "GET");
  const customers = await getDataAfterFetch(response);
  return customers;
};

export const getDonationCampaignDetailTichLuyClient = async (
  campaignAlias: string,
  tlUserToken?: string | null
): Promise<tlDonationCampaignModel> => {
  const params = new URLSearchParams({
    alias: campaignAlias,
  }).toString();

  const url = `${TL_API_GET_DONATION_CAMPAIGN_DETAIL}?${params}`;

  const response = await fetchForTL(
    url,
    "1.0",
    tlUserToken ?? undefined,
    "GET"
  );
  const detail = await getDataAfterFetch(response);
  return detail;
};

export const getDonationCampaignsTichLuyClient = async (
  pageIndex: number,
  pageSize: number,
  tlUserToken?: string | null
): Promise<tlDonationCampaignModel[]> => {
  const params = new URLSearchParams({
    pageIndex: pageIndex.toString(),
    pageSize: pageSize.toString(),
  }).toString();

  const url = `${TL_API_GET_DONATION_CAMPAIGNS}?${params}`;

  const response = await fetchForTL(
    url,
    "1.0",
    tlUserToken ?? undefined,
    "GET"
  );
  const campaigns = await getDataAfterFetch(response);
  return campaigns;
};

export const postDonationCustomerCreateTichLuyClient = async (
  tlAuthToken: string,
  requestModel: tlPostDonationCustomerCreateRequestAPIModel
): Promise<tlPostDonationCustomerCreateResponseAPIModel> => {
  const url = `${TL_API_POST_DONATION_CUSTOMER_CREATE}`;

  const response = await fetchForTL(
    url,
    "1.0",
    tlAuthToken,
    "POST",
    requestModel
  );
  const data = await getDataAfterFetch(response);
  return data;
};

export const sendInternalTrackingTichLuyClient = async (
  tlAuthToken: string | undefined,
  requestModel: tlSendInternalTrackingRequestAPIModel
): Promise<tlSendInternalTrackingReponseAPIModel> => {
  const url = `${TL_API_SEND_INTERNAL_TRACKING}`;
  const headers = {
    SecretKey: TL_API_INTERNAL_TRACKING_SECRET_KEY,
  };

  const response = await fetchForTL(
    url,
    "1.0",
    tlAuthToken,
    "POST",
    requestModel,
    headers
  );
  const data = await getDataAfterFetch(response);
  return data;
};

export const getSettingsTichLuyClient =
  async (): Promise<tlAppSettingsModel> => {
    const url = `${TL_API_GET_APP_SETTINGS}`;

    const response = await fetchForTL(url, "1.0", undefined, "GET");
    const settings = await getDataAfterFetch(response);
    return settings;
  };

export const getOrdersTichLuyClient = async (
  pageIndex: number,
  pageSize: number,
  tlUserToken: string
): Promise<tlOrderModel[]> => {
  const params = new URLSearchParams({
    pageIndex: pageIndex.toString(),
    pageSize: pageSize.toString(),
  }).toString();

  const url = `${TL_API_GET_ORDERS}?${params}`;

  const response = await fetchForTL(url, "1.0", tlUserToken, "GET");
  const orders = await getDataAfterFetch(response);
  return orders;
};

export const getAccountInfoTichLuyClient = async (
  tlUserToken: string
): Promise<TLUserInfoModel> => {
  const url = `${TL_API_GET_ACCOUNT_INFO}`;

  const response = await fetchForTL(url, "2.0", tlUserToken, "GET");
  const userInfo = await getDataAfterFetch(response);
  return userInfo;
};

export const mappingAccountTichLuyClient = async (
  zaloUserId: string,
  mappingCode: string
): Promise<TLUserModel> => {
  const currentTimestamp = Date.now();
  const signParams: Record<string, string> = {
    accesstoken: zaloUserId,
    timestamp: currentTimestamp.toString(),
  };
  const params = new URLSearchParams({
    sign:
      signRequestSimple(signParams, SECRET_KEY_SIGN_REQUEST_TICH_LUY_AUTH) ??
      "",
  }).toString();
  const url = `${TL_API_AUTH_ZALO_ACCOUNT_MAPPING}?${params}`;

  const dataBody = {
    TimeStamp: currentTimestamp.toString(),
    AccessToken: zaloUserId,
    DeviceName: deviceNameForZaloMiniApp,
    MappingCode: mappingCode,
    SourceType: "ZaloMiniApp", // enum 0
  };

  const response = await fetchForTL(url, "3.0", undefined, "POST", dataBody);
  const user = await getDataAfterFetch(response);
  return user;
};

export const signinTichLuyClient = async (
  tlEToken: string
): Promise<TLUserModel> => {
  return signinTichLuyWithEtokenClient(tlEToken);
};

export const signinTichLuyWithEtokenClient = async (
  tlEToken: string
): Promise<TLUserModel> => {
  const params = new URLSearchParams({
    etoken: tlEToken,
  });
  const url = `${TL_API_AUTH_VALID_ETOKEN}?${params}`;

  const response = await fetchForTL(url, "3.0", undefined, "GET");
  const user = await getDataAfterFetch(response);
  return user;
};

export const signupTichLuyClient = async (
  zaloUserId: string,
  zaloFullName: string,
  referralCode?: string | undefined,
  isAutoSignup?: boolean
): Promise<TLUserModel> => {
  const currentTimestamp = Date.now();
  const signParams: Record<string, string> = {
    accesstoken: zaloUserId,
    timestamp: currentTimestamp.toString(),
  };
  const params = new URLSearchParams({
    sign:
      signRequestSimple(signParams, SECRET_KEY_SIGN_REQUEST_TICH_LUY_AUTH) ??
      "",
  }).toString();
  const url = `${TL_API_AUTH_ZALO_SIGNUP}?${params}`;

  const dataBody = {
    TimeStamp: currentTimestamp.toString(),
    AccessToken: zaloUserId,
    DeviceName: deviceNameForZaloMiniApp,
    AutoRelogin: true,
    FullName: zaloFullName,
    ReferralCode: referralCode,
    IsAutoSignup: isAutoSignup ?? true,
  };

  const response = await fetchForTL(url, "3.0", undefined, "POST", dataBody);
  const user = await getDataAfterFetch(response);
  return user;
};

export const getHomePopupTichLuyClient = async (
  apiAuthToken: string | undefined
): Promise<TLHomePopupModel | null | undefined> => {
  const params = new URLSearchParams({
    displaySlot: TL_HOME_BLOCK_DISPLAY_SLOT,
  }).toString();

  const url = `${TL_API_GET_HOME_POPUP}?${params}`;
  const response = await fetchForTL(url, "1.0", apiAuthToken);
  const homePopup = await getDataAfterFetch(response);
  return homePopup;
};

export const getAllCategoryTichLuyClient = async (): Promise<
  TLCategoryModel[]
> => {
  const url = `${TL_API_GET_ALL_CATEGORY}`;
  const response = await fetchForTL(url, "4.0", undefined);
  const allCategory = await getDataAfterFetch(response);
  return allCategory;
};

export const getAllMerchantTichLuyClient = async (): Promise<
  TLMerchantModel[]
> => {
  const url = `${TL_API_GET_ALL_MERCHANT}`;
  const response = await fetchForTL(url, "4.0", undefined);
  const allMerchant = await getDataAfterFetch(response);
  return allMerchant;
};

export const getMerchantDetailTichLuyClient = async (
  merchantId: number
): Promise<TLMerchantModel> => {
  const params = new URLSearchParams({
    merchantId: merchantId.toString(),
  }).toString();
  const url = `${TL_API_GET_MERCHANT_DETAIL}?${params}`;
  const response = await fetchForTL(url, "4.0", undefined);
  const merchantDetail = await getDataAfterFetch(response);
  return merchantDetail;
};

export const getHomeBlocksTichLuyClient = async (
  apiAuthToken: string | undefined
): Promise<TLHomeBlockModel[]> => {
  const params = new URLSearchParams({
    displaySlot: TL_HOME_BLOCK_DISPLAY_SLOT,
  }).toString();
  const url = `${TL_API_GET_HOME_BLOCKS}?${params}`;
  const response = await fetchForTL(url, "1.0", apiAuthToken);
  const homeBlocks = await getDataAfterFetch(response);
  return homeBlocks;
};

const fetchForTL = (
  url: string,
  apiVersion: "1.0" | "2.0" | "3.0" | "4.0" | "5.0",
  apiAuthToken: string | undefined,
  method: "GET" | "POST" = "GET",
  dataBody?: object,
  headers?: Record<string, string>
): Promise<Response> => {
  const requestHeaders: HeadersInit = new Headers();
  requestHeaders.set("Authorization", `Bearer ${apiAuthToken ?? ""}`);
  requestHeaders.set("api-version", apiVersion);
  requestHeaders.set("Content-Type", "application/json" ?? "");

  if (headers) {
    Object.keys(headers).forEach((key) => {
      requestHeaders.set(key, headers[key]);
    });
  }

  return fetch(url, {
    method: method,
    headers: requestHeaders,
    body: dataBody ? JSON.stringify(dataBody) : undefined,
  });
};

const getDataAfterFetch: any = async (response: Response) => {
  const resData = await response.json();

  // nếu có lỗi
  if (resData.Message && !resData.data) {
    console.log(resData);

    const error: errorfromAPIModel = {
      error_code: 1,
      error_msg: resData.Message,
    };
    const baseErorr: baseErrorFromAPIModel = {
      error_from_api: error,
    };
    return baseErorr;
  }

  interface resfromTichLuyAPIModel {
    status: number;
    code: string;
    errorMessage: string;
    data: any;
  }

  const resDataModel = resData as resfromTichLuyAPIModel;
  // nếu lỗi
  if (resDataModel.status === 1 || resDataModel.errorMessage) {
    console.log(resData);

    const error: errorfromAPIModel = {
      error_code: parseInt(resDataModel.code),
      error_msg: resDataModel.errorMessage,
    };
    const baseErorr: baseErrorFromAPIModel = {
      error_from_api: error,
    };
    return resDataModel.data
      ? { ...resDataModel.data, ...baseErorr }
      : baseErorr;
  }

  // thành công thì trả ra data
  return resDataModel.data;
};
