import React, { lazy, Suspense, useState, useEffect, useCallback } from "react";
import { Icon, Page, useSnackbar } from "zmp-ui";
import TichLuyDonationListItem from "../../components/tich_luy/donation/donation-list-item";
import TichLuyDonationCustomerTop from "../../components/tich_luy/donation/donation-customers";
import { cache_tlDonationCampaigns, cache_user } from "../../cache-manager";
import DataLoading from "../../components/share/data-loading";
import { useParams } from "react-router-dom";
import NotFoundPage from "../share/not-found";
import {
  TLUserModel,
  tlCustomerDonationModel,
  tlDonationCampaignModel,
} from "../../models";
import { getTLDonationCampaignDetailService } from "../../services/tl-donation-campaign-service";
import {
  calculateTimeDifference,
  copyTextToClipboard,
  formatCurrencyVND,
  formatDate,
  getDateFromString,
} from "../../utility";
import TichLuyDonationCustomers from "../../components/tich_luy/donation/donation-customers";
import {
  tlActionTypeEnum,
  tlGetDonationCampaignCustomersTypeEnum,
} from "../../enums";
import TichLuyDonationDetailOnGoingCampaign from "../../components/tich_luy/donation/donation-detail-on-going-campaign";
import TichLuyDonationDetailOthersCampaign from "../../components/tich_luy/donation/donation-detail-others-campaign";
import ProcessActionTypeTichLuy from "../../components/tich_luy/share/process-action-type";
import Badge from "../../components/share/badge";

const TichLuyModalDonationTopup = lazy(
  () => import("../../components/tich_luy/donation/modal-donation-topup")
);

interface DonationDetailStateModel {
  campaignDetail?: tlDonationCampaignModel;
  listCustomerTopDonation?: tlCustomerDonationModel;
  listCustomerNewDonation?: tlCustomerDonationModel;
  visibleModalDonationTopup: boolean;
}

const TichLuyDonationDetail: React.FunctionComponent = () => {
  let params = useParams();
  const campaignAlias = params.campaignAlias;
  const user = cache_user();
  const apiAuthToken = user?.tich_luy_user?.token;
  const { openSnackbar } = useSnackbar();

  const [donationDetailState, setdonationDetailState] =
    useState<DonationDetailStateModel>({
      visibleModalDonationTopup: false,
      campaignDetail: undefined,
      listCustomerNewDonation: undefined,
      listCustomerTopDonation: undefined,
    });
  const { campaignDetail, visibleModalDonationTopup } = donationDetailState;

  const setCampaignDetailState = (value: tlDonationCampaignModel) => {
    setdonationDetailState((prevState) => ({
      ...prevState,
      campaignDetail: value,
    }));
  };

  const setVisibleModalDonationTopupState = (value: boolean) => {
    setdonationDetailState((prevState) => ({
      ...prevState,
      visibleModalDonationTopup: value,
    }));
  };

  const pageHideScrollnbar = true;

  if (!campaignAlias) {
    return <NotFoundPage />;
  }

  const fetchDataCampaignDetail = async (callbackFunc?: () => void) => {
    const campaignDetail = await getTLDonationCampaignDetailService(
      campaignAlias,
      apiAuthToken
    );

    if (!campaignDetail || !campaignDetail.id) {
      openSnackbar({
        text: "Chiến dịch không hợp lệ",
        type: "error",
      });
      return;
    }

    setCampaignDetailState(campaignDetail);

    if (callbackFunc && typeof callbackFunc === "function") {
      await setTimeout(() => {
        callbackFunc();
      }, 500);
    }
  };

  useEffect(() => {
    fetchDataCampaignDetail();

    return () => {
      //console.log("Cleanup functio deal detail");
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, [campaignAlias]);

  if (!campaignDetail) {
    return <DataLoading />;
  }

  // thiết lập của user hiện tại với chiến dịch (trường hợp KH chọn quyên góp )
  // nếu chưa đăng nhập hoặc ko chọn chiến dịch này thì là null
  const customerDonationSetting = campaignDetail.customer_donation_setting;
  // chiến dịch đang được user chọn quyên góp
  const isCampaignDonating =
    customerDonationSetting !== null && customerDonationSetting !== undefined;

  const onClickBtnTopup = (tlUser?: TLUserModel | null) => {
    setVisibleModalDonationTopupState(true);
  };

  const onClickBtnShare = () => {
    const currentUrl = window.location.href;
    copyTextToClipboard(currentUrl);
    openSnackbar({
      text: "Đã sao chép link chia sẻ bạn bè",
      type: "success",
    });
  };

  return (
    <>
      <Page className="page" hideScrollbar={pageHideScrollnbar}>
        <div className="m-t-15">
          <h1 className="line-height-35">{campaignDetail.name}</h1>
          <label className="text-dark">
            {campaignDetail.short_description}
          </label>
          <label className="font-size-12 text-dark">
            {formatDate(campaignDetail.time_created, "dd/MM/yyyy")}
          </label>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2-1-1 lg:grid-cols-2-2-1 gap-1.5rem m-t-15">
          <div>
            <img className="w-full" src={campaignDetail.logo} />
          </div>
          <div>
            <div className="text-success text-bold">THÔNG TIN QUYÊN GÓP</div>
            {isCampaignDonating && (
              <div className="m-t-10 text-center">
                <Badge
                  classNameExtends=""
                  icon={
                    <Icon className="font-size-15" icon="zi-check-circle" />
                  }
                  type="success"
                  text="Bạn đang quyên góp đơn hàng tự động"
                />
              </div>
            )}
            <div className="flex flex-col gap-10px border-solid-1 border-radius-12 bg-dark p-15 m-t-15">
              {campaignDetail.donation_partners.map((partner) => (
                <div key={partner.id} className="flex">
                  <div className="flex m-t-auto m-b-auto w-30px">
                    <img className="w-30px h-30" src={partner.logo} />
                  </div>
                  <div className="p-l-15">
                    <div>{partner.name}</div>
                    <div className="text-dark font-size-12">
                      {partner.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="flex gap-10px m-t-20">
              <div className="font-size-18 text-bold">
                {formatCurrencyVND(campaignDetail.raised_amount)}
              </div>
              <div className="text-dark">quyên góp</div>
              <div className="text-dark">/</div>
              <div className="text-dark">
                {formatCurrencyVND(campaignDetail.goal_amount)}
              </div>
            </div>
            <div className="progress progress-small m-t-10">
              <div
                className="progress-bar"
                role="progressbar"
                style={{ width: `${campaignDetail.raised_percent}%` }}
              ></div>
            </div>
            <div className="flex j-c-space-between m-t-10">
              <div>
                <div className="text-dark">Lượt quyên góp</div>
                <div className="font-size-15 text-bold">
                  {campaignDetail.donation_count}
                </div>
              </div>
              <div>
                <div className="text-dark">Đạt được</div>
                <div className="font-size-15 text-bold">
                  {campaignDetail.raised_percent}%
                </div>
              </div>
              <div>
                <div className="text-dark">Thời hạn còn</div>
                <div className="font-size-15 text-bold">
                  {calculateTimeDifference(
                    new Date(),
                    getDateFromString(campaignDetail.end_date),
                    "day"
                  )}{" "}
                  Ngày
                </div>
              </div>
            </div>
            <div className="flex text-center m-t-20 j-c-center gap-10px">
              <button
                onClick={onClickBtnShare}
                className="btn btn-light btn-border cursor-pointer w-120px"
              >
                Chia sẻ
              </button>
              <ProcessActionTypeTichLuy
                action_type={tlActionTypeEnum.RunFuncOnClick}
                onClick={onClickBtnTopup}
                requiredLogin={true}
              >
                <button className="btn btn-solid btn-border cursor-pointer w-120px">
                  {isCampaignDonating ? "Chỉnh sửa" : "Quyên góp"}
                </button>
              </ProcessActionTypeTichLuy>
            </div>
          </div>
        </div>
        <hr className="hr-line-dark m-t-20" />
        <div className="grid grid-cols-1 md:grid-cols-2-1-1 lg:grid-cols-2-2-1 gap-1.5rem m-t-15">
          <div>
            <div
              dangerouslySetInnerHTML={{
                __html: campaignDetail.description,
              }}
            ></div>
            <TichLuyDonationCustomers
              campaignId={campaignDetail.id}
              title="Nhà hảo tâm hàng đầu"
              type={tlGetDonationCampaignCustomersTypeEnum.GetTop}
            />
            <TichLuyDonationCustomers
              campaignId={campaignDetail.id}
              title="Nhà hảo tâm mới nhất"
              type={tlGetDonationCampaignCustomersTypeEnum.GetNew}
            />
          </div>
          <TichLuyDonationDetailOnGoingCampaign
            campaignDetail={campaignDetail}
          />
        </div>
        <TichLuyDonationDetailOthersCampaign campaignDetail={campaignDetail} />
      </Page>
      <Suspense fallback={<div>Loading...</div>}>
        {visibleModalDonationTopup && (
          <TichLuyModalDonationTopup
            visible={visibleModalDonationTopup}
            funcSetModalVisible={setVisibleModalDonationTopupState}
            donationCampaign={campaignDetail}
            funcFetchDataCampaignDetail={fetchDataCampaignDetail}
          />
        )}
      </Suspense>
    </>
  );
};

export default TichLuyDonationDetail;
