import React from "react";
import { Modal } from "zmp-ui";
import { tlOrderStatusEnum } from "../../../enums";
import { tlOrderModel } from "../../../models";
import { formatCurrencyVND, formatDate } from "../../../utility";

interface TichLuyModalOrderDetailProps {
  order?: tlOrderModel;
  isVisible: boolean;
  funcSetModalVisible(value: boolean): void;
}

const TichLuyModalOrderDetail: React.FunctionComponent<
  TichLuyModalOrderDetailProps
> = ({ order, isVisible, funcSetModalVisible }) => {
  if (!order) {
    return <></>;
  }

  const viewListOrderDetail =
    order.list_order_detail.length > 0 ? (
      order.list_order_detail.map((od) => (
        <li key={od.auto_number} className="list-group-item">
          <div className="flex w-full">
            <div className="col-product-name">{od.product_name}</div>
            <div className="col-product-amount text-right">
              {formatCurrencyVND(od.sale_amount)}
            </div>
            <div className="col-product-cashback text-right">
              {od.cashback_rate && od.cashback_amount ? (
                <>
                  <span className="text-danger">{od.cashback_rate}%</span> -{" "}
                  {formatCurrencyVND(od.cashback_amount)}
                </>
              ) : (
                <span>Đang cập nhật</span>
              )}
            </div>
          </div>
        </li>
      ))
    ) : (
      <></>
    );

  const viewTimeEstCashback =
    order.status === tlOrderStatusEnum.Pending ? (
      <div>
        <span className="font-size-12 text-dark">
          Ngày dự kiến hoàn tiền:{" "}
          {order.time_est_cashback
            ? formatDate(order.time_est_cashback, "dd/MM/yyyy")
            : "Đang cập nhật"}
        </span>
      </div>
    ) : (
      <></>
    );

  return (
    <Modal
      className="modal-order-detail"
      visible={isVisible}
      //title="ZaUI 2.0 Modal"
      coverSrc={order.link_image_merchant}
      onClose={() => {
        funcSetModalVisible(false);
      }}
      //zIndex={1200}
      actions={[
        {
          text: "Đóng",
          close: true,
        },
      ]}
      //description=""
    >
      <div className="text-center">
        <span className="f-w-b">Đơn hàng #{order.order_no}</span>
      </div>
      <div className="text-center">
        <span>Trạng thái: </span>
        <span className={`text-status status-${order.status}`}>
          {order.status_text}
        </span>
      </div>
      <ul className="list-group m-t-25">
        <li className="list-group-item">
          <div className="flex w-full">
            <div className="col-product-name text-center text-bold">
              Tên sản phẩm
            </div>
            <div className="col-product-amount text-center text-bold">
              Giá tiền
            </div>
            <div className="col-product-cashback text-center text-bold">
              Số tiền hoàn lại
            </div>
          </div>
        </li>
        {viewListOrderDetail}
      </ul>
      <div>
        <span className="font-size-12 text-dark">
          Ngày đặt hàng: {formatDate(order.time_buy, "dd/MM/yyyy HH:mm")}
        </span>
      </div>
      {viewTimeEstCashback}
    </Modal>
  );
};

export default TichLuyModalOrderDetail;
