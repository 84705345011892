import React, { useEffect, useState } from "react";
//import { onConfirmToExit, offConfirmToExit, closeApp } from "zmp-sdk/apis";
import { App, Modal, useSnackbar } from "zmp-ui";
import { ModalActions } from "zmp-ui/modal";
import { cache_user } from "../../../cache-manager";
import { nghienReviewCategoryModel } from "../../../models";
import { setNRCategoriesForBuyerCollectService } from "../../../services/category-service";

interface TichLuyModalCollectCategoriesProps {
  visible: boolean;
  funcSetModalVisible: (value: boolean) => void;
  categories: nghienReviewCategoryModel[];
}

interface formModel {
  is_loading: boolean;
  category_ids_selected: number[];
}

const TichLuyModalCollectCategories: React.FunctionComponent<
  TichLuyModalCollectCategoriesProps
> = ({ visible, funcSetModalVisible, categories }) => {
  const { openSnackbar } = useSnackbar();
  const [formModelState, setFormModelState] = useState<formModel>({
    is_loading: false,
    category_ids_selected: [],
  });
  const userFromCache = cache_user();
  const appSettings = userFromCache?.app_settings;

  if (!categories || categories.length === 0) {
    return <></>;
  }

  const onClickCategory = (cateId: number) => {
    const existCateId = formModelState.category_ids_selected.includes(cateId);
    // nếu trong danh sách đã chọn có thì bỏ ra
    if (existCateId) {
      setFormModelState((prevState) => ({
        ...prevState,
        category_ids_selected: prevState.category_ids_selected.filter(
          (cis) => cis !== cateId
        ),
      }));
    }
    // chưa có thì thêm vào
    else {
      setFormModelState((prevState) => ({
        ...prevState,
        category_ids_selected: [...prevState.category_ids_selected, cateId],
      }));
    }
  };

  const onClickButtonSubmit = async () => {
    if (formModelState.is_loading) {
      return;
    }

    if (formModelState.category_ids_selected.length === 0) {
      openSnackbar({
        text: "Vui lòng chọn ngành hàng yêu thích",
        type: "error",
      });
      return;
    }

    setFormModelState((prevState) => ({ ...prevState, is_loading: true }));
    try {
      const resultSetCategories = await setNRCategoriesForBuyerCollectService(
        userFromCache?.nghien_review_user.api_auth_token,
        formModelState.category_ids_selected
      );

      // có lỗi
      if (resultSetCategories.error_from_api) {
        openSnackbar({
          text: resultSetCategories.error_from_api.error_msg,
          type: "error",
        });
        return;
      }

      // thành công
      funcSetModalVisible(false);
      openSnackbar({
        text: "Lưu ngành hàng yêu thích thành công",
        type: "success",
      });
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Có lỗi xảy ra",
        type: "error",
      });
    } finally {
      setFormModelState((prevState) => ({ ...prevState, is_loading: false }));
    }
  };

  const listAction: ModalActions[] = [];
  // 2023-07-18 nam.nguyen gắn config có thể cho tắt ko bắt buộc
  if (appSettings?.NR_modal_buyer_like_categories_select_required !== true) {
    listAction.push({
      text: "Bỏ qua",
      onClick: () => {
        funcSetModalVisible(false);
      },
    });
  }

  listAction.push({
    text: `${formModelState.is_loading ? "Đang lưu..." : "Lưu"}`,
    className: "btn btn-solid btn-border btn-small",
    onClick: () => {
      onClickButtonSubmit();
    },
  });

  return (
    <Modal
      className="modal-collect-categorires"
      visible={visible}
      title="Chọn ngành hàng mà bạn yêu thích 💖"
      description="Tích Lũy sẽ đem đến những deal phù hợp với sở thích của bạn"
      onClose={() => {
        // 2023-07-18 nam.nguyen gắn config có thể cho tắt ko bắt buộc
        if (
          appSettings?.NR_modal_buyer_like_categories_select_required !== true
        ) {
          funcSetModalVisible(false);
        }
      }}
      actions={listAction}
    >
      <div className="row-2-colum m-t-20">
        {categories.map((cate) => (
          <div
            onClick={() => {
              onClickCategory(cate.id);
            }}
            key={cate.id}
            className={`home-block-collect-categories ${
              formModelState.category_ids_selected.includes(cate.id)
                ? "selected"
                : ""
            }`}
          >
            <div className="home-block-collect-categories-image">
              <img className="w-full border-top-10" src={cate.image_link} />
            </div>
            <div className="collect-categories-text">
              <span className="m-a text-center">{cate.name}</span>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default TichLuyModalCollectCategories;
