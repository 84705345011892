const COOKIE_NAME_TL_ETOKEN = "tl_etoken";
const COOKIE_NAME_TL_PARTNER_ALIAS = "tl_partner_alias";

function getCookie(name: string): string | null {
  const cookieValue = document.cookie
    .split("; ")
    .find((cookie) => cookie.startsWith(name + "="));

  if (cookieValue) {
    return cookieValue.split("=")[1];
  }

  return null;
};

function setCookie(name: string, value: string, minutesToExpire: number | null = null) {
  let cookieString = `${name}=${value};`;

  if (minutesToExpire !== null) {
    const expirationDate = new Date();
    expirationDate.setTime(expirationDate.getTime() + minutesToExpire * 60 * 1000); // Chuyển đổi phút thành mili giây
    cookieString += `expires=${expirationDate.toUTCString()};`;
  }

  document.cookie = cookieString;
}

export const tl_etoken_cookie = getCookie(COOKIE_NAME_TL_ETOKEN);
export const setTlETokenCookie = (tlEToken: string): void => {
  setCookie(COOKIE_NAME_TL_ETOKEN, tlEToken);
}

export const tl_partner_alias_cookie = getCookie(COOKIE_NAME_TL_PARTNER_ALIAS);
export const setTlPartnerAliasCookie = (partnerAlias: string): void => {
  setCookie(COOKIE_NAME_TL_PARTNER_ALIAS, partnerAlias);
}