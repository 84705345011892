import { tlActionTypeEnum } from "./enums";

export const DOMAIN_API: string = "deal-api.tichluy.vn"; // live
//const DOMAIN_API: string = "nv-api.timdeal.net"; // test
//const DOMAIN_API: string = "localhost:64308"; // local
export const DOMAIN_API_WITH_SCHEME: string = `http${
  DOMAIN_API.includes("localhost") ? "" : "s" // nếu local thì ko có ssl
}://${DOMAIN_API}`;

export const API_GET_HOME_DEALS: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/deal-hot`;
export const API_GET_DEALS_BY_SEARCH: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/deal-by-search`;
export const API_GET_CATEGORY_LV1: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/category-lv1`;
export const API_GET_DEAL_DETAIL: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/deal-detail`;
export const API_GET_CATEGORIES_FOR_BUYER_COLLECT: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/categories-for-buyer-collect`;
export const API_GET_BANKS: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/get-banks`;
export const API_POST_CATEGORIES_FOR_BUYER_COLLECT: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/categories-for-buyer-collect`;
export const API_GET_LIST_SLOT_STATUS: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/slot-status`;
export const API_GET_DEALS_JOINED: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/deal-joined`;
export const API_POST_JOIN_DEAL: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/join-deal`;
export const API_POST_DEAL_SLOT_UPDATE_INFORMATION: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/deal-slot-update-information`;
export const API_GET_AUTH_TOKEN: string = `${DOMAIN_API_WITH_SCHEME}/api/v1/auth-token`;

//------------------------------------------------------------------------------------------------
export const TL_DOMAIN_WEB_WITH_SCHEME: string = "https://www.tichluy.vn";
//export const TL_DOMAIN_API: string = "alt-webapi.tichluy.vn"; // live
export const TL_DOMAIN_API_AUTH: string = "alt-auth-api.tichluy.vn"; // live
export const TL_DOMAIN_API: string = "test-webapi.timdeal.net"; // test
//export const TL_DOMAIN_API: string = "localhost:61004"; // local
// export const TL_DOMAIN_API_AUTH: string = "localhost:2702"; // local
export const TL_DOMAIN_API_WITH_SCHEME: string = `http${
  TL_DOMAIN_API.includes("localhost") ? "" : "s" // nếu local thì ko có ssl
}://${TL_DOMAIN_API}`;
export const TL_DOMAIN_API_AUTH_WITH_SCHEME: string = `http${
  TL_DOMAIN_API.includes("localhost") ? "" : "s" // nếu local thì ko có ssl
}://${TL_DOMAIN_API_AUTH}`;

export const TL_WEB_ACCOUNT: string = `${TL_DOMAIN_WEB_WITH_SCHEME}/chinh-sua-thong-tin`;
export const TL_WEB_ORDERS: string = `${TL_DOMAIN_WEB_WITH_SCHEME}/don-hang`;
export const TL_WEB_ORDER_CLAIM: string = `${TL_DOMAIN_WEB_WITH_SCHEME}/ho-tro/bao-mat-don-hang`;
export const TL_WEB_MAKE_MONEY: string = `${TL_DOMAIN_WEB_WITH_SCHEME}/kiem-tien-voi-tich-luy`;
export const TL_WEB_SUPPORT: string = `${TL_DOMAIN_WEB_WITH_SCHEME}/ho-tro`;

export const TL_API_AUTH_VALID_ETOKEN: string = `${TL_DOMAIN_API_AUTH_WITH_SCHEME}/api/mobile/valid-etoken`;
export const TL_API_AUTH_ZALO_SIGNUP: string = `${TL_DOMAIN_API_AUTH_WITH_SCHEME}/api/mobile/zalo-mini-app-login`;
export const TL_API_AUTH_ZALO_ACCOUNT_MAPPING: string = `${TL_DOMAIN_API_AUTH_WITH_SCHEME}/api/mobile/mapping-account`;

export const TL_API_GET_HOME_BLOCKS: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/web-home-blocks`;
export const TL_API_GET_MERCHANT_DETAIL: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/merchant-detail`;
export const TL_API_GET_ALL_MERCHANT: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/all-merchant`;
export const TL_API_GET_ALL_CATEGORY: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/category-list`;
export const TL_API_GET_HOME_POPUP: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/home-popup`;
export const TL_API_GET_ACCOUNT_INFO: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/account-info`;
export const TL_API_GET_ORDERS: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/orders`;
export const TL_API_GET_DONATION_CAMPAIGNS: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/donation-campaigns`;
export const TL_API_GET_DONATION_CAMPAIGN_DETAIL: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/donation-campaign-detail`;
export const TL_API_GET_DONATION_CAMPAIGN_CUSTOMERS: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/donation-campaign-customers`;
export const TL_API_GET_APP_SETTINGS: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/web-app-settings`;
export const TL_API_SEND_INTERNAL_TRACKING: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/internal-tracking`;
export const TL_API_POST_DONATION_CUSTOMER_CREATE: string = `${TL_DOMAIN_API_WITH_SCHEME}/api/mobile/donation-customer-create`;

// NGHIEN REVIEW
export const ROUTE_PATH_NGHIEN_REVIEW_HOME = "/nghien-review";
export const ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH =
  "/nghien-review/deal-search";
export const ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL = `${ROUTE_PATH_NGHIEN_REVIEW_HOME}/deal-detail`;
export const ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED = `${ROUTE_PATH_NGHIEN_REVIEW_HOME}/deal-joined`;
export const ROUTE_PATH_NGHIEN_REVIEW_USER = `${ROUTE_PATH_NGHIEN_REVIEW_HOME}/user`;

export const SECRET_KEY_SIGN_REQUEST_NGHIEN_REVIEW = "@z0L0mIN!A$P#";

// TICH LUY
export const ROUTE_PATH_TICH_LUY_HOME = "/";
export const ROUTE_PATH_TICH_LUY_APP_OPEN = "/app-open";
export const ROUTE_PATH_TICH_LUY_DONATION = "/donation";
export const ROUTE_PATH_TICH_LUY_DONATION_DETAIL = "/donation/:campaignAlias";
export const ROUTE_PATH_TICH_ALL_MERCHANT = "/all-merchant";
export const ROUTE_PATH_TICH_LUY_USER = "/user";
export const ROUTE_PATH_TICH_LUY_ACCOUNT_MAPPING = "/account-mapping";
export const ROUTE_PATH_TICH_LUY_ORDERS = "/orders";

export const SECRET_KEY_SIGN_REQUEST_TICH_LUY_AUTH = "T@ich^Tim*211";

export const SOURCE_AUTH_TYPE = "TichLuy";
export const ZALO_MINI_APP_ID = "3318011038086523826";

export const URL_LOGO_APP =
  "https://deal.tichluy.vn/Content/Images/logo-tichluy-text.png";

export const TL_HOME_BLOCK_DISPLAY_SLOT = "tichluy-partner-msite-web-app";
export const TL_INTERNAL_TRACKING_SOURCE = "tichluy_web_app";

export const TL_API_INTERNAL_TRACKING_SECRET_KEY =
  "HsfAcT#KpB$Ru*KVoQ6yAnaFuVx^He5HK7af3H2JjXSq2c2cbM9dESTCj^S7LvCR#h&Ji*mMd9*3uuf!&Ao%FR8zJB&ahPBq6$##!t3@u^4ZBq3kk7*N!wX&ZMHHaLtN";

export const ACTION_TYPE_OF_NGHIEN_REVIEW = [
  tlActionTypeEnum.ScreenNghienReviewDealDetail,
  tlActionTypeEnum.ScreenNghienReviewDealAll,
];
