import React from "react";

const NotFoundPage: React.FunctionComponent = () => {
  return (
    <div className="m-a text-danger text-center">
      <p>Trang không tìm thấy 😥</p>
    </div>
  );
};

export default NotFoundPage;
