import React, { useState } from "react";
import { Icon, Page, useSnackbar } from "zmp-ui";
import { cache_tlAllCategory, cache_tlAllMerchant } from "../../cache-manager";
import DataLoading from "../../components/share/data-loading";
import ModalDataLoading from "../../components/share/modal-data-loading";
import TichLuyListMerchant from "../../components/tich_luy/merchant/list-merchant";
import TichLuyModalMerchantDetail from "../../components/tich_luy/merchant/modal-merchant-detail";
import { URL_LOGO_APP } from "../../constants";
import { TLMerchantModel } from "../../models";
import { getTLMerchantDetailService } from "../../services/tl-merchant-service";
import Header from "../../components/share/header";

interface searchModel {
  inputMerchant?: string;
  categoryId?: number;
}

const TichLuyAllMerchantPage: React.FunctionComponent = () => {
  // fetch danh sách cửa hàng
  const listMerchants = cache_tlAllMerchant()?.filter(
    (m) => m.enable_cashback === true
  );
  const listCategory = cache_tlAllCategory();

  const { openSnackbar } = useSnackbar();
  const [merchantDetailState, setMerchantDetailState] =
    useState<TLMerchantModel | null>(null);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [
    isVisibleModalMerchantDetailState,
    setIsVisibleModalMerchantDetailState,
  ] = useState(false);
  const [searchModelState, setSearchModelState] = useState<searchModel | null>(
    null
  );

  if (!listMerchants || !listCategory) {
    return <DataLoading />;
  }

  const listMerchantFilter = !searchModelState
    ? listMerchants
    : listMerchants.filter(
        (m) =>
          (!searchModelState.categoryId ||
            m.category_ids.some((c) => c === searchModelState.categoryId)) &&
          (!searchModelState.inputMerchant ||
            m.name
              .toLowerCase()
              .includes(searchModelState.inputMerchant.toLowerCase()))
      );

  const onChangeSearchModel = (searchModel: searchModel) => {
    // nếu chọn tất cả và ko có tìm kiếm cửa hàng thì mặc định set searchModel = null
    if ((searchModel.categoryId ?? 0) === 0 && !searchModel.inputMerchant) {
      setSearchModelState(null);
      return;
    }

    setSearchModelState(searchModel);
  };

  const showModalMerchantDetail = async (merchantId: number) => {
    if (isLoadingState) return;
    setIsLoadingState(true);

    // fetch data merchant detail
    try {
      // kiểm tra trong cache trước nếu đã có thì lấy ra dùng
      // còn ko thì gọi lên API
      let merchant: TLMerchantModel | undefined = undefined;
      merchant = listMerchants.find((m) => m.id === merchantId);

      if (!merchant) {
        merchant = await getTLMerchantDetailService(merchantId);
      }

      setMerchantDetailState(merchant);
      setIsVisibleModalMerchantDetailState(true);
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Có lỗi xảy ra!",
        type: "error",
      });
    } finally {
      setIsLoadingState(false);
    }
  };

  const viewModalMerchantDetail = merchantDetailState ? (
    <TichLuyModalMerchantDetail
      merchant={merchantDetailState}
      isVisible={isVisibleModalMerchantDetailState}
      funcSetModalVisible={setIsVisibleModalMerchantDetailState}
    />
  ) : (
    <></>
  );

  const viewModalDataLoading = isLoadingState ? (
    <ModalDataLoading visible={true} />
  ) : (
    <></>
  );

  const viewListCategory = (
    <div className="theme-tab">
      <ul className="tabs tab-title tab-border m-b-10">
        {listCategory.map((cate) => (
          <li
            className={
              (searchModelState?.categoryId ?? 0) === cate.id ? "current" : ""
            }
            key={cate.id}
          >
            <a
              onClick={() => {
                const model = { ...searchModelState, categoryId: cate.id };
                onChangeSearchModel(model);
              }}
            >
              {cate.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );

  const viewClearTextForSearchMerchant = searchModelState?.inputMerchant ? (
    <div
      onClick={() => {
        setSearchModelState((prevState) => ({
          ...prevState,
          inputMerchant: "",
        }));
      }}
      className="flex m-a p-r-10"
    >
      <Icon style={{ color: "#ddd" }} icon={"zi-close-circle-solid"} />
    </div>
  ) : (
    <></>
  );

  const viewSearchMerchant = (
    <div
      className="m-t-10 flex border-solid-1 border-12"
      style={{ flexFlow: "row" }}
    >
      <div className="flex p-5 m-a">
        <Icon icon={"zi-search"} />
      </div>
      <input
        value={searchModelState?.inputMerchant ?? ""}
        style={{ border: "none" }}
        placeholder="Nhập cửa hàng cần tìm kiếm..."
        className="form-control border-12 p-5 no-outline"
        onChange={(e: any) => {
          const model = { ...searchModelState, inputMerchant: e.target.value };
          onChangeSearchModel(model);
        }}
      />
      {viewClearTextForSearchMerchant}
    </div>
  );

  return (
    <Page className="page" hideScrollbar={true}>
      {viewSearchMerchant}
      {viewListCategory}
      <div className="m-t-15">
        <TichLuyListMerchant
          merchants={listMerchantFilter}
          funcShowModalMerchantDetail={showModalMerchantDetail}
        />
        {viewModalMerchantDetail}
        {viewModalDataLoading}
      </div>
    </Page>
  );
};

export default TichLuyAllMerchantPage;
