import { getOrdersTichLuyClient } from "../connect/tich-luy-client";
import { tlOrderModel } from "../models";

export const getOrdersService = async (
  pageIndex: number,
  pageSize: number,
  tlUserToken: string
): Promise<tlOrderModel[]> => {
  return getOrdersTichLuyClient(pageIndex, pageSize, tlUserToken);
};
