import {
  categoryLv1s,
  getCategoriesForBuyerCollectNghienReviewClient,
  setCategoriesForBuyerCollectNghienReviewClient,
} from "../connect/nghien-review-client";
import {
  categoriesForBuyerCollectResponseModel,
  categoryLv1,
  setCategoriesForBuyerCollectResponseModel,
} from "../models";

export const getCategoryLv1s = async (): Promise<categoryLv1[]> => {
  return categoryLv1s();
};

export const getNRCategoriesForBuyerCollectService = async (
  apiAuthToken: string | undefined
): Promise<categoriesForBuyerCollectResponseModel> => {
  return getCategoriesForBuyerCollectNghienReviewClient(apiAuthToken);
};

export const setNRCategoriesForBuyerCollectService = async (
  apiAuthToken: string | undefined,
  list_cate_id: number[]
): Promise<setCategoriesForBuyerCollectResponseModel> => {
  return setCategoriesForBuyerCollectNghienReviewClient(
    apiAuthToken,
    list_cate_id
  );
};
