import React, { useState } from "react";
import { Icon } from "zmp-ui";
import { dealSlotStatusEnum } from "../../../../enums";
import { dealSlot } from "../../../../models";
import ImageWithViewDetail from "../../../share/image-with-view-detail";
import FormUpdateOrder from "./form-update-order";

interface stepUpdateOrderProps {
  dealSlot: dealSlot;
  funcFetchDataDealDetail(): Promise<void>;
}

const StepUpdateOrder: React.FunctionComponent<stepUpdateOrderProps> = ({
  dealSlot,
  funcFetchDataDealDetail,
}) => {
  const listStatusAllow = [
    dealSlotStatusEnum.WaitingForBuyer,
    dealSlotStatusEnum.WaitingForBuyerReview,
    dealSlotStatusEnum.Rejected,
    dealSlotStatusEnum.Approved,
    dealSlotStatusEnum.Rewarded,
    dealSlotStatusEnum.WaitingForSeller,
  ];
  const slotStatus = dealSlot.status_code;

  // nếu deal slot ko nằm trong danh sách trạng thái hợp lệ để xử lý thì return
  if (!listStatusAllow.includes(slotStatus)) {
    return <></>;
  }

  const [showFormUploadImageOrderState, setShowFormUploadImageOrderState] =
    useState(false);

  // nếu show form cập nhật ảnh đơn hàng
  if (showFormUploadImageOrderState) {
    return (
      <FormUpdateOrder
        dealSlot={dealSlot}
        funcFetchDataDealDetail={funcFetchDataDealDetail}
        funcSetShowFormUploadImageOrderState={setShowFormUploadImageOrderState}
      />
    );
  }

  const funcOnClickBtnUpdateOrder = () => {
    setShowFormUploadImageOrderState(true);
  };

  // nếu deal slot chưa có hình ảnh đơn hàng thì hiển thị nút cập nhật ảnh đơn hàng
  if (!dealSlot.is_order_found) {
    return (
      <div>
        <a onClick={funcOnClickBtnUpdateOrder} className="btn btn-solid">
          Cập nhật ảnh đơn hàng
        </a>
      </div>
    );
  }

  const listStatusAllowEdit = [
    dealSlotStatusEnum.WaitingForBuyer,
    dealSlotStatusEnum.WaitingForBuyerReview,
    dealSlotStatusEnum.WaitingForSeller,
    dealSlotStatusEnum.Rejected,
  ];
  let viewEdit = <></>;

  if (listStatusAllowEdit.includes(dealSlot.status_code)) {
    viewEdit = (
      <span onClick={funcOnClickBtnUpdateOrder} className="color-primary">
        <Icon className="m-b-5 font-size-18" icon="zi-edit" /> Chỉnh sửa ảnh đơn
        hàng
      </span>
    );
  }

  // slot đã có hình ảnh đơn hàng
  return (
    <div>
      <div className="flex m-t-10 m-b-10">
        {dealSlot.order_image_link?.map((imageUrl, index) => (
          <ImageWithViewDetail
            key={index}
            className="img-square-50px m-r-10"
            src={imageUrl}
          />
        ))}
      </div>
      {viewEdit}
    </div>
  );
};

export default StepUpdateOrder;
