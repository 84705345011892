import React, { useEffect, useState, lazy, Suspense } from "react";
import { Page } from "zmp-ui";
import { tlGetDonationCampaignCustomersTypeEnum } from "../../../enums";
import { tlCustomerDonationModel } from "../../../models";
import { getTLDonationCampaignCustomersService } from "../../../services/tl-donation-campaign-service";
import DataLoading from "../../share/data-loading";
import TichLuyDonationCustomersListItem from "./donation-customers-list-item";

const TichLuyModalDonationCustomers = lazy(
  () => import("../donation/modal-donation-customers")
);

interface TichLuyDonationCustomerTopProps {
  title: string;
  campaignId: number;
  type: tlGetDonationCampaignCustomersTypeEnum;
}

const TichLuyDonationCustomers: React.FunctionComponent<
  TichLuyDonationCustomerTopProps
> = ({ campaignId, title, type }) => {
  const listType =
    type === tlGetDonationCampaignCustomersTypeEnum.GetNew
      ? "short-name"
      : "index";
  const numberRowDisplay = 10;
  const [listCustomer, setlistCustomer] = useState<
    tlCustomerDonationModel[] | undefined
  >(undefined);
  const [visibleModalDonationCustomers, setVisibleModalDonationCustomers] =
    useState<boolean>(false);

  const fetchDataListCustomer = async () => {
    const customers = await getTLDonationCampaignCustomersService(
      campaignId,
      type,
      1,
      numberRowDisplay
    );

    setlistCustomer(customers);
  };

  useEffect(() => {
    fetchDataListCustomer();

    return () => {
      //console.log("Cleanup functio deal detail");
      // Cleanup function sẽ được gọi khi component1 unmount hoặc useEffect chạy lại
      // Thực hiện các công việc cleanup cần thiết
    };
  }, [campaignId]);

  if (!listCustomer) return <DataLoading />;

  // nếu 0 có dữ liệu thì trả về trống
  if (listCustomer.length === 0) return <></>;

  const onClickBtnShowAll = () => {
    setVisibleModalDonationCustomers(true);
  };

  // nếu SL = 10 thì hiển thị nút xem tất cả
  const viewBtnViewAll = listCustomer.length === numberRowDisplay && (
    <div className="text-center m-t-15">
      <button
        onClick={onClickBtnShowAll}
        className="btn btn-outline-dark btn-border btn-small cursor-pointer"
      >
        Xem tất cả
      </button>
    </div>
  );

  return (
    <>
      <hr className="hr-line-dark m-t-15" />
      <div className="font-size-18 text-bold m-t-15">{title}</div>
      <div className="p-15 border-solid-1 border-radius-12 m-t-15">
        <TichLuyDonationCustomersListItem
          listCustomer={listCustomer}
          type={listType}
        />
      </div>
      {viewBtnViewAll}
      <Suspense fallback={<div>Loading...</div>}>
        {visibleModalDonationCustomers && (
          <TichLuyModalDonationCustomers
            title={title}
            visible={visibleModalDonationCustomers}
            listType={listType}
            listCustomersInit={listCustomer}
            funcSetVisibleModal={setVisibleModalDonationCustomers}
            campaignId={campaignId}
            type={type}
          />
        )}
      </Suspense>
    </>
  );
};

export default TichLuyDonationCustomers;
