import React from "react";
import { Modal, Spinner } from "zmp-ui";

interface modalDataLoadingParams {
  visible: boolean;
}

const ModalDataLoading: React.FunctionComponent<modalDataLoadingParams> = ({
  visible,
}) => {
  return (
    <Modal
      className="modal-data-loading"
      visible={visible}
      verticalActions="true"
    >
      <div className="row">
        <div className="m-a">
          <Spinner visible />
          <h4>Đang xử lý...</h4>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDataLoading;
