export enum dealSlotStatusEnum {
  WaitingForBuyer = 0,
  WaitingForSeller = 1,
  Approved = 2,
  Rejected = 3,
  Rewarded = 4,
  Expired = 5,
  Blocked = 6,
  WaitingForBuyerReview = 7,
}

export enum stepBuyDealEnum {
  B1 = "B1",
  B2 = "B2",
  B3 = "B3",
  B4 = "B4",
}

export enum DealSlotUpdateInformationActionTypeEnum {
  UpdateOrder = 0,
  updateReview = 1,
}

export enum dealSlotTransferTypeEnum {
  Bank = 0,
  Momo = 1,
  ShopeePay = 2,
  ZaloPay = 3,
}

export enum tlHomeBlockTypeEnum {
  Image = 0,
  ListImage = 1,
  ListImageWithText = 2,
  Slider = 3,
  Merchants = 4,
  ListImageWithTextVertical = 5,
}

export enum tlActionTypeEnum {
  RedirectUrl = 0,
  ScreenTichLuyMerchantDetail = 1,
  ScreenNghienReviewDealDetail = 2,
  ScreenNghienReviewDealAll = 3,
  ScreenTichLuyUser = 4,
  // chạy function onClick
  RunFuncOnClick = 5,
}

export enum tlOrderStatusEnum {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export enum tlWebAppInformationSourceTypeEnum{
  TichLuy,
  Partner
}

export enum tlGetDonationCampaignCustomersTypeEnum{
  GetTop = 0,
  GetNew = 1
}

export enum tlCustomerDonationTypeEnum{
  DiemTrongTaiKhoan = 0,
  TienDonHangDenHetChienDich = 1,
  TienDonHangTuDongThayDoiChienDich = 2
}