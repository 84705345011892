import React, { ReactNode } from "react";
import { tlActionTypeEnum } from "../../../enums";
import { useSetRecoilState, useRecoilState } from "recoil";
import { useNavigate } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import {
  processActionTypeTichLuyHelper,
  processWithAuthTichLuyHelper,
} from "../../../helper";
import { TLUserModel } from "../../../models";
import {
  tichLuyModalRedirectExternalState,
  tichLuyModalAccountVerifyState,
} from "../../../state";

interface ProcessActionTypeTichLuyComponentProps {
  action_type: tlActionTypeEnum;
  action_data?: string | null;
  requiredLogin: boolean;
  onClick?: (tlUser?: TLUserModel | null) => void;
  children?: ReactNode;
}

const ProcessActionTypeTichLuy: React.FunctionComponent<
  ProcessActionTypeTichLuyComponentProps
> = ({ action_type, requiredLogin, action_data, onClick, children }) => {
  const userFromCache = cache_user();
  const navigate = useNavigate();
  const setModalRedirectExternalState = useSetRecoilState(
    tichLuyModalRedirectExternalState
  );
  const [modalAccountVerifyState, setModalAccountVerifyState] = useRecoilState(
    tichLuyModalAccountVerifyState
  );

  if (!userFromCache) return <></>;

  const onClickElement = () => {
    const callbackFuncWithAuth = (tlUser?: TLUserModel | null) => {
      if (
        action_type === tlActionTypeEnum.RunFuncOnClick &&
        onClick &&
        typeof onClick === "function"
      ) {
        onClick(tlUser);
      } else {
        processActionTypeTichLuyHelper(
          tlUser?.eToken,
          action_type,
          action_data,
          navigate,
          setModalRedirectExternalState,
          userFromCache.app_settings
        );
      }
    };

    if (requiredLogin) {
      processWithAuthTichLuyHelper(
        userFromCache,
        modalAccountVerifyState,
        setModalAccountVerifyState,
        callbackFuncWithAuth
      );
    } else {
      callbackFuncWithAuth();
    }
  };

  const attachOnClickToChildren = (children: ReactNode) => {
    return React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const childProps = child.props;
        let originalOnClick: Function;
        if (
          childProps &&
          childProps.onClick &&
          typeof childProps.onClick === "function"
        ) {
          // Gán sự kiện onClick gốc để tí thao tác nếu có
          originalOnClick = childProps.onClick;
        }

        // hiện tại ko cần bind hết các component con chỉ cần bind component con đầu tiên
        // Nếu component con có children lồng nhau, áp dụng đệ quy
        // if (childProps && childProps.children) {
        //   childProps.children = attachOnClickToChildren(childProps.children);
        // }

        const onClick = () => {
          onClickElement();
          // nếu có function trên node con thì chạy luôn
          if (originalOnClick) {
            originalOnClick();
          }
        };

        return React.cloneElement(child as React.ReactElement, {
          onClick: onClick,
        });
      }
    });
  };

  const childrenWithOnClick = attachOnClickToChildren(children);

  return <>{childrenWithOnClick}</>;
};

export default ProcessActionTypeTichLuy;
