import React from "react";
import { Swiper } from "zmp-ui";
import { cache_user } from "../../../cache-manager";
import { TLHomeBlockModel } from "../../../models";
import LazyImage from "../../share/lazy-image";
import ProcessActionTypeTichLuy from "../share/process-action-type";

interface TichLuyHomeSliderProps {
  block: TLHomeBlockModel;
}

const TichLuyHomeSlider: React.FunctionComponent<TichLuyHomeSliderProps> = ({
  block,
}) => {
  const userFromCache = cache_user();

  if (block.list_home_block_item?.length > 0 === false || !userFromCache) {
    return <></>;
  }

  return (
    <div className="block-container">
      <Swiper autoplay={true}>
        {block.list_home_block_item.map((item) => (
          <Swiper.Slide key={item.id}>
            <ProcessActionTypeTichLuy
              action_type={item.action_type}
              action_data={item.action_data}
              requiredLogin={true}
            >
              <LazyImage
                className="slide-img"
                src={item.image_url}
              />
            </ProcessActionTypeTichLuy>
          </Swiper.Slide>
        ))}
      </Swiper>
    </div>
  );
};

export default TichLuyHomeSlider;
