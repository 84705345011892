import React, { useEffect, useState } from "react";
import { Route, useLocation, useSearchParams } from "react-router-dom";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH,
  ROUTE_PATH_TICH_LUY_DONATION_DETAIL,
  ROUTE_PATH_TICH_LUY_HOME,
  ROUTE_PATH_TICH_LUY_ORDERS,
  URL_LOGO_APP,
} from "../../constants";
import { Icon, useNavigate } from "zmp-ui";

const Header: React.FunctionComponent = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();
  const [visibleActionBarState, setVisibleActionBarState] = useState(false);

  const setVisibleActionBar = (value: boolean) => {
    setVisibleActionBarState(value);
  };

  const onClickBtnBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate(ROUTE_PATH_TICH_LUY_HOME, { replace: true, animate: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
    }
  };

  useEffect(() => {
    const listRoutePathVisibleMenuHeader = [
      ROUTE_PATH_TICH_LUY_ORDERS,
      ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
      ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH,
      ROUTE_PATH_TICH_LUY_DONATION_DETAIL,
    ];

    // kiểm tra xem route hiện tại có khả dụng để hiển thị header hay không
    const regexPattern = /([^/]+)/g;
    const matchRegexCurrentPath = currentPath.match(regexPattern);

    const visibleAction = listRoutePathVisibleMenuHeader.some((pathOfList) => {
      const matchRegexPathOfList = pathOfList.match(regexPattern);
      // trường hợp trang home "/"
      if (
        !matchRegexPathOfList &&
        !matchRegexCurrentPath &&
        currentPath === "/"
      )
        return true;

      if (matchRegexPathOfList?.length !== matchRegexCurrentPath?.length) {
        return false;
      }

      if (matchRegexCurrentPath && matchRegexPathOfList) {
        // lặp qua từng match group để so sánh
        for (let index = 0; index < matchRegexPathOfList.length; index++) {
          const valueOfPathOfList = matchRegexPathOfList[index];
          const valueOfCurrentPath = matchRegexCurrentPath[index];

          // trường hợp route config có dấu : thì cho đúng luôn vì trường hợp
          // này là param trong reactJs
          if (valueOfPathOfList.includes(":")) continue;

          // 2 chuỗi ko giống nhau thì return false
          if (
            valueOfPathOfList.trim().toLowerCase() !==
            valueOfCurrentPath.trim().toLocaleLowerCase()
          ) {
            return false;
          }
        }

        return true;
      } else {
        return false;
      }
    });

    setVisibleActionBar(visibleAction);
  }, [currentPath]);

  const viewActionBar = visibleActionBarState ? (
    <span onClick={onClickBtnBack} className="cursor-pointer">
      <Icon className="btn-back" icon="zi-chevron-left-header" />
    </span>
  ) : (
    <></>
  );

  return (
    <div className="header-logo text-center">
      {viewActionBar}
      <img className="w-100" src={URL_LOGO_APP} />
    </div>
  );
};

export default Header;
