import {
  getDonationCampaignCustomersTichLuyClient,
  getDonationCampaignDetailTichLuyClient,
  getDonationCampaignsTichLuyClient,
} from "../connect/tich-luy-client";
import { tlGetDonationCampaignCustomersTypeEnum } from "../enums";
import { tlCustomerDonationModel, tlDonationCampaignModel } from "../models";

export const getTLDonationCampaignsService = async (
  pageIndex: number,
  pageSize: number = 6,
  tlUserToken?: string | null
): Promise<tlDonationCampaignModel[]> => {
  return getDonationCampaignsTichLuyClient(pageIndex, pageSize, tlUserToken);
};

export const getTLDonationCampaignDetailService = async (
  campaignAlias: string,
  tlUserToken?: string | null,
  campaignsFromCache?: tlDonationCampaignModel[] | null
): Promise<tlDonationCampaignModel> => {
  // kiểm tra trong cache nếu có thì lấy ra dùng
  const campaignDetailExistCache = campaignsFromCache?.find(
    (x) => x.alias === campaignAlias
  );
  if (campaignDetailExistCache) {
    return campaignDetailExistCache;
  }

  // gọi API server để lấy detail
  return getDonationCampaignDetailTichLuyClient(campaignAlias, tlUserToken);
};

export const getTLDonationCampaignCustomersService = async (
  campaignId: number,
  type: tlGetDonationCampaignCustomersTypeEnum,
  pageIndex: number,
  pageSize: number = 10
): Promise<tlCustomerDonationModel[]> => {
  return getDonationCampaignCustomersTichLuyClient(
    campaignId,
    type,
    pageIndex,
    pageSize
  );
};

export const getTLDonationCampaignOnGoingForDetailService = (
  currentCampaign: tlDonationCampaignModel,
  campaignsFromCache: tlDonationCampaignModel[],
  pageSize: number = 1
): tlDonationCampaignModel[] => {
  // nếu cache ko có giá trị thì return luôn
  if (campaignsFromCache.length === 0) return [];

  return campaignsFromCache
    .filter((x) => x.id !== currentCampaign.id)
    .slice(0, pageSize);
};

export const getTLDonationCampaignOthersForDetailService = (
    currentCampaign: tlDonationCampaignModel,
    campaignsFromCache: tlDonationCampaignModel[],
    pageSize: number = 3
  ): tlDonationCampaignModel[] => {
    // nếu cache ko có giá trị thì return luôn
    if (campaignsFromCache.length === 0) return [];
  
    return campaignsFromCache
      .filter((x) => x.id !== currentCampaign.id)
      .slice(0, pageSize);
  };
