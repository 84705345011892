import React from "react";
import { Page } from "zmp-ui";
import { formatCurrencyVND } from "../../../utility";
import { tlCustomerDonationModel } from "../../../models";

interface TichLuyDonationCustomerNewListItemProps {
  listCustomer: tlCustomerDonationModel[];
  type: "index" | "short-name";
}

const TichLuyDonationCustomersListItem: React.FunctionComponent<
  TichLuyDonationCustomerNewListItemProps
> = ({ listCustomer, type }) => {
  if (!listCustomer || listCustomer.length === 0) return <></>;

  return (
    <>
      {listCustomer.map((customer, index) => (
        <div
          key={index}
          className="flex border-bottom-solid-1-dark p-b-10 m-b-10"
        >
          <div className="flex j-c-center text-bold font-size-18 text-dark flex m-t-auto m-b-auto w-20px">
            {type === "index" ? index + 1 : customer.short_name}
          </div>
          <div className="flex-1 p-l-15">
            <div className="text-bold">{customer.name}</div>
            <div className="text-dark">{customer.ref_code}</div>
          </div>
          <div className="text-bold flex m-t-auto m-b-auto">
            {formatCurrencyVND(customer.amount)}
          </div>
        </div>
      ))}
    </>
  );
};

export default TichLuyDonationCustomersListItem;
