import React, { useState } from "react";
import { useLocation } from "react-router";
import { useRecoilState, useSetRecoilState } from "recoil";
import { Icon, BottomNavigation, useNavigate } from "zmp-ui";
import { cache_user } from "../../cache-manager";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_JOINED,
  ROUTE_PATH_NGHIEN_REVIEW_HOME,
  ROUTE_PATH_TICH_ALL_MERCHANT,
  ROUTE_PATH_TICH_LUY_HOME,
  ROUTE_PATH_TICH_LUY_USER,
} from "../../constants";
import {
  processWithAuthTichLuyHelper,
  setPageHomeLastViewProductId,
} from "../../helper";
import { TLModalAccountVerifyModel } from "../../models";
import { tichLuyModalAccountVerifyState } from "../../state";

interface bottomNavigationCustomProps {
  children?: React.ReactNode;
}

const BottomNavigationTichLuy: React.FunctionComponent<
  bottomNavigationCustomProps
> = () => {
  const userFromCache = cache_user();
  const [modalAccountVerifyState, setModalAccountVerifyState] = useRecoilState(
    tichLuyModalAccountVerifyState
  );
  const [activeTab, setActiveTabState] = useState("Home");
  const setActiveTab: any = (key: string) => {
    console.log(key);
    setActiveTabState(key);
  };
  const navigate = useNavigate();

  return (
    <BottomNavigation
      id="bottomNavigation"
      fixed
      activeKey={activeTab}
      onChange={(key) => setActiveTab(key)}
    >
      <BottomNavigation.Item
        key="Home"
        label="Trang chủ"
        icon={<Icon icon="zi-home" />}
        activeIcon={<Icon icon="zi-home" />}
        onClick={() => {
          navigate(ROUTE_PATH_TICH_LUY_HOME, {
            animate: false,
            replace: true,
          });
        }}
      />
      <BottomNavigation.Item
        label="Cửa hàng"
        key="CuaHang"
        icon={<Icon icon="zi-more-grid" />}
        activeIcon={<Icon icon="zi-more-grid-solid" />}
        onClick={() => {
          navigate(ROUTE_PATH_TICH_ALL_MERCHANT, {
            animate: false,
            replace: true,
          });
        }}
      />
      <BottomNavigation.Item
        label="Deal hoàn tiền"
        key="NghienReview"
        icon={<Icon icon="zi-star" />}
        activeIcon={<Icon icon="zi-star-solid" />}
        onClick={() => {
          navigate(ROUTE_PATH_NGHIEN_REVIEW_HOME, {
            animate: false,
          });
        }}
      />
      <BottomNavigation.Item
        key="User"
        label="Tài khoản"
        icon={<Icon icon="zi-user" />}
        activeIcon={<Icon icon="zi-user-solid" />}
        onClick={() => {
          const callbackFuncWithAuth = () => {
            navigate(ROUTE_PATH_TICH_LUY_USER, {
              animate: false,
              replace: true,
            });
          };
          processWithAuthTichLuyHelper(
            userFromCache,
            modalAccountVerifyState,
            setModalAccountVerifyState,
            callbackFuncWithAuth
          );
        }}
      />
    </BottomNavigation>
  );
};

export default BottomNavigationTichLuy;
