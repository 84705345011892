import React, { memo, useEffect, useMemo } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { cache_dealsForPageHome } from "../../../cache-manager";
import DataLoading from "../../share/data-loading";
import HomeDealItem from "./home-deal-item";

interface homeListDealItemProps {
  category_name: string;
}

const HomeListDealItem: React.FunctionComponent<homeListDealItemProps> = ({
  category_name,
}) => {
  const listDealItem = cache_dealsForPageHome(category_name);
  //console.log("load component HomeListDealItem " + category_name);

  return (
    <div className="row">
      {listDealItem ? (
        listDealItem.map((item) => (
          <HomeDealItem key={Math.random()} item={item}></HomeDealItem>
        ))
      ) : (
        <DataLoading></DataLoading>
      )}
    </div>
  );
};

export default memo(HomeListDealItem);
