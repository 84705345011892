import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL,
  ROUTE_PATH_NGHIEN_REVIEW_HOME,
  ROUTE_PATH_TICH_LUY_DONATION_DETAIL,
  ROUTE_PATH_TICH_LUY_USER,
  SECRET_KEY_SIGN_REQUEST_NGHIEN_REVIEW,
  TL_DOMAIN_WEB_WITH_SCHEME,
  TL_INTERNAL_TRACKING_SOURCE,
  ZALO_MINI_APP_ID,
} from "./constants";
import { addQueryParams, updateQueryParams } from "./utility";
import { HmacSHA256, enc } from "crypto-js";
import {
  cacheProcessingModel,
  tlAppSettingsModel,
  TLModalAccountVerifyModel,
  TLModalRedirectExternalModel,
  TLUserModel,
  tlWebAppInformationModel,
  userInfo,
} from "./models";
import { SetterOrUpdater } from "recoil";
import {
  tlActionTypeEnum,
  tlWebAppInformationSourceTypeEnum,
} from "./enums";
import { ZMPNavigationFunction } from "zmp-ui/useNavigate";
import { setTlETokenCookie, setTlPartnerAliasCookie, tl_etoken_cookie, tl_partner_alias_cookie } from "./cookie-helpers";

export const buildUrl_Internal_PageHome = (cateLv1?: string) => {
  return `${ROUTE_PATH_NGHIEN_REVIEW_HOME}/${encodeURIComponent(
    cateLv1 ?? ""
  )}`;
};

export const buildUrl_NghienReview_Internal_PageDealDetail = (
  dealId: string
) => {
  return `${ROUTE_PATH_NGHIEN_REVIEW_DEAL_DETAIL}?dealId=${encodeURIComponent(
    dealId
  )}`;
};

export const buildUrl_NghienReview_External_DealDetail = (
  detailLink: string,
  authToken: string
) => {
  const url = addQueryParams(detailLink, { t: authToken });
  return url;
};

export const buildUrl_TichLuy_Internal_Redirect_CampaignDetail = (
  merchantAlias: string
) => {
  return `${ROUTE_PATH_TICH_LUY_DONATION_DETAIL.replace(":campaignAlias", merchantAlias)}`;
};

export const buildUrl_TichLuy_External_Redirect_Merchant = (
  merchantAlias: string
) => {
  return `${TL_DOMAIN_WEB_WITH_SCHEME}/chuyen-huong-den-${merchantAlias}`;
};

export const buildUrl_TichLuy_External_AppOpen = (
  url: string,
  tlEtoken: string,
  isCopyUrlAndOpenBrower: boolean,
  partnerAlias?: string | null
) => {
  // 2023-06-21 nam.nguyen cập nhật thêm giá trị tl_source để tracking
  const paramsToUpdate = {
    tl_source: `${TL_INTERNAL_TRACKING_SOURCE}${partnerAlias ? `_${partnerAlias}` : ""}`,
    tl_medium: window.location.pathname,
  };
  url = updateQueryParams(url, paramsToUpdate);

  const urlTLAppOpen = `${TL_DOMAIN_WEB_WITH_SCHEME}/app-open.aspx?url=${encodeURIComponent(
    url
  )}&etk=${encodeURIComponent(tlEtoken)}&ext=${
    isCopyUrlAndOpenBrower || partnerAlias ? "1" : "0"
  }`;

  // 2023-09-26 nam.nguyen nếu là thao tác mở link qua partner scheme link
  // thì build thêm link hỗ trợ partner scheme
  if(partnerAlias){
    return `${partnerAlias}://webopen?url=${encodeURIComponent(urlTLAppOpen)}`;
  }

  // trường hợp còn lại thì trả ra link TL App Open
  return urlTLAppOpen;
};

export const buildUrl_ZMA_Share = (internal_path?: string) => {
  const params = new URLSearchParams();
  if (internal_path) {
    params.append("path", internal_path);
  }

  return `https://zalo.me/s/${ZALO_MINI_APP_ID}/?${params.toString()}`;
};

export const openUrlInWebView = async (url: string) => {
  try {
    console.log("start open webview url: " + url);
    // await openWebview({
    //   url: url,
    // });
  } catch (error) {
    // xử lý khi gọi api thất bại
    console.log(error);
  }
};

export function signRequestSimple(
  parameters: Record<string, string>,
  appSecret: string
) {
  return signRequest(parameters, null, appSecret);
}

export function signRequest(
  parameters: Record<string, string>,
  apiName: string | null,
  appSecret: string,
  body: string | null = null,
  signMethod: string | null = null
) {
  // Remove "sign" param to hash the remaining parameters
  delete parameters["sign"];

  // Sort all parameters by key with ASCII order
  const sortedParams: any = {};
  Object.keys(parameters)
    .sort()
    .forEach((key) => {
      sortedParams[key] = parameters[key];
    });

  // Concatenate all parameters in order
  let query = apiName ?? "";
  Object.keys(sortedParams).forEach((key) => {
    const value = sortedParams[key];
    if (key && value) {
      query += key.toLowerCase() + value;
    }
  });

  // Add the body to the end
  if (body) {
    query += body;
  }

  // Sign the query string
  let hash;
  if (!signMethod || signMethod === "sha256") {
    hash = HmacSHA256(query, appSecret);
  }

  // finally : transfer binary byte to hex string
  return hash?.toString(enc.Hex).toUpperCase();
}

export const closeZaloLoadingScreen = async () => {
  try {
    //closeLoading({});
  } catch (error) {
    // xử lý khi gọi api thất bại
    console.log(error);
  }
};

export const getPageHomeLastViewProductId = (): string | undefined => {
  return window.PAGE_HOME_LAST_VIEW_PRODUCT_ID;
};

export const setPageHomeLastViewProductId = (value: string | undefined) => {
  window.PAGE_HOME_LAST_VIEW_PRODUCT_ID = value;
};

export const processWithAuthTichLuyHelper = (
  userFromCache: userInfo | null,
  modalAccountVerifyState: TLModalAccountVerifyModel,
  setModalAccountVerifyState: SetterOrUpdater<TLModalAccountVerifyModel>,
  callbackFuncWithAuth: (tlUser: TLUserModel) => void,
  isSkipAuth?: boolean
) => {
  if (!userFromCache) return;

  // nếu đã đăng nhập tài khoản TL thì thực hiện func
  if (userFromCache.tich_luy_user || isSkipAuth === true) {
    callbackFuncWithAuth((userFromCache.tich_luy_user ?? {}) as TLUserModel);
  }
  // chưa đăng nhập thì hiển thị modal hỏi tài khoản tích lũy
  else {
    setModalAccountVerifyState({
      ...modalAccountVerifyState,
      isVisible: true,
      callbackFunction: callbackFuncWithAuth,
    });
  }
};

export const setConfigAppViewVisibleActionBar = (visibleActionBar: boolean) => {
  // configAppView({
  //   headerColor: "#23ae4a",
  //   statusBarType: "normal",
  //   actionBar: {
  //     hide: visibleActionBar,
  //   },
  // });
};

export const processActionTypeTichLuyHelper = (
  tlUserEToken: string | undefined | null,
  actionType: tlActionTypeEnum,
  actionData: string | undefined | null,
  navigate: ZMPNavigationFunction,
  setModalRedirectExternalState: SetterOrUpdater<TLModalRedirectExternalModel>,
  tlAppSettings: tlAppSettingsModel,
  textActionForModalCopyRedirectExternal?: string
) => {
  switch (actionType) {
    case tlActionTypeEnum.RedirectUrl: {
      // 2023-06-21 nam.nguyen nếu url ko có dữ liệu thì return ko thao tác
      const url = actionData;
      if (!url || !tlUserEToken) {
        return;
      }

      // 2023-06-30 nam.nguyen nếu có setting tự đôg mở link webview thì các
      // loại chuyển hướng cửa hàng tự động mở webview luôn ko cần mở popup
      // kêu user copy link
      const isAutoRedirectWebviewInApp = tlAppSettings.auto_redirect === true;

      // hiện tại zalo mini app chưa cho mở web view nên mở modal kêu user
      // trình duyệt bên ngoài
      const urlTichLuyAppOpen = buildUrl_TichLuy_External_AppOpen(
        url,
        tlUserEToken,
        !isAutoRedirectWebviewInApp
      );

      if (isAutoRedirectWebviewInApp) {
        setConfigAppViewVisibleActionBar(false);

        window.location.href = urlTichLuyAppOpen;
      }
      // mở popup
      else {
        setModalRedirectExternalState({
          isVisible: true,
          url: urlTichLuyAppOpen,
          textAction: textActionForModalCopyRedirectExternal,
        });
      }

      return;
    }
    case tlActionTypeEnum.ScreenTichLuyMerchantDetail: {
      // hiện tại action này mở popup xử lý trực tiếp trong page nên ko cần xử lý
      return;
    }
    case tlActionTypeEnum.ScreenNghienReviewDealAll: {
      navigate(ROUTE_PATH_NGHIEN_REVIEW_HOME, {
        animate: false,
      });
      return;
    }
    case tlActionTypeEnum.ScreenNghienReviewDealDetail: {
      const routeDealDetail = buildUrl_NghienReview_Internal_PageDealDetail(
        actionData ?? ""
      );
      navigate(routeDealDetail, {
        animate: false,
      });
      return;
    }
    case tlActionTypeEnum.ScreenTichLuyUser: {
      navigate(ROUTE_PATH_TICH_LUY_USER, {
        animate: false,
        replace: true,
      });
      return;
    }
    default:
      break;
  }
};

// lấy thông tin tlToken - partner alias - ... dưới cookie để xử lý
export const getTlWebAppInformartion = () => {
  const tl_eToken = tl_etoken_cookie;
  // nếu token ko tồn tại thì return null luôn
  if (!tl_eToken) return null;

  // nếu ko có partnerAlias là loại partner còn lại là loại web đang chạy cho TL
  const partnerAlias = tl_partner_alias_cookie;

  const tlWebAppInformation: tlWebAppInformationModel = {
    tl_eToken: tl_eToken,
    source_type: partnerAlias
      ? tlWebAppInformationSourceTypeEnum.Partner
      : tlWebAppInformationSourceTypeEnum.TichLuy,
    partner_alias: partnerAlias,
  };

  return tlWebAppInformation;
};

export const setTlWebAppInformartion = (tlEToken: string, partnerAlias: string | null) => {
  setTlETokenCookie(tlEToken);
  if(partnerAlias){
    setTlPartnerAliasCookie(partnerAlias);
  }
};
