import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { cache_user } from "../../cache-manager";
import { TL_INTERNAL_TRACKING_SOURCE } from "../../constants";
import { tlSendInternalTrackingRequestAPIModel } from "../../models";
import { sendInternalTrackingTLService } from "../../services/tl-tracking-service";

const InternalTrackingPageView: React.FunctionComponent = () => {
  const userFromCache = cache_user();
  const location = useLocation();

  // Hàm callback để gửi event lên API khi link thay đổi
  const sendTrackingEvent = async (
    urlFull: string,
    urlPath: string,
    urlTitle: string,
    urlQueryString: string
  ) => {
    // Gửi request lên API với thông tin cần thiết, ví dụ:
    try {
      let tlAuthToken: string | undefined = undefined;
      let userId: string = "n/a";
      if (userFromCache) {
        userId = `buyerId.${userFromCache.nghien_review_user.id}`;

        // nếu có thêm user của TL thì thêm vào
        if (userFromCache.tich_luy_user) {
          userId += `|tlUserId.${userFromCache.tich_luy_user.email}`;
          tlAuthToken = userFromCache.tich_luy_user.token;
        }
      }

      const eventModel: tlSendInternalTrackingRequestAPIModel = {
        Source: TL_INTERNAL_TRACKING_SOURCE,
        Medium: "organic",
        Campaign: "tracking_page_view",
        Content: urlPath,
        Term: urlQueryString,
        PageTitle: urlTitle,
        PageLocation: urlFull,
        UserId: userId,
      };

      sendInternalTrackingTLService(tlAuthToken, eventModel);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const currentURL = window.location.href;
    const currentPath = window.location.pathname;
    const currentTitle = document.title;
    const currentQueryString = window.location.search;
    sendTrackingEvent(
      currentURL,
      currentPath,
      currentTitle,
      currentQueryString
    );
  }, [location]);

  return <></>;
};

export default InternalTrackingPageView;
