import React from "react";
import { Icon } from "zmp-ui";
import { tlOrderModel } from "../../../models";
import { formatCurrencyVND, formatDate } from "../../../utility";

interface listOrderProps {
  list_order: tlOrderModel[];
  funcSetModalVisible(visible: boolean, order: tlOrderModel): void;
}

const TichLuyListOrder: React.FunctionComponent<listOrderProps> = ({
  list_order,
  funcSetModalVisible,
}) => {
  if (list_order.length === 0) {
    return <div className="text-danger text-center">Không có đơn hàng</div>;
  }

  return (
    <div className="list-order">
      {list_order.map((order) => (
        <div
          onClick={() => {
            funcSetModalVisible(true, order);
          }}
          key={order.auto_number}
          className={`list-order-item status-${order.status}`}
        >
          <div className="list-order-item-left">
            <img className="w-60" src={order.link_image_merchant} />
          </div>
          <div className="list-order-item-center">
            <div className="row truncate m-w-100">
              <span>
                {formatDate(order.time_buy, "dd/MM/yy")} - #{order.order_no}
              </span>
            </div>
            <span className="row list-order-item-status-text">
              {order.status_text}
            </span>
          </div>
          <div className="list-order-item-right">
            <div className="text-right">
              <span
                className={`list-order-item-cashback-amount font-size-${
                  order.total_cashback_amount ? "12" : "10"
                }`}
              >
                {order.total_cashback_amount
                  ? formatCurrencyVND(order.total_cashback_amount)
                  : "Đang cập nhật"}
              </span>
              <br />
              <span className="font-size-12 text-dark">
                {formatCurrencyVND(order.total_sale_amount)}
              </span>
            </div>
            <div className="flex">
              <Icon className="m-a" icon="zi-chevron-right" />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TichLuyListOrder;
