import React, { useState } from "react";
import { useSnackbar } from "zmp-ui";
import { cache_user } from "../../../../cache-manager";
import { DealSlotUpdateInformationActionTypeEnum } from "../../../../enums";
import {
  dealSlot,
  dealSlotUpdateInformationRequestModel,
  fileImageInformation,
} from "../../../../models";
import { updateInformationForDealSlot } from "../../../../services/slot-service";
import ImageWithViewDetail from "../../../share/image-with-view-detail";

interface FormUpdateOrderProps {
  dealSlot: dealSlot;
  funcFetchDataDealDetail(): Promise<void>;
  funcSetShowFormUploadImageOrderState(value: boolean): void;
}

const FormUpdateOrder: React.FC<FormUpdateOrderProps> = ({
  dealSlot,
  funcFetchDataDealDetail,
  funcSetShowFormUploadImageOrderState,
}) => {
  const user = cache_user();
  const authApiToken = user?.nghien_review_user.api_auth_token;
  const [imageUrls, setImageUrls] = useState<fileImageInformation[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const { openSnackbar } = useSnackbar();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const urls: fileImageInformation[] = [];
      const maxlengthFile = 3;

      for (let i = 0; i < Math.min(files.length, maxlengthFile); i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.onloadend = () => {
          urls.push({
            name: file.name,
            type: file.type,
            base64_url: reader.result as string,
          });
          if (urls.length === Math.min(files.length, maxlengthFile)) {
            setImageUrls(urls);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleSaveImage = async () => {
    // chặn trường hợp ấn nhiều lần
    if (uploading) return;

    console.log("BEGIN UPDATE ORDER");

    try {
      setUploading(true);
      const informationUpdate: dealSlotUpdateInformationRequestModel = {
        deal_slot_id: dealSlot.id,
        action_type: DealSlotUpdateInformationActionTypeEnum.UpdateOrder,
        list_images: imageUrls,
      };

      // gọi API
      const response = await updateInformationForDealSlot(
        authApiToken,
        informationUpdate
      );
      console.log(response);
      // Xử lý thành công
      if (!response.error_from_api) {
        // refresh lại data deal
        funcFetchDataDealDetail();
        // ẩn form update Order
        funcSetShowFormUploadImageOrderState(false);

        openSnackbar({
          text: "Cập nhật thành công",
          type: "success",
        });
      }
      // Xử lý khi có lỗi
      else {
        openSnackbar({
          text: `${response.error_from_api.error_msg}. Code[${response.error_from_api.error_code}]`,
          type: "error",
        });
      }
    } catch (error) {
      console.log(error);
      openSnackbar({
        text: "Có lỗi xảy ra!",
        type: "error",
      });
    } finally {
      setUploading(false);
    }
  };

  let viewImagesOld = <></>;
  if (dealSlot.order_image_link && imageUrls.length === 0) {
    viewImagesOld = (
      <div className="flex m-t-10 m-b-10">
        {dealSlot.order_image_link.map((imageUrl, index) => (
          <ImageWithViewDetail
            key={index}
            className="img-square-50px m-r-10"
            src={imageUrl}
          />
        ))}
      </div>
    );
  }

  return (
    <div>
      {viewImagesOld}
      <div className="form-group m-b-0">
        <input
          className="form-control"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          multiple
        />
      </div>
      <div className="m-b-10 font-size-12 font-style-italic">
        <span>* Tối đa 3 ảnh</span>
      </div>
      <div className="flex m-t-10 m-b-10">
        {imageUrls.map((image, index) => (
          <img
            className="img-square-50px m-r-10"
            key={index}
            src={image.base64_url}
            alt={`Uploaded Image ${index}`}
          />
        ))}
      </div>
      <button
        className="btn btn-small btn-solid"
        onClick={handleSaveImage}
        disabled={!imageUrls || uploading}
      >
        {uploading ? "Đang lưu..." : "Lưu"}
      </button>
    </div>
  );
};

export default FormUpdateOrder;
