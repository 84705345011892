import React, { useState } from "react";
import { Icon } from "zmp-ui";
import { dealSlotStatusEnum } from "../../../../enums";
import { dealSlot } from "../../../../models";
import ImageWithViewDetail from "../../../share/image-with-view-detail";
import FormUpdateReview from "./form-update-review";

interface stepUpdateReviewProps {
  dealSlot: dealSlot;
  funcFetchDataDealDetail(): Promise<void>;
}

const StepUpdateReview: React.FunctionComponent<stepUpdateReviewProps> = ({
  dealSlot,
  funcFetchDataDealDetail,
}) => {
  const listStatusAllow = [
    dealSlotStatusEnum.WaitingForBuyerReview,
    dealSlotStatusEnum.Rejected,
    dealSlotStatusEnum.Approved,
    dealSlotStatusEnum.Rewarded,
    dealSlotStatusEnum.WaitingForSeller,
  ];
  const slotStatus = dealSlot.status_code;

  // nếu deal slot ko nằm trong danh sách trạng thái hợp lệ để xử lý thì return
  if (!listStatusAllow.includes(slotStatus)) {
    return <></>;
  }

  const [showFormUploadImageReviewState, setShowFormUploadImageReviewState] =
    useState(false);

  // nếu show form cập nhật ảnh đơn hàng
  if (showFormUploadImageReviewState) {
    return (
      <FormUpdateReview
        dealSlot={dealSlot}
        funcFetchDataDealDetail={funcFetchDataDealDetail}
        funcSetShowFormUploadImageReviewState={
          setShowFormUploadImageReviewState
        }
      />
    );
  }

  const funcOnClickBtnUpdateReview = () => {
    setShowFormUploadImageReviewState(true);
  };

  // nếu deal slot chưa có hình ảnh review thì hiển thị nút cập nhật ảnh đơn hàng
  if (dealSlot.status_code === dealSlotStatusEnum.WaitingForBuyerReview) {
    return (
      <div>
        <div className="alert alert-warning">
          Khi bạn đã nhận được hàng và đánh giá sản phẩm hãy nhớ cập nhật hình
          ảnh đánh giá để được người bán duyệt và hoàn tiền nhé.
        </div>
        <a onClick={funcOnClickBtnUpdateReview} className="btn btn-solid">
          Cập nhật ảnh đánh giá SP
        </a>
      </div>
    );
  }

  const listStatusAllowEdit = [
    dealSlotStatusEnum.WaitingForBuyerReview,
    dealSlotStatusEnum.WaitingForSeller,
    dealSlotStatusEnum.Rejected,
  ];
  let viewEdit = <></>;

  if (listStatusAllowEdit.includes(dealSlot.status_code)) {
    viewEdit = (
      <span onClick={funcOnClickBtnUpdateReview} className="color-primary">
        <Icon className="m-b-5 font-size-18" icon="zi-edit" /> Chỉnh sửa ảnh
        review
      </span>
    );
  }

  // slot đã có hình ảnh review
  return (
    <div>
      <div className="flex m-t-10 m-b-10">
        {dealSlot.review_image_link?.map((imageUrl, index) => (
          <ImageWithViewDetail
            key={index}
            className="img-square-50px m-r-10"
            src={imageUrl}
          />
        ))}
      </div>
      {viewEdit}
    </div>
  );
};

export default StepUpdateReview;
