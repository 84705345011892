import React, { memo, Suspense } from "react";
import { Page, useNavigate, Tabs, Icon } from "zmp-ui";

import PageTitle from "../../components/share/page-title";
import HomeListDealItem from "../../components/nghien_review/home/home-list-deal-item";
import HomeTabs from "../../components/nghien_review/home/home-tabs";
import { closeZaloLoadingScreen, signRequest } from "../../helper";
import { cache_user } from "../../cache-manager";
import {
  ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH,
  URL_LOGO_APP,
} from "../../constants";
import { useParams } from "react-router-dom";

const HomePage: React.FunctionComponent = () => {
  const title: string = "Deal bán chạy";
  const pageHideScrollnbar: boolean = true;
  const navigate = useNavigate();
  // vào lấy liền user lưu vào cache để các page sau dùng ko cần đợi
  const userInfo = cache_user(closeZaloLoadingScreen);

  const viewSearchMerchant = (
    <div
      onClick={() => {
        navigate(ROUTE_PATH_NGHIEN_REVIEW_DEAL_SEARCH, {
          animate: false,
        });
      }}
      className="m-t-10 flex border-solid-1 border-12"
      style={{ flexFlow: "row" }}
    >
      <div className="flex p-5 m-a">
        <Icon icon={"zi-search"} />
      </div>
      <input
        style={{ border: "none" }}
        placeholder="Nhập tên deal cần tìm kiếm..."
        className="form-control border-12 p-5 no-outline"
      />
    </div>
  );

  return (
    <Page className="page" hideScrollbar={pageHideScrollnbar}>
      <PageTitle title={title}></PageTitle>
      {viewSearchMerchant}
      <HomeTabs></HomeTabs>
    </Page>
  );
};

export default HomePage;
