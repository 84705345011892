import React from "react";
import { useNavigate } from "zmp-ui";
import { buildUrl_NghienReview_Internal_PageDealDetail } from "../../../helper";
import { dealItem, dealJoined } from "../../../models";
import { formatCurrencyWithShorten } from "../../../utility";

interface DealItemProps {
  item: dealJoined;
}

const DealsJoinedItem: React.FunctionComponent<DealItemProps> = ({ item }) => {
  const navigate = useNavigate();
  const onClickBtnDetail = (
    event: React.MouseEvent<HTMLElement>,
    dealId: string
  ) => {
    navigate(buildUrl_NghienReview_Internal_PageDealDetail(dealId));
  };

  return (
    <div key={item.deal_slot.id} className="text-center">
      <div>
        <img className="img-border" src={item.image_url} />
      </div>
      <div>
        <h4 className="m-b-5">{item.name}</h4>
        <small>Mã: {item.id}</small>
        <p className="deals-joined-price">
          Giá bán: {formatCurrencyWithShorten(item.price)} - Hoàn tiền:{" "}
          {formatCurrencyWithShorten(item.cashback)} ({item.cashback_rate} %) -
          Giá cuối: {formatCurrencyWithShorten(item.actual_price)}
        </p>
        <p className="text-danger">{item.deal_slot.status}</p>
        <button
          onClick={(e) => onClickBtnDetail(e, item.id_e)}
          className="btn btn-solid"
        >
          Chi tiết
        </button>
        <div className="m-t-25 m-b-15 step-info-divider"></div>
      </div>
    </div>
  );
};

export default DealsJoinedItem;
