import React from "react";
import { Avatar, Box, Page, Text } from "zmp-ui";
import { URL_LOGO_APP } from "../../constants";

const TichLuyAccountUpdatePage: React.FunctionComponent = () => {
  return (
    <Page className="page" hideScrollbar={true}>
      <div className="m-t-15 text-center">
        <h2>Cập Nhật Thông Tin</h2>
      </div>
      <div className="m-t-15">
        <div className="form-group">
          <label className="font-weight-bold">Email</label>
          <input
            name="transferBankAccountName"
            className="form-control form-control-large"
            type="email"
            placeholder="Email"
          />
        </div>
        <div className="form-group">
          <label className="font-weight-bold">Mật khẩu</label>
          <input
            name="transferBankAccountName"
            className="form-control form-control-large"
            type="password"
            placeholder="Mật khẩu"
          />
        </div>
        <div className="form-group">
          <label className="font-weight-bold">Xác nhận mật khẩu</label>
          <input
            name="transferBankAccountName"
            className="form-control form-control-large"
            type="password"
            placeholder="Xác nhận mật khẩu"
          />
        </div>
        <div className="text-center m-t-15">
          <button className="btn btn-solid btn-border btn-small">
            Xác nhận
          </button>
        </div>
        {/* <div className="text-center m-t-10">
          <span className="text-disabled">Bạn đã có tài khoản Tích Lũy? </span>
          <a className="text-success">Đồng bộ</a>
        </div> */}
      </div>
    </Page>
  );
};

export default TichLuyAccountUpdatePage;
