import {
  getAccountInfoTichLuyClient,
  mappingAccountTichLuyClient,
  signinTichLuyClient,
  signupTichLuyClient,
} from "../connect/tich-luy-client";
import { TLUserInfoModel, TLUserModel } from "../models";

export const signupTichLuyService = async (
  zaloUserId: string,
  fullName: string,
  referralCode?: string
): Promise<TLUserModel> => {
  return signupTichLuyClient(zaloUserId, fullName, referralCode);
};

export const signinTichLuyService = async (
  tlEToken: string
): Promise<TLUserModel> => {
  return signinTichLuyClient(tlEToken);
};

export const mappingAccountTichLuyService = async (
  zaloUserId: string,
  mappingCode: string
): Promise<TLUserModel> => {
  return mappingAccountTichLuyClient(zaloUserId, mappingCode);
};

export const getAccountInfoTichLuyService = async (
  tlUserEtoken: string
): Promise<TLUserInfoModel> => {
  return getAccountInfoTichLuyClient(tlUserEtoken);
};
