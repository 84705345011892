import React from "react";
import { useRecoilState } from "recoil";
import { Modal, Icon, useSnackbar } from "zmp-ui";
import { TLModalRedirectExternalModel } from "../../../models";
import { tichLuyModalRedirectExternalState } from "../../../state";
import { copyTextToClipboard } from "../../../utility";
import NoteOpenLinkExternal from "../../nghien_review/deal_detail/deal-detail-note-open-external";

const TichLuyModalRedirectExternal: React.FunctionComponent = () => {
  const { openSnackbar } = useSnackbar();
  const [modalState, setModalState] =
    useRecoilState<TLModalRedirectExternalModel>(
      tichLuyModalRedirectExternalState
    );

  // nếu modal ko có url để kêu user copy link thì ko cần build html ra dom để tối ưu
  if (!modalState.url) return <></>;

  const copyUrlExtternalToClipboard = () => {
    copyTextToClipboard(modalState.url);
    setModalState({ isVisible: false } as TLModalRedirectExternalModel);
    openSnackbar({
      text: "Đã sao chép liên kết",
      type: "success",
    });
  };

  return (
    <Modal
      zIndex={9999}
      visible={modalState.isVisible}
      //title="🔔 Thông báo"
      onClose={copyUrlExtternalToClipboard}
      verticalActions="true"
      description={`Bạn vui lòng sao chép và mở liên kết bằng trình duyệt điện thoại để ${
        modalState.textAction ?? "tiếp tục"
      }.`}
    >
      <div className="text-center m-t-15">
        <input
          className="w-full h-30"
          type="text"
          value={modalState.url}
          disabled
        />
      </div>
      <div className="text-center m-t-15">
        <button
          onClick={copyUrlExtternalToClipboard}
          className="btn btn-solid btn-border"
        >
          <Icon className="m-b-5 font-size-18" icon="zi-copy" /> Sao chép liên
          kết
        </button>
      </div>
      <NoteOpenLinkExternal></NoteOpenLinkExternal>
    </Modal>
  );
};

export default TichLuyModalRedirectExternal;
